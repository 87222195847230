import { assoc, isNil } from "ramda";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useApp } from "../../../hooks/useApp";
import { LvTable } from "../../../hooks/useLvTable";
import { ROUTES } from "../../../http/constants";
import { createProductsInProcess } from "../../../service/lvService";
import { loadQuote } from "../../../service/offerManagementService";
import { FirmadesNutzers, Nutzer } from "../../../types/identity";
import { getNonOpenedQuoteByOrderNumber } from "../../../utilities/cartUtils";
import { LvAddressData } from "./LvOfferTab";

const DEFAULT_OFFER_CREATE_STATE = {
  isCreateLoading: true,
  isOpenLoading: true,
  isCreateSuccess: false,
  isOpenSuccess: false,
  errorMessage: "",
};

export function useOfferCreate(
  lvTable: LvTable,
  buildProject: string,
  referenceNumber: string,
  comment: string,
  addressData: LvAddressData,
) {
  const navigate = useNavigate();
  const { identityStore } = useApp();
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [offerCreateState, setOfferCreateState] = useState(
    DEFAULT_OFFER_CREATE_STATE,
  );
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    if (showLoadingModal) {
      setOfferCreateState(DEFAULT_OFFER_CREATE_STATE);
    }
  }, [showLoadingModal]);

  function openLoadingModal() {
    setShowLoadingModal(true);
  }

  function closeLoadingModal() {
    abortControllerRef.current.abort();
    setShowLoadingModal(false);
    abortControllerRef.current = new AbortController();
  }

  async function createOffer(company: FirmadesNutzers, user: Nutzer) {
    try {
      const { data } = await createProductsInProcess(
        lvTable,
        buildProject,
        referenceNumber,
        comment,
        addressData,
        company,
        user,
        abortControllerRef.current.signal,
      );
      setOfferCreateState(assoc("isCreateSuccess", true));
      return data.cartOrderNumber;
    } catch (error) {
      setOfferCreateState(
        assoc("errorMessage", "offer_management_create_lv_error_new_offer"),
      );
    } finally {
      setOfferCreateState(assoc("isCreateLoading", false));
    }
  }

  async function openOffer(cartOrderNumber: string, userEmail: string) {
    try {
      const foundQuote = await getNonOpenedQuoteByOrderNumber(
        cartOrderNumber,
        userEmail,
      );
      if (isNil(foundQuote)) {
        throw new Error(`no quote found by order number ${cartOrderNumber}`);
      }
      await loadQuote(foundQuote.cartTO.id);
      setOfferCreateState(assoc("isOpenSuccess", true));
      navigate(ROUTES.CART);
    } catch (error) {
      setOfferCreateState(
        assoc("errorMessage", "offer_management_open_lv_offer_error"),
      );
    } finally {
      setOfferCreateState(assoc("isOpenLoading", false));
    }
  }

  async function createAndOpenOffer() {
    openLoadingModal();
    const company = identityStore.getCompany();
    const user = identityStore.getUser();
    if (isNil(company) || isNil(user)) {
      return;
    }
    const cartOrderNumber = await createOffer(company, user);
    if (isNil(cartOrderNumber)) {
      return;
    }
    openOffer(cartOrderNumber, user.Email);
  }

  return {
    createAndOpenOffer,
    showLoadingModal,
    closeLoadingModal,
    offerCreateState,
  };
}
