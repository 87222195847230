import { faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { equals, has, path, pathEq, pathOr } from "ramda";
import { useRef, useState } from "react";

import { L10n } from "@encoway/l10n";
import { Constants, Icon } from "@encoway/react-components";
import { specState } from "@encoway/rest-api";

import { useApp } from "../../../hooks/useApp";
import { useConfiguration } from "../../../hooks/useConfiguration";
import {
  setConfigurationParameter,
  undoConfigurationParameter,
} from "../../../service/configurationService";
import { ParameterTO } from "../../../types/@encoway/Parameter";
import { Value } from "../../../types/@encoway/Value";
import {
  ConfiguratorComponentProps,
  ConfiguratorComponentStyleProps,
} from "../../../types/configuration";
import { ConfigurationUndoModal } from "../configurationUndoModal";

const DEFAULT_STYLE = {
  defaultIcon: "fa-television",
  systemIcon: "fa-cog",
  undoIcon: "fa-times",
  unspecifiedIcon: "fragezeichen",
  userIcon: "fa-user",
};

const READY_STATE_PATH = ["properties", "TT_READY_STATE"];

function determineIcon(
  id: string,
  mediaLink: ConfiguratorComponentProps<ParameterTO>["mediaLink"],
  parameterStyle: ConfiguratorComponentStyleProps,
) {
  return function (state: string) {
    if (equals(state, "SET_BY_USER")) {
      return (
        <FontAwesomeIcon
          data-icon-id={`SET_BY_USER-${id}`}
          icon={faUser}
          style={{ color: "#ef7b22" }}
        />
      );
    }

    const ICONS: Record<string, string> = {
      SET_BY_DEFAULT: parameterStyle.defaultIcon,
      SET_BY_SYSTEM: parameterStyle.systemIcon,
      SET_BY_EXTERNAL: parameterStyle.externalIcon,
    };
    if (has(state, ICONS)) {
      return (
        <Icon
          iconId={`${state}-${id}`}
          mediaLink={mediaLink}
          src={ICONS[state]}
          title={L10n.format(state)}
        />
      );
    }

    return null;
  };
}

type ParameterUndoButtonProps = Readonly<{
  data: ParameterTO;
  style: ConfiguratorComponentStyleProps;
  prevValue: React.MutableRefObject<Value | null>;

  setLoading: ConfiguratorComponentProps<ParameterTO>["setLoading"];
  onValueChanged: ConfiguratorComponentProps<ParameterTO>["onValueChanged"];
  openModal: () => void;
}>;

function ParameterUndoButton(props: ParameterUndoButtonProps) {
  const { data, style, prevValue, onValueChanged, openModal, setLoading } =
    props;

  const { configurationId } = useConfiguration();
  const { language } = useApp();

  const isUndoable =
    data.undoable &&
    data.editable &&
    style.hasUndo &&
    specState(data) === "SET_BY_USER";

  async function onValueUndo(): Promise<void> {
    const currentValue = path<Value>(["selectedValues", 0], data);
    if (currentValue) {
      setLoading(true);
      prevValue.current = currentValue;
      // manual undo to get the response
      const { data: undoData } = await undoConfigurationParameter(
        configurationId,
        data.id,
        language,
      );
      if (pathEq("CONFLICTED", ["status"], undoData)) {
        setLoading(false);
        return openModal();
      }
      // reset the previous value so that the correct undo can be performed
      // afterwards
      await setConfigurationParameter(
        configurationId,
        data.id,
        language,
        prevValue.current,
        data.viewPort!,
      );
      onValueChanged(data, Constants.Undo);
      setLoading(false);
    }
  }

  if (isUndoable) {
    return (
      <button
        className="is-undo p-0 pl-2 border-0 has-background-white is-clickable"
        onClick={onValueUndo}
      >
        <FontAwesomeIcon
          data-icon-id={`UNDO-${data.name}`}
          icon={faTimes}
          style={{ color: "#ef7b22", height: 14 }}
          title={L10n.format("undo")}
        />
      </button>
    );
  }

  return null;
}

type ParameterStateIconProps = Readonly<{
  data: ParameterTO;
  style: ConfiguratorComponentStyleProps;

  mediaLink: ConfiguratorComponentProps<ParameterTO>["mediaLink"];
}>;

function ParameterStateIcon(props: ParameterStateIconProps) {
  const { data, style, mediaLink } = props;

  const parameterStyle = { ...DEFAULT_STYLE, ...style };
  const icon = determineIcon(data.id, mediaLink, parameterStyle);

  if (parameterStyle.showSpecState !== false) {
    return (
      <span className="icon is-small is-spec-state is-pulled-right is-flex">
        {pathEq("UNSPECIFIED", READY_STATE_PATH, data) ? (
          <Icon
            iconId={`TO_BE_DETERMINED-${data.name}`}
            mediaLink={mediaLink}
            src={DEFAULT_STYLE.unspecifiedIcon}
            title={L10n.format("TO_BE_DETERMINED")}
          />
        ) : (
          icon(specState(data))
        )}
      </span>
    );
  }

  return null;
}

type ParameterStateProps = Readonly<ConfiguratorComponentProps<ParameterTO>>;

export function ParameterState(props: ParameterStateProps) {
  const { data, style, mediaLink, onValueChanged, setLoading } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const prevValue = useRef<Value | null>(null);

  return (
    <span className="is-pulled-right">
      <ParameterUndoButton
        data={data}
        onValueChanged={onValueChanged}
        openModal={() => setShowModal(true)}
        prevValue={prevValue}
        setLoading={setLoading}
        style={style}
      />
      <ParameterStateIcon data={data} mediaLink={mediaLink} style={style} />
      <ConfigurationUndoModal
        conflictedCharacteristic={pathOr("", ["translatedName"], data)}
        conflictedValue={pathOr("", ["translatedValue"], prevValue.current)}
        onCancel={() => setShowModal(false)}
        isVisible={showModal}
      />
    </span>
  );
}
