import { RefObject, useEffect } from "react";

export function useClickOutside(
  ref: RefObject<HTMLSpanElement>,
  action: () => void,
) {
  function handleClickOutside(event: MouseEvent) {
    if (!ref.current?.contains(event.target as Node)) {
      action();
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [ref]);
}
