import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AutoAwesome } from "@mui/icons-material";
import { styled } from "@mui/material";
import { AxiosPromise } from "axios";
import classNames from "classnames";
import { equals, gte, length, map, not } from "ramda";
import { useEffect, useState } from "react";

import { ParameterTO } from "../../../../types/@encoway/Parameter";
import { Value } from "../../../../types/@encoway/Value";
import { isOriginalValue } from "../../../../utilities/lvUtils";
import {
  dropDownValuesSeparable,
  getSelectableDropDownValues,
  getUnselectableDropDownValues,
} from "../../../configurator/customizing/configuratorDropDownUtils";
import { t } from "../../../shoppingCart/localizationUtils";

const ITEM_COUNT_SCROLLABLE_CONTENT = 8;

export type MinimalValue = Pick<
  Value,
  "value" | "translatedValue" | "selectable" | "selected"
>;

const DropdownTriggerButton = styled("button")({
  borderLeft: "1px solid #dbdbdb !important",
  borderRight: "1px solid #dbdbdb !important",
});

const DropdownIcon = styled("span")({
  height: "30px !important",
  width: "30px !important",
  marginRight: "0 !important",
});

type LvDropdownItemProps = {
  name: string;
  isNotSelectable: boolean;
  isSelected: boolean;
  onMouseDown: React.MouseEventHandler<HTMLButtonElement>;
  isOriginal?: boolean;
};

export const LvDropdownItem = ({
  name,
  isNotSelectable,
  isSelected,
  onMouseDown,
  isOriginal,
}: LvDropdownItemProps) => (
  <button
    className={classNames(
      "dropdown-item is-size-7 is-flex is-align-items-center is-justify-content-space-between",
      {
        "is-not-selectable": isNotSelectable,
        "is-selected": isSelected,
      },
    )}
    onMouseDown={onMouseDown}
  >
    <p>{name}</p>
    {isOriginal && <AutoAwesome sx={{ fontSize: "1rem" }} />}
  </button>
);

type LvDropdownItemsProps = Readonly<{
  values: MinimalValue[];
  originalValue?: string;
  isSelected: (value: string) => boolean;
  onMouseDown: (value: string) => void;
}>;

export function LvDropdownItems(props: LvDropdownItemsProps) {
  const { values, originalValue, isSelected, onMouseDown } = props;
  return (
    <>
      {map(
        ({ value, translatedValue, selectable }) => (
          <LvDropdownItem
            key={value}
            name={translatedValue}
            isNotSelectable={not(selectable) && not(isSelected(value))}
            isSelected={isSelected(value)}
            onMouseDown={() => onMouseDown(value)}
            isOriginal={isOriginalValue(originalValue, value)}
          />
        ),
        values,
      )}
    </>
  );
}

type LvDropdownProps = {
  values: MinimalValue[];
  selectedValue: { value: string; translatedValue: string };
  originalValue?: string;
  isDisabled?: boolean;
  onSelectValue: (value: string) => void;
  getParameterTO?: () => AxiosPromise<ParameterTO>;
  onUndo?: () => void;
};

export const LvDropdown = ({
  isDisabled,
  originalValue,
  selectedValue,
  values,
  onSelectValue,
  getParameterTO,
  onUndo,
}: LvDropdownProps) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const [conflictFreeValues, setConflictFreeValues] = useState(values);

  useEffect(() => {
    setConflictFreeValues(values);
  }, [values]);

  const isScrollable = gte(
    length(conflictFreeValues),
    ITEM_COUNT_SCROLLABLE_CONTENT,
  );

  async function onToggleDropdown() {
    if (not(dropdownActive) && getParameterTO) {
      const result = await getParameterTO();
      if (result.data.values) {
        setConflictFreeValues(result.data.values);
      }
    }
    setDropdownActive((prev) => not(prev));
  }

  return (
    <div className={classNames("dropdown", { "is-active": dropdownActive })}>
      <div className="dropdown-trigger">
        <DropdownTriggerButton
          className="button is-small border-0 p-0 pl-2"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={onToggleDropdown}
          onBlur={() => setDropdownActive(false)}
          disabled={isDisabled}
        >
          <span>{selectedValue.translatedValue}</span>
          <DropdownIcon className="icon is-small">
            <FontAwesomeIcon icon={dropdownActive ? faAngleUp : faAngleDown} />
          </DropdownIcon>
        </DropdownTriggerButton>
      </div>
      <div
        className="dropdown-menu w-100"
        id="dropdown-menu"
        role="menu"
        style={{ padding: 1 }}
      >
        <div
          className={classNames("dropdown-content", {
            "is-scrollable": isScrollable,
          })}
          style={{ borderRadius: 0, padding: 0 }}
        >
          {onUndo && (
            <LvDropdownItem
              name={t("lv_import_reset_customer_address")}
              isNotSelectable={equals(selectedValue.value, "")}
              isSelected={equals(selectedValue.value, "")}
              onMouseDown={onUndo}
            />
          )}
          <LvDropdownItems
            values={getSelectableDropDownValues(conflictFreeValues)}
            originalValue={originalValue}
            isSelected={equals(selectedValue.value)}
            onMouseDown={onSelectValue}
          />
          {dropDownValuesSeparable(conflictFreeValues) && (
            <hr className="dropdown-divider" />
          )}
          <LvDropdownItems
            values={getUnselectableDropDownValues(conflictFreeValues)}
            originalValue={originalValue}
            isSelected={equals(selectedValue.value)}
            onMouseDown={onSelectValue}
          />
        </div>
      </div>
    </div>
  );
};
