import { pathOr } from "ramda";
import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import ProductSelectionFilters from "./productSelectionFilters";
import ProductSelectionTable from "./productSelectionTable";

/**
 * The product selection page.
 *
 * @class Productselection
 * @extends React.Component
 */
export default class ProductSelection extends React.Component {
  render() {
    if (this.props.currentCategory === "teckentrup") {
      return null;
    }

    return (
      <div>
        <ProductSelectionFilters
          currentFilters={this.props.filters}
          currentSearchTerm={this.props.searchTerm}
          characteristics={this.props.products.characteristics}
          characteristicViews={this.props.products.characteristicViews}
          aggregatedCharacteristicValues={
            this.props.products.aggregatedCharacteristicValues
          }
          validValues={pathOr(
            {},
            ["unfilteredProducts", "aggregatedCharacteristicValues"],
            this.props,
          )}
          setFilter={(value, characteristicId) =>
            this.props.manageFilters(value, characteristicId)
          }
          setSearch={(searchterm) => this.props.setSearchTerm(searchterm)}
          onFilter={() => this.updateProductsForCurrentFilterQuery()}
          onReset={() => this.props.resetFiltersAndSearchterm()}
          searchFilterLoading={this.props.searchFilterLoading}
        />
        <InfiniteScroll
          pageStart={0}
          loadMore={this.props.loadMoreDataOnScroll}
          hasMore={
            this.props.products.metadata.total.hitCount >
            this.props.products.metadata.offset
          }
          loader={<div className="loader" key={0} />}
        >
          <ProductSelectionTable
            language={this.props.language}
            products={this.props.products.products}
            characteristics={this.props.products.characteristics}
            characteristicViews={this.props.products.characteristicViews}
            toggleFavorite={this.props.toggleFavorite}
            favoriteProductIds={this.props.favoriteProductIds}
          />
        </InfiniteScroll>
      </div>
    );
  }
}
