import {
  equals,
  find,
  isEmpty,
  isNil,
  length,
  map,
  not,
  path,
  pathOr,
  propEq,
  reduce,
} from "ramda";

import { L10n } from "@encoway/l10n";

import {
  ExtendedTeckentrupCartTO,
  LocalizedShippingMethodTO,
  TeckentrupCartArticleTO,
} from "../../../../../types/cart";
import { ORDER_STATUS_FLAGGED } from "../../../../../utilities/cartUtils";
import { CART_ITEM_TYPE_SPAREPART } from "../footerUtils";

const NO_SHIPPING_METHOD_FOUND = "NO_SHIPPING_METHOD_FOUND";

/**
 * Builds Teckentrup-Dropdown-Entry-Objects. Teckentrup Dropdown need a special list of configuration
 * objects defining available entries. Css determines if entry is selected or not.
 */
export const buildShippingMethodDropDown = (
  allowedShippingMethods: LocalizedShippingMethodTO[],
  activeShippingMethod: LocalizedShippingMethodTO,
  shippingMethodTranslation: Record<string, string>,
) =>
  map(
    (shippingMethod) => ({
      id: shippingMethod.id,
      translationKey: shippingMethod.translationKey,
      value: pathOr(
        L10n.format("no_shipping_type_exist"),
        [shippingMethod.id],
        shippingMethodTranslation,
      ),
      css:
        shippingMethod.id === path(["id"], activeShippingMethod)
          ? "is-selected"
          : "selectable",
    }),
    allowedShippingMethods,
  );

export function hasNoAllowedShippingMethod(
  allowedShippingMethods: LocalizedShippingMethodTO[],
) {
  if (isEmpty(allowedShippingMethods)) {
    return true;
  }
  const foundNoShippingMethod = find(
    propEq(NO_SHIPPING_METHOD_FOUND, "id"),
    allowedShippingMethods,
  );
  return not(isNil(foundNoShippingMethod));
}

export const calculationViews = (
  {
    expresskostenZuschlag,
    expresskostenZuschlagPartOrder,
    processingSurcharge,
    processingSurchargePartOrder,
    allowedShippingMethods,
    allowedShippingMethodsPartOrder,
  }: ExtendedTeckentrupCartTO,
  isOrder: boolean,
) => {
  const hasExpressSurcharge = isOrder
    ? expresskostenZuschlagPartOrder?.price > 0
    : expresskostenZuschlag?.price > 0;

  const hasProcessingSurcharge = isOrder
    ? processingSurchargePartOrder?.price > 0
    : processingSurcharge?.price > 0;

  const hasShippingMethod = isOrder
    ? allowedShippingMethodsPartOrder &&
      length(allowedShippingMethodsPartOrder) > 1
    : allowedShippingMethods && length(allowedShippingMethods) > 1;

  return { hasExpressSurcharge, hasProcessingSurcharge, hasShippingMethod };
};

export function hasOnlySpareParts(
  items: TeckentrupCartArticleTO[],
  isOrderView: boolean,
): boolean {
  return reduce(
    (acc, cur) => {
      const type = pathOr(
        CART_ITEM_TYPE_SPAREPART,
        ["specialArticleType"],
        cur,
      );
      const flagged = pathOr("", ["articleOrderStatus"], cur);
      const valid =
        equals(type, CART_ITEM_TYPE_SPAREPART) ||
        (isOrderView && !equals(flagged, ORDER_STATUS_FLAGGED));

      return acc && valid && hasOnlySpareParts(cur.subArticles, isOrderView);
    },
    true,
    items,
  );
}

function hasCartConfigurableProduct(items: TeckentrupCartArticleTO[]): boolean {
  return reduce(
    (acc, cur) => {
      if (acc || (not(cur.optional) && cur.configurable)) {
        return true;
      }
      return hasCartConfigurableProduct(cur.subArticles);
    },
    false,
    items,
  );
}

function hasCartOnlyAlternativeConfigurableProducts(
  items: TeckentrupCartArticleTO[],
): boolean {
  return reduce(
    (acc, cur) => {
      if (cur.group) {
        return (
          acc && hasCartOnlyAlternativeConfigurableProducts(cur.subArticles)
        );
      }
      return acc && cur.configurable && cur.optional;
    },
    true,
    items,
  );
}

// There are three cases where we cannot rely on shippingMethodAvailable because
// it cannot take into account information about the currently selected view:
//   1. If there is both a spare part and a configurable article in the shopping
//      cart and we start a part order in the orderView and only select the
//      spare part and then switch back to one of the other views, the shipping
//      method was still displayed, although it should actually be hidden due to
//      the configurable article
//   2. If there is a spare part in the shopping cart and we start a part order
//      in the orderView an select nothing and then switch back to one of the
//      other views, the shipping method was not displayed, although it should
//      be visible due to the spare part
//   3. If there are only configurable products in the cart that are all set as
//      alternative positions
export function isShippingMethodVisible(
  cart: ExtendedTeckentrupCartTO,
  isOrderView: boolean,
  hasRightToSee: (booleanAuthorities: string[]) => boolean,
) {
  if (isEmpty(cart.articles.subArticles)) {
    return false;
  }
  if (not(hasRightToSee(cart.authorities.booleanAuthorities))) {
    return false;
  }
  const isConfigurableProductInCart = hasCartConfigurableProduct(
    cart.articles.subArticles,
  );
  const hasOnlyAlternativeConfigurableProducts =
    hasCartOnlyAlternativeConfigurableProducts(cart.articles.subArticles);
  if (not(isOrderView) && isConfigurableProductInCart) {
    return false;
  }

  return (
    cart.shippingMethodAvailable ||
    (!isOrderView &&
      !isConfigurableProductInCart &&
      !hasOnlyAlternativeConfigurableProducts) ||
    !hasOnlyAlternativeConfigurableProducts
  );
}
