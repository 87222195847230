import { Box, Typography } from "@mui/material";
import { length } from "ramda";

import { LvTable } from "../../../hooks/useLvTable";
import { t } from "../../shoppingCart/localizationUtils";
import { ReactComponent as CubeChecked } from "./cube_checked.svg";

type PositionsOverviewProps = Readonly<{
  lvTable: LvTable;
}>;

export function PositionsOverview({ lvTable }: PositionsOverviewProps) {
  const { selectedLvPositions, lvPositions } = lvTable;
  return (
    <Box
      sx={{
        padding: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box>
        <Typography sx={{ marginTop: "-2px" }}>
          {t("lv_position_count_label")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            marginBottom: "-2px",
          }}
        >
          <CubeChecked width={16} height={16} />
          <Typography color="textSecondary" sx={{ fontSize: "small" }}>
            {length(selectedLvPositions)} / {length(lvPositions)}
          </Typography>
        </Box>
      </Box>
      <button className="button is-blue-dark">
        {t("lv_position_action_analyze_all")}
      </button>
    </Box>
  );
}
