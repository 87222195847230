import classNames from "classnames";

import { L10n } from "@encoway/l10n";
import { ParameterTO } from "@encoway/react-components";

import { ConfiguratorComponentProps } from "../../../../types/configuration";

type ParameterErrorProps = Readonly<{
  error: ConfiguratorComponentProps<ParameterTO>["error"];
  id: string;
  isSmall?: boolean;
}>;

export function ParameterError(props: ParameterErrorProps) {
  const { error, id, isSmall = false } = props;

  if (!error || error.id !== id) {
    return null;
  }

  return (
    <div
      className={classNames({
        notification: true,
        "is-warning": true,
        "is-small": isSmall,
      })}
      title={error.message}
    >
      {L10n.format("error_value", error)}
    </div>
  );
}
