import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { equals, isNil, join, not, values } from "ramda";
import { useState } from "react";

import { mapIndexed } from "../utilities/utilities";

import "./GenericDropdown.scss";

type PrimitiveType = string | number | object;

type GenericDropdownProps<T> = {
  dropdownId: string;
  value: T | undefined;
  options: T[];
  onChange: (value: T) => void;
  getDisplayValue?: (value: T) => string;
  isFullwidth?: boolean;
};

export const GenericDropdown = <T extends PrimitiveType>({
  dropdownId,
  value,
  options,
  onChange,
  getDisplayValue,
  isFullwidth,
}: GenericDropdownProps<T>) => {
  const [showOptions, setShowOptions] = useState(false);

  function renderDisplayValue(value: T | undefined): string | number {
    if (isNil(value)) {
      return "";
    }
    if (getDisplayValue) {
      return getDisplayValue(value);
    }
    if (typeof value === "object") {
      return join(" | ", values(value));
    }
    return value;
  }

  return (
    <div
      className={classnames("dropdown generic-dropdown", {
        "is-active": showOptions,
        "is-fullwidth": isFullwidth,
      })}
    >
      <div className="dropdown-trigger">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          data-dropdown-id={dropdownId}
          onClick={() => setShowOptions((prev) => not(prev))}
          onBlur={() => setShowOptions(false)}
        >
          <span className="selected-value-span">
            {renderDisplayValue(value)}
          </span>
          <span className="icon is-small has-text-orange">
            <FontAwesomeIcon
              icon={showOptions ? faAngleUp : faAngleDown}
              size="xs"
            />
          </span>
        </button>
      </div>
      <div className="dropdown-menu">
        <div className="dropdown-content">
          {mapIndexed(
            (option, index) => (
              <DropDownItem
                key={index}
                option={option}
                itemIndex={index}
                onChange={onChange}
                isActive={equals(option, value)}
                dropdownId={dropdownId}
                renderDisplayValue={renderDisplayValue}
              />
            ),
            options,
          )}
        </div>
      </div>
    </div>
  );
};

type DropDownItemProps<T> = {
  option: T;
  itemIndex: number;
  onChange: (value: T) => void;
  isActive: boolean;
  dropdownId: string;
  renderDisplayValue: (value: T) => string | number;
};

const DropDownItem = <T extends PrimitiveType>({
  option,
  itemIndex,
  onChange,
  isActive,
  dropdownId,
  renderDisplayValue,
}: DropDownItemProps<T>) => (
  <button
    className={classnames("dropdown-item", { "is-selected": isActive })}
    onMouseDown={() => onChange(option)}
  >
    <span data-dropdown-entry-id={`${dropdownId}-${itemIndex}`}>
      {renderDisplayValue(option)}
    </span>
  </button>
);
