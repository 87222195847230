import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material";
import classNames from "classnames";
import { findIndex, length, propEq } from "ramda";
import { Dispatch, SetStateAction, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import { ModalBodyWrapper } from "../../../components/modal/modal";
import { useLvConfiguration } from "../../../hooks/useLvConfiguration";
import { LvTable } from "../../../hooks/useLvTable";
import { ResolvedPosition } from "../../../types/lvTable";
import {
  LV_TAB_ID,
  LvTabId,
  startNextPositionConfiguration,
  stopCurrentPositionConfiguration,
} from "../../../utilities/lvUtils";
import { t } from "../../shoppingCart/localizationUtils";
import { LvModalFooter } from "./LvModalFooter";
import { LvPositionView } from "./LvPositionView";
import { PositionsOverview } from "./PositionsOverview";
import { PositionsTreeView } from "./PositionsTreeView";

import "./lvTableTab.scss";

const ButtonsRight = styled("div")({
  display: "flex",
  flex: "1",
  justifyContent: "flex-end",
});

type LvTableTabProps = {
  isHidden: boolean;
  lvTable: LvTable;
  onCancel: () => void;
  setSelectedTab: Dispatch<SetStateAction<LvTabId>>;
};

export const LvTableTab = ({
  isHidden,
  lvTable,
  onCancel,
  setSelectedTab,
}: LvTableTabProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [panelSize, setPanelSize] = useState(30);
  const {
    lvPositions,
    selectedPositionId,
    setSelectedPositionId,
    updateLvPosition,
  } = lvTable;
  const lvConfiguration = useLvConfiguration();

  const selectedPositionIndex = findIndex(
    propEq(selectedPositionId, "id"),
    lvPositions,
  );
  const hasNoNextPosition = selectedPositionIndex === length(lvPositions) - 1;
  const hasNoPreviousPosition = selectedPositionIndex === 0;

  async function goToPosition(previousOrNext: -1 | 1, isDisabled: boolean) {
    if (isDisabled || lvConfiguration.isLoading) {
      return;
    }
    await stopCurrentPositionConfiguration(
      selectedPositionId,
      updateLvPosition,
      lvConfiguration.stopConfAndGetValues,
    );
    const nextPositionId =
      lvTable.lvPositions[selectedPositionIndex + previousOrNext].id;
    setSelectedPositionId(nextPositionId);
    startNextPositionConfiguration(
      nextPositionId,
      lvPositions,
      lvConfiguration.startConfAndSetValues,
    );
  }

  async function fetchAddressAndConstruction() {
    setIsLoading(true);
    try {
      await lvTable.getAddressAndConstruction();
      setSelectedTab(LV_TAB_ID.LV_HEADER_DATA);
    } catch (error) {
      setSelectedTab(LV_TAB_ID.LV_HEADER_DATA);
      lvTable.setFileToOldFile();
    } finally {
      setIsLoading(false);
    }
  }

  const selectedPosition: ResolvedPosition | undefined =
    lvTable.lvPositions[selectedPositionIndex];

  return (
    <>
      <ModalBodyWrapper
        className={classNames("select-positions-tab", {
          "is-hidden": isHidden,
        })}
      >
        <div className="lv-table slim-scrollbar">
          <PanelGroup direction="horizontal">
            <Panel
              defaultSize={30}
              style={{
                display: "flex",
                flexDirection: "column",
                overflowX: "hidden",
              }}
            >
              <PositionsOverview lvTable={lvTable} />
              <div className="horizontal-divider" />
              <PositionsTreeView
                lvTable={lvTable}
                lvConfiguration={lvConfiguration}
              />
            </Panel>
            <PanelResizeHandle className="vertical-divider" />
            <Panel
              defaultSize={70}
              style={{
                overflowX: "hidden",
                overflowY: "auto",
              }}
              onResize={setPanelSize}
            >
              {selectedPosition && (
                <LvPositionView
                  lvTable={lvTable}
                  selectedPosition={selectedPosition}
                  panelSize={panelSize}
                  lvConfiguration={lvConfiguration}
                />
              )}
            </Panel>
          </PanelGroup>
        </div>
      </ModalBodyWrapper>
      <LvModalFooter
        className={classNames({ "is-hidden": isHidden })}
        saveLabel={t("lv_position_continue")}
        saveButtonId="lv_position_continue_header_data"
        onSave={() =>
          lvTable.areLvFilesEqual
            ? setSelectedTab(LV_TAB_ID.LV_HEADER_DATA)
            : fetchAddressAndConstruction()
        }
        cancelLabel={t("cancel")}
        onCancel={onCancel}
        disabled={!lvTable.isAnyProductSelected}
        isLoading={isLoading}
      >
        <ButtonsRight>
          <div>
            <button
              data-button-id="button-previous-lv-position"
              className="button is-blue-dark"
              onClick={() => goToPosition(-1, hasNoPreviousPosition)}
              disabled={hasNoPreviousPosition || lvConfiguration.isLoading}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faAngleLeft} />
              </span>
              <span>{t("lv_position_previous_position")}</span>
            </button>
            <button
              data-button-id="button-next-lv-position"
              className="button is-blue-dark"
              onClick={() => goToPosition(1, hasNoNextPosition)}
              disabled={hasNoNextPosition || lvConfiguration.isLoading}
            >
              <span>{t("lv_position_next_position")}</span>
              <span className="icon">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </button>
          </div>
        </ButtonsRight>
      </LvModalFooter>
    </>
  );
};
