import { not, pathOr, pipe, slice } from "ramda";
import { useEffect, useState } from "react";

import { L10n } from "@encoway/l10n";

import { GenericDropdown } from "../../../../../components/GenericDropdown";
import { InputMaxLength } from "../../../../../components/input/inputMaxLength";
import IdentityStore from "../../../../../http/identityStore";
import { InputConstants } from "../../../../offerManagement/modifyOfferModal/inputConstants";
import {
  getAvailableCountryCodes,
  getPostCodeMaxLength,
  isPostCodeValid,
} from "../differingShippingAddressUtils";
import { DifferingShippingAddressState } from "./differingShippingAddress";

type DifferingShippingAddressContentProps = {
  identityStore: IdentityStore;
  state: DifferingShippingAddressState;
  reset: () => void;
  onChange: (name: keyof DifferingShippingAddressState, value: string) => void;
  setStateValue: (
    key: keyof DifferingShippingAddressState,
    value: string,
  ) => void;
};

export function DifferingShippingAddressContent(
  props: Readonly<DifferingShippingAddressContentProps>,
) {
  const { identityStore, state, reset, onChange, setStateValue } = props;
  const [postCodeValid, setPostCodeValid] = useState<boolean>(
    isPostCodeValid(state.countryCode, state.postCode),
  );

  useEffect(() => {
    setPostCodeValid(isPostCodeValid(state.countryCode, state.postCode));
  }, [state.countryCode, state.postCode]);

  if (not(state.hasDifferingShippingAddress)) {
    return null;
  }

  function postCodeChangeWrapper(value: string) {
    const maxLength = getPostCodeMaxLength(value);
    const postCode = pipe(pathOr("", ["postCode"]), (postCode) =>
      slice(0, maxLength, postCode),
    )(state);
    setStateValue("postCode", postCode);
    onChange("countryCode", value);
  }

  return (
    <div>
      <div className="right-align-button custom-margin-1">
        <button
          className="button is-info is-blue-dark"
          onClick={reset}
          data-button-id="order_dialog_reset_data"
        >
          {L10n.format("order_dialog_reset_data")}
        </button>
      </div>
      <div className="two-columns custom-margin-2">
        <div className="column-entry with-padding">
          <InputMaxLength
            name="companyName"
            value={state.companyName}
            onChange={(event) => onChange("companyName", event.target.value)}
            label={L10n.format("order_dialog_company_name")}
            placeholder={L10n.format("order_dialog_company_name_placeholder")}
            maxLength={InputConstants.length.order_dialog_company_name}
            inputId={"order_dialog_company_name"}
          />
        </div>
        <div className="column-entry with-padding">
          <InputMaxLength
            name="telephoneNumber"
            value={state.telephoneNumber}
            onChange={(event) =>
              onChange("telephoneNumber", event.target.value)
            }
            label={
              L10n.format("order_dialog_telephone_number") +
              L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format(
              "order_dialog_telephone_number_placeholder",
            )}
            maxLength={InputConstants.length.order_dialog_telephone_number}
            inputId={"order_dialog_telephone_number"}
          />
        </div>
      </div>

      <div className="two-columns with padding custom-margin-2 ">
        <div className="column-entry with-padding">
          <InputMaxLength
            name="contactSalutation"
            value={state.contactSalutation}
            onChange={(event) =>
              onChange("contactSalutation", event.target.value)
            }
            label={
              L10n.format("order_dialog_contact_salutation") +
              L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format(
              "order_dialog_contact_salutation_placeholder",
            )}
            maxLength={InputConstants.length.order_dialog_contact_salutation}
            inputId={"order_dialog_contact_salutation"}
          />
        </div>
        <div className="column-entry with-padding"></div>
      </div>

      <div className="two-columns custom-margin-2">
        <div className="column-entry with-padding">
          <InputMaxLength
            name="contactFirstName"
            value={state.contactFirstName}
            onChange={(event) =>
              onChange("contactFirstName", event.target.value)
            }
            label={
              L10n.format("order_dialog_contact_firstName") +
              L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format(
              "order_dialog_contact_firstName_placeholder",
            )}
            maxLength={InputConstants.length.order_dialog_contact_firstName}
            inputId={"order_dialog_contact_firstName"}
          />
        </div>
        <div className="column-entry with-padding">
          <InputMaxLength
            name="contactLastName"
            value={state.contactLastName}
            onChange={(event) =>
              onChange("contactLastName", event.target.value)
            }
            label={
              L10n.format("order_dialog_contact_lastName") +
              L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format(
              "order_dialog_contact_lastName_placeholder",
            )}
            maxLength={InputConstants.length.order_dialog_contact_lastName}
            inputId={"order_dialog_contact_lastName"}
          />
        </div>
      </div>
      <div className="two-columns custom-margin-2">
        <div className="column-entry with-padding">
          <InputMaxLength
            name="street"
            value={state.street}
            onChange={(event) => onChange("street", event.target.value)}
            label={
              L10n.format("order_dialog_street") +
              L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format("order_dialog_street_placeholder")}
            maxLength={InputConstants.length.order_dialog_street}
            inputId={"order_dialog_street"}
          />
        </div>
        <div className="column-entry with-padding">
          <InputMaxLength
            name="houseNumber"
            value={state.houseNumber}
            onChange={(event) => onChange("houseNumber", event.target.value)}
            label={
              L10n.format("order_dialog_house_number") +
              L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format("order_dialog_house_number_placeholder")}
            maxLength={InputConstants.length.order_dialog_house_number}
            inputId={"order_dialog_house_number"}
          />
        </div>
      </div>
      <div className="two-columns custom-margin-2">
        <div className="column-entry multi-column-input">
          <label className="label">
            {L10n.format("order_dialog_post_code") +
              L10n.format("mandatory_symbol")}
          </label>
          <div className="columns with-padding">
            <div className="column is-two-quarter">
              <GenericDropdown
                dropdownId="order_dialog_post_code"
                value={state.countryCode}
                options={getAvailableCountryCodes(identityStore)}
                onChange={postCodeChangeWrapper}
              />
            </div>
            <div className="column">
              <InputMaxLength
                name="postCode"
                value={state.postCode}
                onChange={(event) => onChange("postCode", event.target.value)}
                placeholder={L10n.format("order_dialog_post_code_placeholder")}
                maxLength={pathOr(
                  InputConstants.length.order_dialog_post_code,
                  ["length", state.countryCode, "order_dialog_post_code"],
                  InputConstants,
                )}
                isInputValid={postCodeValid}
                hasWarning={state.countryCode === "DE"}
                warningLabel={"order_dialog_post_code_warning"}
                warningDuration={6000}
                inputId="order_dialog_post_code"
              />
            </div>
          </div>
        </div>
        <div className="column-entry with-padding">
          <InputMaxLength
            name="city"
            value={state.city}
            onChange={(event) => onChange("city", event.target.value)}
            label={
              L10n.format("order_dialog_city") + L10n.format("mandatory_symbol")
            }
            placeholder={L10n.format("order_dialog_city_placeholder")}
            maxLength={InputConstants.length.order_dialog_city}
            inputId="order_dialog_city"
          />
        </div>
      </div>
      <div className="bottom-space" />
    </div>
  );
}
