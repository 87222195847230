import {
  append,
  defaultTo,
  filter,
  find,
  findIndex,
  isEmpty,
  isNil,
  last,
  omit,
  pathOr,
  pick,
  propEq,
  take,
  test,
  update,
} from "ramda";

import {
  addCustomer,
  getCustomers,
  updateCustomer,
} from "../../../service/customerManagementService";
import { PartialBy } from "../../../types/app";
import {
  ExtendedTeckentrupCartTO,
  QuoteShareTO,
  TeckentrupCartTO,
} from "../../../types/cart";
import { safePick } from "../../../utilities/utilities";
import {
  ContactDto,
  CustomerDto,
} from "../../customerManagement/customerManagementUtils";
import { escapeRegExCharacters, sortItems } from "../offerManagementUtils";
import {
  findCustomer,
  findCustomerFromState,
  getFoundNumberOrName,
  isComplete,
} from "./modifyOfferDialogUtils";

export type ProjectInformationReadOnly = {
  status: string;
  orderNumber: string;
  creationDate: string;
  createdBy: string;
  modificationDate: string;
  lastModifiedBy: string;
};

export type ProjectInformation = {
  buildProject: string;
  referenceNumber: string;
  orderedDate: string;
  sourceDocumentName: string;
  followUpDate: string;
  comment: string;
};

export type ProjectInformationExtended = {
  accountId: string;
  clientId: string;
  creatorUserId: string;
  priceDate: string;
  currencyConversionRate: number;
  checkoutDate: string;
  contributionMarginNumber: string;
  copiedFrom: string;
  parentOrder: string;
  subOrders: string[];
};

export type ProjectInformationShared = {
  children: QuoteShareTO[];
  parent: QuoteShareTO | null;
};

export type CustomerInformation = {
  customerNumber: string;
  customerName: string;
  title: string;
  firstname: string;
  surname: string;
  street: string;
  houseNumber: string;
  zip: string;
  city: string;
  phone: string;
  email: string;
  id: string | number | null;
};

export type CustomerSearch = {
  customers: CustomerDto[];
  selectedCustomer: CustomerDto | null;
  selectedAddress: ContactDto | null;
  searchResult: CustomerDto[] | null;
  searchValue: string;
  toggleCustomerAddress: boolean;
  disableSearchResult: boolean;
};

export type ShippingAddress = {
  companyName: string;
  contactSalutation: string;
  contactFirstName: string;
  contactLastName: string;
  telephoneNumber: string;
  street: string;
  houseNumber: string;
  postCode: string;
  city: string;
  countryCode: string;
};

export type ThirdPartySystemInformation = {
  thirdPartySystemName: string;
  thirdPartySystemRequestId: string;
  thirdPartySystemReturnAddress: string;
};

export type ModifyOfferModalState = {
  projectInformationReadOnly: ProjectInformationReadOnly;
  projectInformation: ProjectInformation;
  projectInformationExtended: ProjectInformationExtended;
  projectInformationShared: ProjectInformationShared;
  customerInformation: CustomerInformation;
  customerSearch: CustomerSearch;
  shippingAddress: ShippingAddress;
  thirdPartySystemInformation: ThirdPartySystemInformation;
  status: number;
  customerAddLoading: boolean;
  shippingAddressMandatoriesCompleted: boolean;
  customerAddLoadingToolTip: boolean;
  isComplete: boolean;
  edited: boolean;
  toggleCancelDialog: boolean;
  customerNumberEmpty: boolean;
  customerNameEmpty: boolean;
  customerExists: boolean;
};

export type ModifyOfferModalReducedState = PartialBy<
  ModifyOfferModalState,
  "thirdPartySystemInformation"
>;

export type ModifyOfferModalStateObjectKeys = keyof Pick<
  ModifyOfferModalState,
  | "projectInformationReadOnly"
  | "projectInformation"
  | "projectInformationExtended"
  | "projectInformationShared"
  | "customerInformation"
  | "customerSearch"
  | "shippingAddress"
  | "thirdPartySystemInformation"
>;

export const INITIAL_MODIFY_OFFER_MODAL: ModifyOfferModalState = {
  projectInformationReadOnly: {
    status: "",
    orderNumber: "",
    creationDate: "",
    createdBy: "",
    modificationDate: "",
    lastModifiedBy: "",
  },
  projectInformation: {
    buildProject: "",
    referenceNumber: "",
    orderedDate: "",
    sourceDocumentName: "-",
    followUpDate: "",
    comment: "",
  },
  projectInformationExtended: {
    accountId: "",
    clientId: "",
    creatorUserId: "",
    priceDate: "",
    currencyConversionRate: 1,
    checkoutDate: "",
    contributionMarginNumber: "",
    copiedFrom: "",
    parentOrder: "",
    subOrders: [],
  },
  projectInformationShared: {
    children: [],
    parent: null,
  },
  customerInformation: {
    customerNumber: "",
    customerName: "",
    title: "",
    firstname: "",
    surname: "",
    street: "",
    houseNumber: "",
    zip: "",
    city: "",
    phone: "",
    email: "",
    id: null,
  },
  customerSearch: {
    customers: [],
    selectedCustomer: null,
    selectedAddress: null,
    searchResult: [],
    searchValue: "",
    toggleCustomerAddress: false,
    disableSearchResult: false,
  },
  shippingAddress: {
    companyName: "",
    contactSalutation: "",
    contactFirstName: "",
    contactLastName: "",
    telephoneNumber: "",
    street: "",
    houseNumber: "",
    postCode: "",
    city: "",
    countryCode: "",
  },
  thirdPartySystemInformation: {
    thirdPartySystemName: "-",
    thirdPartySystemRequestId: "-",
    thirdPartySystemReturnAddress: "-",
  },
  status: -1,
  customerAddLoading: false,
  shippingAddressMandatoriesCompleted: true,
  customerAddLoadingToolTip: false,
  isComplete: false,
  edited: false,
  toggleCancelDialog: false,
  customerNumberEmpty: false,
  customerNameEmpty: false,
  customerExists: false,
} as const;

function getProjectInformationReadonly(
  cartTO: TeckentrupCartTO | ExtendedTeckentrupCartTO,
): ProjectInformationReadOnly {
  return {
    ...INITIAL_MODIFY_OFFER_MODAL.projectInformationReadOnly,
    ...pick(["orderNumber", "creationDate", "modificationDate"], cartTO),
    ...safePick(["status", "createdBy", "lastModifiedBy"], cartTO.headerData),
  };
}

function getProjectInformation(
  cartTO: TeckentrupCartTO | ExtendedTeckentrupCartTO,
): ProjectInformation {
  return {
    ...INITIAL_MODIFY_OFFER_MODAL.projectInformation,
    ...safePick(
      [
        "buildProject",
        "referenceNumber",
        "orderedDate",
        "sourceDocumentName",
        "followUpDate",
        "comment",
      ],
      cartTO.headerData,
    ),
  };
}

function getProjectInformationExtended(
  cartTO: TeckentrupCartTO | ExtendedTeckentrupCartTO,
): ProjectInformationExtended {
  return {
    ...INITIAL_MODIFY_OFFER_MODAL.projectInformationExtended,
    ...pick(
      [
        "creatorUserId",
        "priceDate",
        "checkoutDate",
        "contributionMarginNumber",
        "currencyConversionRate",
      ],
      cartTO,
    ),
    ...safePick(
      ["accountId", "clientId", "subOrders", "parentOrder", "copiedFrom"],
      cartTO.headerData,
    ),
  };
}

function getProjectInformationShared(
  cartTO: TeckentrupCartTO | ExtendedTeckentrupCartTO,
): ProjectInformationShared {
  const shareInformationTO = cartTO.headerData?.shareInformationTO;
  return {
    ...INITIAL_MODIFY_OFFER_MODAL.projectInformationShared,
    ...(shareInformationTO && {
      parent: shareInformationTO.parent,
      children: Object.values(shareInformationTO.children ?? []),
    }),
  };
}

function getCustomerInformation(
  cartTO: TeckentrupCartTO | ExtendedTeckentrupCartTO,
): CustomerInformation {
  const address = cartTO.headerData?.address;
  return {
    ...INITIAL_MODIFY_OFFER_MODAL.customerInformation,
    ...(address && {
      ...pick(["email", "street", "houseNumber", "city"], address),
      customerName: address.companyName,
      title: address.contactSalutation,
      firstname: address.contactFirstName,
      surname: address.contactLastName,
      phone: address.telephoneNumber,
      zip: address.postCode,
      customerNumber: address.customerNo,
    }),
  };
}

function getShippingAddress(
  cartTO: TeckentrupCartTO | ExtendedTeckentrupCartTO,
): ShippingAddress {
  return {
    ...INITIAL_MODIFY_OFFER_MODAL.shippingAddress,
    ...cartTO.headerData?.shippingAddress,
  };
}

function getThirdPartySystemInformation(
  cartTO: TeckentrupCartTO | ExtendedTeckentrupCartTO,
): ThirdPartySystemInformation {
  return {
    ...INITIAL_MODIFY_OFFER_MODAL.thirdPartySystemInformation,
    ...safePick(
      [
        "thirdPartySystemName",
        "thirdPartySystemRequestId",
        "thirdPartySystemReturnAddress",
      ],
      cartTO.headerData,
    ),
  };
}

function getIsComplete(
  cartTO: TeckentrupCartTO | ExtendedTeckentrupCartTO,
): boolean {
  return isComplete(
    cartTO.headerData?.buildProject,
    cartTO.headerData?.referenceNumber,
    INITIAL_MODIFY_OFFER_MODAL.shippingAddressMandatoriesCompleted,
  );
}

export function prepareModifyOfferModal(
  cartTO: TeckentrupCartTO | ExtendedTeckentrupCartTO | undefined,
): ModifyOfferModalReducedState {
  if (isNil(cartTO)) {
    return INITIAL_MODIFY_OFFER_MODAL;
  }
  const preparedModifyOfferDialogState = {
    ...INITIAL_MODIFY_OFFER_MODAL,
    projectInformationReadOnly: getProjectInformationReadonly(cartTO),
    projectInformation: getProjectInformation(cartTO),
    projectInformationExtended: getProjectInformationExtended(cartTO),
    projectInformationShared: getProjectInformationShared(cartTO),
    customerInformation: getCustomerInformation(cartTO),
    shippingAddress: getShippingAddress(cartTO),
    thirdPartySystemInformation: getThirdPartySystemInformation(cartTO),
    isComplete: getIsComplete(cartTO),
  };
  return cartTO.headerData?.isRequest
    ? preparedModifyOfferDialogState
    : omit(["thirdPartySystemInformation"], preparedModifyOfferDialogState);
}

export const prepareInformationDTO = ({
  customerInformation,
  shippingAddress,
  projectInformation: { buildProject, referenceNumber, comment, followUpDate },
  projectInformationReadOnly: { lastModifiedBy },
}: {
  customerInformation: ModifyOfferModalState["customerInformation"];
  shippingAddress: ModifyOfferModalState["shippingAddress"];
  projectInformation: ModifyOfferModalState["projectInformation"];
  projectInformationReadOnly: ModifyOfferModalState["projectInformationReadOnly"];
}) => ({
  buildProject,
  referenceNumber,
  comment,
  lastModifiedBy,
  followUpDate,
  address: {
    ...pick(["email", "street", "houseNumber", "city"], customerInformation),
    companyName: customerInformation.customerName,
    customerNo: customerInformation.customerNumber,
    contactSalutation: customerInformation.title,
    contactFirstName: customerInformation.firstname,
    contactLastName: customerInformation.surname,
    telephoneNumber: customerInformation.phone,
    postCode: customerInformation.zip,
  },
  shippingAddress: {
    companyName: shippingAddress.companyName,
    contactSalutation: shippingAddress.contactSalutation,
    contactFirstName: shippingAddress.contactFirstName,
    contactLastName: shippingAddress.contactLastName,
    telephoneNumber: shippingAddress.telephoneNumber,
    street: shippingAddress.street,
    houseNumber: shippingAddress.houseNumber,
    postCode: shippingAddress.postCode,
    city: shippingAddress.city,
    countryCode: shippingAddress.countryCode,
  },
});

export const InputChange =
  (name: string, value: string, prop: ModifyOfferModalStateObjectKeys) =>
  (state: ModifyOfferModalReducedState) => {
    const newState = { ...state, [prop]: { ...state[prop], [name]: value } };
    return {
      ...newState,
      isComplete: isComplete(
        newState.projectInformation.buildProject,
        newState.projectInformation.referenceNumber,
        newState.shippingAddressMandatoriesCompleted,
      ),
      edited: true,
    };
  };

export const ShippingAddressChange =
  (name: string, value: string) => (state: ModifyOfferModalReducedState) => {
    return {
      ...state,
      shippingAddress: {
        ...state.shippingAddress,
        [name]: value,
      },
    };
  };

export const SetShippingAddress =
  (value: ModifyOfferModalState["shippingAddress"]) =>
  (state: ModifyOfferModalReducedState) => {
    return {
      ...state,
      shippingAddress: value,
    };
  };

export const ShippingAddressMandatoriesCompleted =
  (value: ModifyOfferModalState["shippingAddressMandatoriesCompleted"]) =>
  (state: ModifyOfferModalReducedState) => {
    return {
      ...state,
      shippingAddressMandatoriesCompleted: value,
      isComplete: isComplete(
        state.projectInformation.buildProject,
        state.projectInformation.referenceNumber,
        value,
      ),
    };
  };

export const CustomerInputChange =
  (name: string, value: string) => (state: ModifyOfferModalReducedState) => {
    const newState = {
      ...state,
      customerInformation: {
        ...state.customerInformation,
        [name]: value,
        id: null,
      },
    };

    return {
      ...newState,
      isComplete: isComplete(
        newState.projectInformation.buildProject,
        newState.projectInformation.referenceNumber,
        state.shippingAddressMandatoriesCompleted,
      ),
      edited: true,
    };
  };

export const CustomerNumberChange =
  (value: string) => (state: ModifyOfferModalReducedState) => {
    const foundCustomer = findCustomer(value, state.customerSearch.customers);
    return {
      ...state,
      customerInformation: {
        ...state.customerInformation,
        customerNumber: value,
        id: null,
      },
      isComplete: isComplete(
        state.projectInformation.buildProject,
        state.projectInformation.referenceNumber,
        state.shippingAddressMandatoriesCompleted,
      ),
      customerExists: Boolean(foundCustomer),
      edited: true,
    };
  };

export const ResetCustomerNumberTag = (
  state: ModifyOfferModalReducedState,
) => ({
  ...state,
  customerExists: false,
  customerInformation: {
    ...state.customerInformation,
    selectedCustomer: null,
  },
});

export const SetCustomerNumberExists = (
  state: ModifyOfferModalReducedState,
) => {
  const foundCustomer = findCustomer(
    state.customerInformation.customerNumber,
    state.customerSearch.customers,
  );
  return {
    ...state,
    customerInformation: {
      ...state.customerInformation,
      id: null,
    },
    customerExists: Boolean(foundCustomer),
  };
};

export const ToggleCancelDialog = (state: ModifyOfferModalReducedState) => ({
  ...state,
  toggleCancelDialog: !state.toggleCancelDialog,
});

// SearchCustomer
export const GetCustomers = async (companyId: number | null | undefined) => {
  const {
    status,
    data: { pagedCustomers },
  } = await getCustomers();
  return (state: ModifyOfferModalReducedState) => ({
    ...state,
    status,
    customerSearch: {
      ...state.customerSearch,
      customers: pagedCustomers,
    },
    companyId,
  });
};

export const SearchValueChange =
  (searchValue: string) => (state: ModifyOfferModalReducedState) => {
    const filteredCustomers = filter(({ customerNumber, customerName }) => {
      const regExString = new RegExp(
        `${escapeRegExCharacters(searchValue)}.*`,
        "gi",
      );
      return (
        test(regExString, customerNumber.toLowerCase()) ||
        test(regExString, customerName.toLowerCase())
      );
    }, state.customerSearch.customers);

    return {
      ...state,
      customerSearch: {
        ...state.customerSearch,
        disableSearchResult: false,
        searchResult: isEmpty(searchValue)
          ? []
          : sortItems<CustomerDto>(
              "customerName",
              "asc",
              take(11, filteredCustomers),
            ),
        searchValue,
      },
    };
  };

export const ToggleSelectedCustomer =
  (selectedCustomer: CustomerDto) => (state: ModifyOfferModalReducedState) => {
    const sortedCompanyContacts = sortItems<ContactDto>(
      "surname",
      "asc",
      selectedCustomer.companyContacts,
    );
    return {
      ...state,
      customerSearch: {
        ...state.customerSearch,
        toggleCustomerAddress: true,
        selectedAddress: sortedCompanyContacts![0],
        selectedCustomer: {
          ...selectedCustomer,
          companyContacts: sortedCompanyContacts ?? [],
        },
      },
    };
  };

export const SetCustomerAddress =
  (
    selectedAddress: ModifyOfferModalState["customerSearch"]["selectedAddress"],
  ) =>
  (state: ModifyOfferModalReducedState) => ({
    ...state,
    customerSearch: {
      ...state.customerSearch,
      selectedAddress,
    },
  });

export const CustomerSearchSave = (
  state: ModifyOfferModalReducedState,
): ModifyOfferModalReducedState => ({
  ...state,
  edited: false,
  customerInformation: {
    ...state.customerInformation,
    ...state.customerSearch.selectedAddress,
    customerNumber: pathOr(
      INITIAL_MODIFY_OFFER_MODAL.customerInformation.customerNumber,
      ["customerSearch", "selectedCustomer", "customerNumber"],
      state,
    ),
    customerName: pathOr(
      INITIAL_MODIFY_OFFER_MODAL.customerInformation.customerName,
      ["customerSearch", "selectedCustomer", "customerName"],
      state,
    ),
  },
});

export const ResetSearchValue = (state: ModifyOfferModalReducedState) => ({
  ...state,
  customerSearch: {
    ...state.customerSearch,
    searchValue: "",
    searchResult: [],
    selectedCustomer: null,
    selectedAddress: null,
    toggleCustomerAddress: false,
  },
});

export const AddCustomer = async (
  customer: CustomerInformation,
  companyId: number | undefined | null,
) => {
  const newCustomer = {
    ...pick(["customerNumber", "customerName"], customer),
    companyId,
    companyContacts: [omit(["id"], customer)],
  };
  const {
    status,
    data: { returnValue },
  } = await addCustomer(newCustomer);
  return (state: ModifyOfferModalReducedState) => ({
    ...state,
    edited: false,
    status,
    customerNumberEmpty: false,
    customerNameEmpty: false,
    customerSearch: {
      ...state.customerSearch,
      customers: append(returnValue, state.customerSearch.customers),
    },
  });
};

function findCustomerAddress(
  customerAddress: ModifyOfferModalState["customerInformation"],
  selectedCustomer: CustomerDto | undefined | null,
  customers: CustomerDto[],
): CustomerDto | undefined {
  if (selectedCustomer) {
    return selectedCustomer;
  }

  return find<CustomerDto>(propEq(customerAddress.id, "id"), customers);
}

const prepareNewCustomer = (
  customerAddress: ModifyOfferModalState["customerInformation"],
  selectedCustomerAddress: CustomerDto | undefined,
) => ({
  ...(selectedCustomerAddress &&
    omit(["companyNumber", "deliveryContacts"], selectedCustomerAddress)),
  customerNumber: customerAddress.customerNumber,
  customerName: customerAddress.customerName,
  companyContacts: [
    ...(selectedCustomerAddress ? selectedCustomerAddress.companyContacts : []),
    omit(["id"], customerAddress),
  ],
});

const findCustomerAndAddTheChangedAddress = (
  customers: CustomerDto[],
  returnValue: CustomerDto,
) =>
  update(
    findIndex(propEq(returnValue.id, "id"))(customers),
    returnValue,
    customers,
  );

/**
 * Edits the companyContacts of an existing customer or adds a new customer to the customers.
 * @param {*} customerAddress the given customerAdress
 * @param {*} selectedCustomer the selected Customer
 */
export const EditCustomer = async (
  customerAddress: ModifyOfferModalState["customerInformation"],
  selectedCustomer: CustomerDto | undefined | null,
  customers: CustomerDto[],
) => {
  const selectedCustomerAddress = findCustomerAddress(
    customerAddress,
    selectedCustomer,
    customers,
  );
  const newCustomer = prepareNewCustomer(
    customerAddress,
    selectedCustomerAddress,
  );
  const {
    status,
    data: { returnValue },
  } = await updateCustomer(newCustomer);
  const selectedAddress = defaultTo(null, last(returnValue.companyContacts));
  return (
    state: ModifyOfferModalReducedState,
  ): ModifyOfferModalReducedState => ({
    ...state,
    edited: false,
    status,
    customerNumberEmpty: false,
    customerNameEmpty: false,
    customerSearch: {
      ...state.customerSearch,
      selectedAddress,
      selectedCustomer: returnValue,
      customers: findCustomerAndAddTheChangedAddress(
        state.customerSearch.customers,
        returnValue,
      ),
    },
  });
};

export const ToggleAddCustomerLoading =
  (showToolTip = false) =>
  (state: ModifyOfferModalReducedState) => ({
    ...state,
    customerAddLoadingToolTip: showToolTip,
    customerAddLoading: !state.customerAddLoading,
  });

export const ToggleCustomerAddedToolTip =
  (showToolTip = false) =>
  (state: ModifyOfferModalReducedState) => ({
    ...state,
    customerAddLoadingToolTip: showToolTip && !state.customerAddLoadingToolTip,
  });

export const ToggleCustomerNumberNotSetWarning =
  (
    numberValid: ModifyOfferModalState["customerNumberEmpty"],
    nameValid: ModifyOfferModalState["customerNameEmpty"],
  ) =>
  (state: ModifyOfferModalReducedState) => ({
    ...state,
    edited: false,
    customerNumberEmpty: numberValid,
    customerNameEmpty: nameValid,
  });

export const PreSelectCustomer = (
  state: ModifyOfferModalReducedState,
): ModifyOfferModalReducedState => {
  const {
    customerInformation: { customerNumber, customerName },
    customerSearch: { searchValue },
  } = state;

  if (
    isEmpty(customerNumber) &&
    isEmpty(customerName) &&
    !isEmpty(searchValue)
  ) {
    return state;
  }

  const foundCustomer = findCustomerFromState(state);
  const foundCustomerNumberOrName = getFoundNumberOrName(foundCustomer, state);
  const searchResultState = SearchValueChange(foundCustomerNumberOrName)(state);

  return {
    ...state,
    customerSearch: {
      ...state.customerSearch,
      searchValue: foundCustomerNumberOrName,
      searchResult: searchResultState.customerSearch.searchResult,
      selectedCustomer: foundCustomer,
      selectedAddress: foundCustomer ? foundCustomer.companyContacts[0] : null,
      toggleCustomerAddress: foundCustomer
        ? Boolean(foundCustomer.companyContacts)
        : false,
    },
  };
};

export type HeaderDataPayload = Partial<
  ReturnType<typeof prepareInformationDTO>
>;
