import classNames from "classnames";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import {
  any,
  isEmpty,
  isNil,
  join,
  length,
  lt,
  map,
  prop,
  propEq,
} from "ramda";
import { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from "react";

import { ModalBodyWrapper } from "../../../components/modal/modal";
import { Config } from "../../../config/config";
import { LvTable } from "../../../hooks/useLvTable";
import { parsePdf } from "../../../service/lvService";
import { LV_TAB_ID, LvTabId } from "../../../utilities/lvUtils";
import { t } from "../../shoppingCart/localizationUtils";
import { LvModalFooter } from "./LvModalFooter";
import { PdfDocument } from "./PdfDocument";

GlobalWorkerOptions.workerSrc = `${Config.LvImport.baseUrl}${Config.LvImport.pdfWorkerSrc}`;

export const VALID_LV_FILE_TYPES = [{ key: ".pdf", type: "application/pdf" }];

const INITIAL_LV_IMPORT_OPTIONS = {
  firstPage: "1",
  lastPage: "0",
  headerHeightCm: "0",
  footerHeightCm: "0",
};

export type LvImportOptions = typeof INITIAL_LV_IMPORT_OPTIONS;

type LvImportTabProps = {
  isHidden: boolean;
  lvTable: LvTable;
  onCancel: () => void;
  setSelectedTab: Dispatch<SetStateAction<LvTabId>>;
};

export const LvImportTab = ({
  isHidden,
  lvTable,
  onCancel,
  setSelectedTab,
}: LvImportTabProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [lvImportOptions, setLvImportOptions] = useState(
    INITIAL_LV_IMPORT_OPTIONS,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [alreadyImported, setAlreadyImported] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (isNil(fileList) || isEmpty(fileList)) {
      return;
    }
    handleFileChange(fileList[0]);
  };

  const handleFileChange = (file: File) => {
    if (any(propEq(file.type, "type"), VALID_LV_FILE_TYPES)) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }
    setLvImportOptions(INITIAL_LV_IMPORT_OPTIONS);
    unsetAlreadyImported();
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = function () {
      if (reader?.result) {
        const transfer = getDocument(reader.result);
        transfer.promise.then(function (doc) {
          setLvImportOptionsValue("lastPage", doc.numPages);
          setTotalPages(doc.numPages);
        });
      }
    };
  };

  const handleInputClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleUploadClick = async () => {
    if (!selectedFile) {
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("pdf_input_file", selectedFile);
      formData.append("pdf_first_page_lv", lvImportOptions.firstPage);
      formData.append("pdf_last_page_lv", lvImportOptions.lastPage);
      // TODO: Remove here and in backend, if no longer needed
      formData.append(
        "pdf_header_height_in_cm",
        lvImportOptions.headerHeightCm,
      );
      formData.append(
        "pdf_footer_height_in_cm",
        lvImportOptions.footerHeightCm,
      );
      const { data } = await parsePdf(formData);
      await lvTable.initializeLvStructure(selectedFile, data);
      setAlreadyImported(true);
      setSelectedTab(LV_TAB_ID.LV_POSITIONS);
    } catch (error) {
      unsetAlreadyImported();
    } finally {
      setIsLoading(false);
    }
  };

  const setLvImportOptionsValue = (
    name: keyof LvImportOptions,
    value: string | number,
  ) => {
    setLvImportOptions({ ...lvImportOptions, [name]: String(value) });
  };

  const unsetAlreadyImported = () => {
    lvTable.clearLvTableState();
    setAlreadyImported(false);
  };

  const removeFile = () => {
    setSelectedFile(null);
    setTotalPages(0);
    setIsLoading(false);
    setLvImportOptions(INITIAL_LV_IMPORT_OPTIONS);
    unsetAlreadyImported();
    if (hiddenFileInput?.current) {
      hiddenFileInput.current.value = "";
    }
  };

  return (
    <>
      <ModalBodyWrapper
        className={classNames({ "is-hidden": isHidden }, "p-0")}
      >
        <input
          ref={hiddenFileInput}
          id="file"
          type="file"
          accept={join(",", map(prop("key"), VALID_LV_FILE_TYPES))}
          className="is-hidden"
          onChange={onFileChange}
        />
        <PdfDocument
          handleInputClick={handleInputClick}
          selectedFile={selectedFile}
          onRemoveFile={removeFile}
          totalPages={totalPages}
          lvImportOptions={lvImportOptions}
          handleFileChange={handleFileChange}
          setLvImportOptionsValue={setLvImportOptionsValue}
          unsetAlreadyImported={unsetAlreadyImported}
        />
      </ModalBodyWrapper>
      <LvModalFooter
        className={classNames({ "is-hidden": isHidden })}
        cancelLabel={t("cancel")}
        saveLabel={t("next")}
        saveButtonId="lv_import_next"
        onCancel={onCancel}
        hasWarning={lt(0, length(lvTable.lvPositions))}
        onSave={
          alreadyImported
            ? () => setSelectedTab(LV_TAB_ID.LV_POSITIONS)
            : handleUploadClick
        }
        disabled={!selectedFile}
        isLoading={isLoading}
      />
    </>
  );
};
