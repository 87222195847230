import { filter, pathOr, propEq } from "ramda";

import { Quantity } from "@encoway/react-components";

import { ParameterTO } from "../../../../types/@encoway/Parameter";
import { Value } from "../../../../types/@encoway/Value";
import { ConfiguratorComponentProps } from "../../../../types/configuration";

const FALLBACK_QUANTITY = {
  currentMaximumQuantity: 1,
  currentQuantity: 1,
  maximumQuantity: 1,
  minimumQuantity: 1,
};

type ParameterQuantityProps = Readonly<{
  data: ParameterTO;
  onValueChanged: ConfiguratorComponentProps<ParameterTO>["onValueChanged"];
}>;

export function ParameterQuantity(props: ParameterQuantityProps) {
  const selected = filter(
    propEq(true, "selected"),
    pathOr<Value[]>([], ["data", "values"], props),
  );
  const quantity = pathOr(FALLBACK_QUANTITY, [0, "quantity"], selected);
  return (
    <Quantity
      currentMaximumQuantity={quantity.currentMaximumQuantity}
      currentQuantity={quantity.currentQuantity}
      maximumQuantity={quantity.maximumQuantity}
      minimumQuantity={quantity.minimumQuantity}
      data={props.data}
      onValueChanged={props.onValueChanged}
    />
  );
}
