import { faCircle, faCircleDot } from "@fortawesome/free-regular-svg-icons";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { isNil, map, not, pathOr } from "ramda";
import { useEffect, useState } from "react";

import { ParameterTO } from "../types/@encoway/Parameter";
import { Value } from "../types/@encoway/Value";
import { ConfiguratorComponentProps } from "../types/configuration";

type ItemIconProps = {
  selected: boolean;
  isLoading: boolean;
};

const ItemIcon = ({ selected, isLoading }: Readonly<ItemIconProps>) => {
  if (isLoading) {
    return (
      <FontAwesomeIcon icon={faCircleNotch} spin style={{ color: "#ef7b22" }} />
    );
  }

  return (
    <FontAwesomeIcon
      icon={selected ? faCircleDot : faCircle}
      width="13.7167px"
    />
  );
};

type RadioButtonProps = ConfiguratorComponentProps<ParameterTO> & {
  item: Value;
  loadingValue: string | null;
  onSelect: (value: string) => void;
};

const RadioButton = ({
  item,
  loadingValue,
  onSelect,
  ...props
}: Readonly<RadioButtonProps>) => {
  const isUnselected = isNil(loadingValue) && not(item.selected);
  return (
    <button
      className={classnames("button is-ghost is-extended-radio-button", {
        "is-selected": item.selected,
        "is-selectable": isUnselected && item.selectable,
        "is-not-selectable": isUnselected && not(item.selectable),
      })}
      data-radio-button-id={`${props.data.name}-${item.value}`}
      data-field-id="radio-button"
      disabled={not(props.data.editable)}
      {...(props.data.editable && { onClick: () => onSelect(item.value) })}
    >
      <span className="icon">
        <ItemIcon
          selected={item.selected}
          isLoading={loadingValue === item.value && props.loading}
        />
      </span>
      <span>{item.translatedValue}</span>
    </button>
  );
};

export const CustomExtendedRadioButton = (
  props: Readonly<ConfiguratorComponentProps<ParameterTO>>,
) => {
  const [loadingValue, setLoadingValue] = useState<string | null>(null);
  const { setLoading } = props;

  const currentValue = pathOr(
    "",
    ["data", "selectedValues", 0, "value"],
    props,
  );

  useEffect(() => {
    setLoadingValue(null);
    setLoading(false);
  }, [props.data]);

  function onSelect(value: string) {
    if (currentValue === value) {
      return;
    }
    setLoadingValue(value);
    setLoading(true);
    props.onValueChanged(props.data, value);
  }

  return (
    <>
      {map(
        (item) => (
          <RadioButton
            key={item.value}
            item={item}
            loadingValue={loadingValue}
            onSelect={onSelect}
            {...props}
          />
        ),
        pathOr<Value[]>([], ["data", "values"], props),
      )}
    </>
  );
};
