import { useRef } from "react";

import { useClickOutside } from "../../../../../../hooks/useClickOutside";
import { t } from "../../../../localizationUtils";

import "./deleteCartItemWarningPopup.scss";

type DeleteCartItemWarningPopupProps = Readonly<{
  label: string;
  onDelete: () => void;
  onCancel: () => void;
  className?: string;
}>;

export function DeleteCartItemWarningPopup(
  props: DeleteCartItemWarningPopupProps,
) {
  const { label, onDelete, onCancel, className = "warning-container" } = props;
  const root = useRef<HTMLSpanElement>(null);
  useClickOutside(root, onCancel);

  return (
    <span className={className} ref={root}>
      <div className="sec-warn is-visible" data-dialog-id="delete_warn">
        {label}
        <div className="buttons">
          <button
            data-button-id="accept_delete"
            className="button is-info is-pulled-right is-orange"
            onClick={onDelete}
          >
            {t("yes")}
          </button>
          <button
            data-button-id="cancel_delete"
            className="button is-info is-blue-dark"
            onClick={onCancel}
          >
            {t("no")}
          </button>
        </div>
      </div>
    </span>
  );
}
