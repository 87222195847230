import { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { OfferManagementService } from "@encoway/cart-service-js-client";

import { SERVICE_BASE_URL } from "../../../context/AppProvider";
import { useCart } from "../../../hooks/useCart";
import { useConfiguration } from "../../../hooks/useConfiguration";
import { ROUTES } from "../../../http/constants";
import { FreeArticleTO } from "../../../types/cart";
import { PrintOptionsValues } from "../../../types/print";
import { printOfferEvent, track } from "../../../utilities/eventTracking";
import { ModifyOfferModalReducedState } from "../../offerManagement/modifyOfferModal/modifyOfferModalActions";
import { createNewPrintOptionsInformation } from "../components/modals/printOptions/printOptionsUtils";
import { prepareFreeCartItem } from "./utils/cartDropDownUtils";
import { toggleStateWithName } from "./utils/cartModalUtils";

export const CART_MODALS = {
  MODIFY_OFFER: "modifyOffer",
  ADD_CUSTOM_POSITION: "addCustomPosition",
  CLEAR_CART: "clearCart",
  RECONFIGURING_ARTICLE: "reconfiguringArticle",
  FREE_ITEM: "freeItem",
  PRINT_OPTION: "printOption",
  ORDER_MODAL: "orderModal",
  ORDER_FAILED_MODAL: "orderFailedModal",
  UPDATE_PRICES: "updatePrices",
  SHARE_CART: "shareCart",
  CART_ID_CHANGED_MODAL: "cardIdChanged",
  CART_ARTICLES_CHANGED_MODAL: "cardArticlesChanged",
  CART_DB_CHECK_MODAL: "cartDbCheckModal",
  CART_DB_CHECK_MODAL_WARNING: "cartDbCheckModalWarning",
  VALIDATE_CART: "validateCart",
} as const;

export type CartModals = (typeof CART_MODALS)[keyof typeof CART_MODALS];

const InitialModals = {
  [CART_MODALS.MODIFY_OFFER]: false,
  [CART_MODALS.ADD_CUSTOM_POSITION]: false,
  [CART_MODALS.CLEAR_CART]: false,
  [CART_MODALS.RECONFIGURING_ARTICLE]: false,
  [CART_MODALS.FREE_ITEM]: false,
  [CART_MODALS.PRINT_OPTION]: false,
  [CART_MODALS.ORDER_MODAL]: false,
  [CART_MODALS.ORDER_FAILED_MODAL]: false,
  [CART_MODALS.UPDATE_PRICES]: false,
  [CART_MODALS.SHARE_CART]: false,
  [CART_MODALS.CART_ID_CHANGED_MODAL]: false,
  [CART_MODALS.CART_ARTICLES_CHANGED_MODAL]: false,
  [CART_MODALS.CART_DB_CHECK_MODAL]: false,
  [CART_MODALS.CART_DB_CHECK_MODAL_WARNING]: false,
  [CART_MODALS.VALIDATE_CART]: false,
};

export type ModalData = {
  title?: string;
  content?: string | JSX.Element;
  orderNumberOld?: string;
  orderNumberNew?: string;
};

const InitialModalData: ModalData = {
  title: "",
  content: "",
};

export const useCartModals = (
  offerManagementService: OfferManagementService,
) => {
  const navigate = useNavigate();
  const { cartActions } = useCart();
  const { remove } = useConfiguration();
  const [modals, setModals] = useState(InitialModals);
  const [modalData, setModalData] = useState(InitialModalData);
  const [modalLoadingStates, setModalLoadingStates] = useState(modals);

  const { saveQuote, removeAllCartEntries } = cartActions;

  const toggleModal =
    (modalName: CartModals) =>
    (data = InitialModalData) => {
      setModals(toggleStateWithName(modalName));
      setModalData(data);
    };

  const toggleModalLoading = (modalName: CartModals) => () => {
    setModalLoadingStates(toggleStateWithName(modalName));
  };

  const saveFreeItemModal = (freeItem: FreeArticleTO) => async () => {
    toggleModal(CART_MODALS.FREE_ITEM)();
    await cartActions.createFreePosition(
      prepareFreeCartItem(freeItem),
      freeItem.amount,
    );
  };

  const saveQuoteModifyOfferModal = async (
    checkoutInformation: ModifyOfferModalReducedState,
    isNavigate = false,
  ) => {
    toggleModalLoading(CART_MODALS.MODIFY_OFFER)();
    try {
      if (isNavigate) {
        await saveQuote(offerManagementService, checkoutInformation);
        navigate(ROUTES.OFFER_MANAGEMENT, { replace: true });
        return;
      }
      saveQuote(offerManagementService, checkoutInformation).then(() =>
        toggleModalLoading(CART_MODALS.MODIFY_OFFER)(),
      );
    } catch (error) {
      toggleModalLoading(CART_MODALS.MODIFY_OFFER)();
      console.assert(
        window.location.hostname === "localhost",
        `Error while executing cart service call: ${(error as AxiosError).message}`,
      );
    }
    toggleModal(CART_MODALS.MODIFY_OFFER)();
  };

  const closeClearCartModalAndEmptyCart = () => {
    toggleModalLoading(CART_MODALS.CLEAR_CART)();
    removeAllCartEntries().then(toggleModalLoading(CART_MODALS.CLEAR_CART));
    remove();
    toggleModal(CART_MODALS.CLEAR_CART)();
  };

  const savePrintOptionsInHeaderData = async (
    printOptions: PrintOptionsValues,
  ) => {
    await cartActions.saveCheckoutInformation({
      printOptions: createNewPrintOptionsInformation(printOptions),
    });
  };

  const downloadFromPrintOption =
    (printOptions: PrintOptionsValues) => async () => {
      await savePrintOptionsInHeaderData(printOptions);
      window.location.href = `${SERVICE_BASE_URL}/api/docs/`;
      toggleModal(CART_MODALS.PRINT_OPTION)();
      track(printOfferEvent);
    };

  const openSharedCart = async (shareId: string) => {
    await cartActions.openSharedCart(shareId);
  };

  return {
    modals,
    modalActions: {
      toggleModal,
      toggleModalLoading,
      saveFreeItemModal,
      saveQuoteModifyOfferModal,
      closeClearCartModalAndEmptyCart,
      downloadFromPrintOption,
      savePrintOptionsInHeaderData,
      openSharedCart,
      setModalData,
    },
    modalData,
    modalLoadingStates,
  };
};

export type CartModalsReturn = ReturnType<typeof useCartModals>;
export type ToggleModalAction = ReturnType<
  CartModalsReturn["modalActions"]["toggleModal"]
>;
