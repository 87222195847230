import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Box, styled, Typography } from "@mui/material";
import {
  RichTreeView,
  TreeItem2,
  TreeItem2Props,
  treeItemClasses,
  TreeViewBaseItem,
  useTreeItem2Utils,
} from "@mui/x-tree-view";
import { equals, find, forEach, has, isNotEmpty, isNotNil } from "ramda";
import { forwardRef, Ref, SyntheticEvent, useRef, useState } from "react";

import { LvConfiguration } from "../../../hooks/useLvConfiguration";
import { LvTable } from "../../../hooks/useLvTable";
import { ResolvedPosition } from "../../../types/lvTable";
import {
  startNextPositionConfiguration,
  stopCurrentPositionConfiguration,
} from "../../../utilities/lvUtils";
import { t } from "../../shoppingCart/localizationUtils";
import { LvDeleteWarning } from "./LvPositionView";
import { MoreIconMenu } from "./MoreIconMenu";
import { ReactComponent as CubeAlert } from "./cube_alert.svg";
import { ReactComponent as CubeAnalyse } from "./cube_analyse.svg";
import { ReactComponent as CubeChecked } from "./cube_checked.svg";

type TreeViewItem = TreeViewBaseItem<{
  id: string;
  label: string;
  selectedProduct: string | undefined;
  isAnalyzed: boolean;
}>;

function preparePositionsTree(lvPositions: ResolvedPosition[]) {
  const tree: TreeViewItem[] = [];
  forEach(({ id, lvType, lvPosition, product, lvQuantity }) => {
    const treeItem = {
      id,
      label: lvPosition,
      selectedProduct: product?.translatedValue,
      // ckeck one of the properties that are added with analyzePosition
      isAnalyzed: isNotNil(lvQuantity),
    };
    if (lvType === "folder") {
      tree.push({ ...treeItem, children: [] });
      return;
    }
    if (isNotNil(tree.at(-1)?.children)) {
      tree.at(-1)!.children!.push(treeItem);
      return;
    }
    tree.push(treeItem);
  }, lvPositions);
  return tree;
}

type TreePositionIconProps = Readonly<{
  isExpandable: boolean;
  isAnalyzed: boolean;
  selectedProduct: string | undefined;
}>;

function TreePositionIcon({
  isExpandable,
  isAnalyzed,
  selectedProduct,
}: TreePositionIconProps) {
  if (isExpandable) {
    return <FolderOpenIcon />;
  }
  if (isNotNil(selectedProduct)) {
    return <CubeChecked width={24} height={24} style={{ minWidth: "24px" }} />;
  }
  if (isAnalyzed) {
    return <CubeAlert width={24} height={24} style={{ minWidth: "24px" }} />;
  }
  return <CubeAnalyse width={24} height={24} style={{ minWidth: "24px" }} />;
}

type CustomLabelProps = Readonly<{
  children: string;
  className: string;
  isSelected: boolean;
  isExpandable: boolean;
  isLoading: boolean;
  selectedProduct: string | undefined;
  treeViewItem: TreeViewItem;
  lvTable: LvTable;
}>;

function CustomLabel({
  children,
  className,
  isSelected,
  isLoading,
  treeViewItem,
  lvTable,
}: CustomLabelProps) {
  const isExpandable = has("children", treeViewItem);
  const { selectedProduct, isAnalyzed } = treeViewItem;
  const {
    selectedPositionId,
    togglePositionType,
    addLvPosition,
    deleteLvPosition,
  } = lvTable;
  const [showPositionDeleteWarning, setShowPositionDeleteWarning] =
    useState(false);

  const menuItems = [
    {
      key: "change-position",
      children: isExpandable
        ? t("lv_position_action_change_to_position")
        : t("lv_position_action_change_to_folder"),
      onClick: () => togglePositionType(selectedPositionId),
    },
    {
      key: "add-position",
      children: t("lv_position_action_add_position"),
      onClick: () => addLvPosition(selectedPositionId),
    },
    {
      key: "merge-positions",
      children: t("lv_position_action_merge_position"),
      disabled: true,
    },
    {
      key: "delete-position",
      children: t("lv_position_action_delete_position"),
      onClick: () => setShowPositionDeleteWarning(true),
    },
  ];

  return (
    <Box
      className={className}
      sx={{ display: "flex", gap: 1, alignItems: "center", width: "100%" }}
    >
      <TreePositionIcon
        isExpandable={isExpandable}
        isAnalyzed={isAnalyzed}
        selectedProduct={selectedProduct}
      />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            textWrap: "nowrap",
          }}
        >
          <Typography sx={{ fontWeight: isSelected ? "bold" : "normal" }}>
            {t(children)}
          </Typography>
          {selectedProduct && (
            <Typography color="textSecondary">{selectedProduct}</Typography>
          )}
        </Box>
        {isSelected && (
          <MoreIconMenu
            sx={{ pointerEvents: isLoading ? "none" : "auto" }}
            menuItems={menuItems}
          />
        )}
        {showPositionDeleteWarning && (
          <Box
            sx={{
              position: "absolute",
              right: "4rem",
              top: "2rem",
            }}
          >
            <LvDeleteWarning
              label={t("lv_warning_position_delete_configuration")}
              onDelete={() => deleteLvPosition(selectedPositionId)}
              onCancel={() => setShowPositionDeleteWarning(false)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

type CustomTreeItemProps = TreeItem2Props & {
  lvTable: LvTable;
  isLoading: boolean;
};

const CustomTreeItem = forwardRef(function CustomTreeItem(
  props: CustomTreeItemProps,
  ref: Ref<HTMLLIElement>,
) {
  const { publicAPI, status } = useTreeItem2Utils({
    itemId: props.itemId,
    children: props.children,
  });

  const treeViewItem: TreeViewItem = publicAPI.getItem(props.itemId);

  return (
    <TreeItem2
      {...props}
      ref={ref}
      slots={{
        label: CustomLabel,
      }}
      slotProps={{
        label: {
          isSelected: status.selected,
          treeViewItem,
          lvTable: props.lvTable,
          isLoading: props.isLoading,
        } as CustomLabelProps,
      }}
    />
  );
});

const StyledRichTreeView = styled(RichTreeView)({
  flex: 1,
  overflowY: "auto",
  overflowX: "hidden",
  [`& .${treeItemClasses.groupTransition}`]: {
    paddingLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: 20, // default padding (8px) + default indentation (12px)
    },
  },
  [`& .${treeItemClasses.content}`]: {
    height: 42,
    borderRadius: 0,
    borderBottom: "1px solid #b5cacd",
  },
});

type PositionsTreeViewProps = Readonly<{
  lvTable: LvTable;
  lvConfiguration: LvConfiguration;
}>;

export function PositionsTreeView({
  lvTable,
  lvConfiguration,
}: PositionsTreeViewProps) {
  const {
    lvPositions,
    selectedPositionId,
    setSelectedPositionId,
    updateLvPosition,
  } = lvTable;

  const { isLoading, stopConfAndGetValues, startConfAndSetValues } =
    lvConfiguration;

  async function onSelectedItemsChange(
    _: SyntheticEvent<Element, Event>,
    itemId: string | null,
  ) {
    if (equals(selectedPositionId, itemId)) {
      return;
    }
    await stopCurrentPositionConfiguration(
      selectedPositionId,
      updateLvPosition,
      stopConfAndGetValues,
    );
    setSelectedPositionId(itemId);
    startNextPositionConfiguration(itemId, lvPositions, startConfAndSetValues);
  }

  const positionTree = preparePositionsTree(lvPositions);

  const firstFilledFolderRef = useRef(
    find((item) => isNotEmpty(item.children), positionTree),
  );

  return (
    <StyledRichTreeView
      items={positionTree}
      selectedItems={selectedPositionId}
      // @ts-expect-error type of itemId is also string[], because of styled
      onSelectedItemsChange={onSelectedItemsChange}
      // @ts-expect-error Property 'menuItems' is missing in type 'TreeItemProps'
      slots={{ item: CustomTreeItem }}
      slotProps={{
        item: {
          lvTable,
          isLoading,
        } as CustomTreeItemProps,
      }}
      expansionTrigger="iconContainer"
      disableSelection={lvConfiguration.isLoading}
      {...(isNotNil(firstFilledFolderRef.current) && {
        defaultExpandedItems: [firstFilledFolderRef.current.id],
      })}
    />
  );
}
