import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import { AppProvider } from "./context/AppProvider";

const theme = createTheme({
  typography: {
    fontFamily: `"DIN-Medium", Arial, sans-serif`,
  },
});

function Entry() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <App />
        </AppProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

const root = createRoot(document.getElementById("content") as HTMLElement);

root.render(<Entry />);
