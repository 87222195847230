import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { propEq } from "ramda";
import { useState } from "react";

import { Description, Viewport } from "@encoway/react-components";

import { ParameterTO } from "../../../types/@encoway/Parameter";
import { ConfiguratorComponentProps } from "../../../types/configuration";
import { DescriptionLink } from "./parameter/DescriptionLink";
import { ParameterError } from "./parameter/ParameterError";
import { ParameterQuantity } from "./parameter/ParameterQuantity";

import "./parameter.scss";

const DEFAULT_STYLE = {
  descriptionIcon: "fa-info-circle",
  hasUndo: false,
  showSpecState: true,
  undoIcon: "fa-times",
  defaultIcon: "fa-television",
  userIcon: "fa-user",
  systemIcon: "fa-cog",
  mandatoryIcon: "fa-exclamation-triangle",
  externalIcon: "fa-external-link",
  compact: false,
};

type ParameterProps = Readonly<ConfiguratorComponentProps<ParameterTO>>;

export function Parameter(props: ParameterProps) {
  const {
    config,
    data,
    error,
    options,
    style,
    mediaLink,
    onFocus,
    onValueChanged,
  } = props;
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const parameterStyle = { ...DEFAULT_STYLE, ...style };
  const parameterViewport = Viewport.parse(
    data.name,
    Viewport.orElse(data.viewPort, "radioButton"),
    data.multivalued,
  );

  function getStateViewportName(): string {
    return Viewport.parse(`${data.name}State`, "parameterState").name;
  }

  if (propEq("hidden", "name", parameterViewport)) {
    return <div id={data.id} data-parameter={data.name} className="content" />;
  }

  const containerStyles = classnames({
    columns: true,
    "is-ready": data.terminal,
    "is-mandatory": data.mandatory && !data.terminal,
  });
  const parameterStyles = classnames({
    parameter: true,
    "is-mandatory": data.mandatory && !data.terminal,
  });

  return (
    <div id={data.id} className={containerStyles} data-parameter={data.name}>
      <div className={`column ${parameterStyle.compactLeftColumn}`}>
        <div
          className={parameterStyles}
          data-icon-id={
            parameterStyles.includes("is-mandatory")
              ? `warning-id-${data.id}`
              : null
          }
        >
          <div className="translated-name">
            {data.translatedName}
            <DescriptionLink
              handleDescriptionClick={() => setShowInfo(true)}
              id={data.id}
              imageUrl={data.imageUrl}
              longText={data.longText}
              parameterStyle={parameterStyle}
            />
          </div>
          <div className="description">
            {Viewport.instance(getStateViewportName(), {
              ...props,
              setLoading,
            })}
          </div>
          {data.mandatory && !data.terminal && (
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              style={{ color: "#ef7b22" }}
              data-icon-id={`warning-icon-${data.id}`}
            />
          )}
        </div>
        {data.shortText && (
          <span className="help shortText">{data.shortText}</span>
        )}
        {showInfo && (
          <Description
            data={data}
            mediaLink={mediaLink}
            onClose={() => setShowInfo(false)}
          />
        )}
      </div>
      <div className={`column ${parameterStyle.compactRightColumn}`}>
        <ParameterError error={error} id={data.id} isSmall />
        {Viewport.instance(parameterViewport.name, {
          config,
          data,
          loading,
          options,
          style: { ...parameterStyle, ...parameterViewport.style },
          mediaLink,
          onValueChanged,
          onFocus,
          setLoading,
        })}
        <ParameterQuantity data={data} onValueChanged={onValueChanged} />
      </div>
    </div>
  );
}
