import { useNavigate } from "react-router-dom";

import { L10n } from "@encoway/l10n";

import { ProgressTable } from "../../../../../components/ProgressTable";
import { Loading } from "../../../../../components/loading";
import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../../components/modal/modal";
import { ROUTES } from "../../../../../http/constants";
import { mapIndexed } from "../../../../../utilities/utilities";
import { useValidateCartModal } from "./useValidateCartModal";
import { determineItemValidationLabel } from "./validateCartUtils";

import "./validateCartModal.scss";

export const ValidateCartModal = () => {
  const navigate = useNavigate();
  const [status, cartItems, onSave] = useValidateCartModal();
  return (
    <Modal className="validate-cart-warning">
      <ModalHeader
        label={L10n.format("cart_validation_header")}
        onCancel={() => navigate(ROUTES.OFFER_MANAGEMENT, { replace: true })}
      />
      <ModalBodyWrapper
        label={L10n.format("cart_validation_start_text")}
        className="is-flex is-flex-direction-column gap-3"
      >
        {status.loading ? (
          <div className="loading">
            <Loading />
          </div>
        ) : (
          <ProgressTable
            tableHeader={{
              position: L10n.format("update_price_table_pos"),
              article: L10n.format("update_price_table_article"),
            }}
            tableCells={mapIndexed(
              (cartItem, index) => ({
                isLoading: cartItem.validation.running,
                isSuccess:
                  cartItem.validation.finished && cartItem.validation.valid,
                position: index + 1,
                article: determineItemValidationLabel(cartItem),
              }),
              cartItems,
            )}
          />
        )}
      </ModalBodyWrapper>
      <ModalFooter
        disabled={!status.finished}
        saveLabel={L10n.format("ok")}
        onSave={onSave}
      />
    </Modal>
  );
};
