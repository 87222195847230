import { isNil, pathOr } from "ramda";
import { useEffect, useRef, useState } from "react";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
  SaveButton,
} from "../../../components/modal/modal";
import { customerExists } from "../../../service/customerManagementService";
import {
  ExtendedTeckentrupCartTO,
  TeckentrupCartTO,
} from "../../../types/cart";
import {
  saveProcessAndGoToQuoteManagementEvent,
  saveProcessEvent,
  track,
} from "../../../utilities/eventTracking";
import { t } from "../../shoppingCart/localizationUtils";
import { CustomerSearchTable } from "./customerSearchTable/customerSearchTable";
import { CancelModal } from "./modifyOffer/cancelModal";
import { ModifyOffer } from "./modifyOffer/modifyOffer";
import {
  isNotEmptyAndWithoutWhitespaces,
  isSelectedCustomerChangedOrSearchValueEmpty,
} from "./modifyOfferDialogUtils";
import {
  AddCustomer,
  CustomerSearchSave,
  EditCustomer,
  GetCustomers,
  ModifyOfferModalReducedState,
  PreSelectCustomer,
  SearchValueChange,
  SetCustomerNumberExists,
  ToggleAddCustomerLoading,
  ToggleCancelDialog,
  ToggleCustomerAddedToolTip,
  ToggleCustomerNumberNotSetWarning,
  prepareModifyOfferModal,
} from "./modifyOfferModalActions";

import "./modifyOfferModal.scss";

type ModifyOfferModalProps = {
  closeDialogMethod: () => void;
  saveQuote: (
    checkoutInformation: ModifyOfferModalReducedState,
    isNavigate?: boolean,
  ) => void;
  quote?: TeckentrupCartTO | ExtendedTeckentrupCartTO;
  companyId?: number | null;
  isSaveAndNavigateButton?: boolean;
};

export const ModifyOfferModal = ({
  closeDialogMethod,
  saveQuote,
  quote,
  companyId,
  isSaveAndNavigateButton = false,
}: Readonly<ModifyOfferModalProps>) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const [toggleCustomerSearch, setToggleCustomerSearch] = useState(false);
  const [saved, setSaved] = useState(false);
  const [state, setState] = useState(prepareModifyOfferModal(quote));
  const didMount = useRef<boolean>(false);

  useEffect(() => {
    (async () => {
      setState(await GetCustomers(companyId));
      setState(SetCustomerNumberExists);
      setState(PreSelectCustomer);
    })();
  }, []);

  const onCancel = () =>
    state.edited ? setState(ToggleCancelDialog) : closeDialogMethod();

  const onCustomerSearchToggle = () => {
    const { customerInformation, customerSearch } = state;
    setState(
      SearchValueChange(
        pathOr("", ["searchValue"], customerSearch.searchValue),
      ),
    );
    isSelectedCustomerChangedOrSearchValueEmpty(
      customerInformation,
      customerSearch,
    ) && setState(PreSelectCustomer);
    setToggleCustomerSearch(true);
  };

  const onCustomerSearchSave = () => {
    setState(CustomerSearchSave);
    setToggleCustomerSearch(false);
  };

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    if (!toggleCustomerSearch && !isNil(bottomRef.current)) {
      // Scrolls to the end of the modal to see all input fields after saving
      // the address without scrolling by hand
      bottomRef.current.scrollIntoView();
    }
  }, [toggleCustomerSearch]);

  const onAddCustomer = async () => {
    const {
      customerSearch: { selectedCustomer, customers },
      customerInformation,
    } = state;
    const customerNumberIsValid = isNotEmptyAndWithoutWhitespaces(
      customerInformation.customerNumber,
    );
    const customerNameIsValid = isNotEmptyAndWithoutWhitespaces(
      customerInformation.customerName,
    );

    if (customerNumberIsValid && customerNameIsValid) {
      setState(ToggleAddCustomerLoading(false));
      const { customerNumber } = customerInformation;
      const { data } = await customerExists({ customerNumber });
      data
        ? setState(
            await EditCustomer(
              customerInformation,
              selectedCustomer,
              customers,
            ),
          )
        : setState(await AddCustomer(customerInformation, companyId));
      setState(ToggleAddCustomerLoading(true));
      setTimeout(() => setState(ToggleCustomerAddedToolTip(false)), 3000);
    } else {
      setState(
        ToggleCustomerNumberNotSetWarning(
          !customerNumberIsValid,
          !customerNameIsValid,
        ),
      );
    }
  };

  const saveQuoteAndTrack = (
    state: ModifyOfferModalReducedState,
    navigate?: boolean,
  ) => {
    track(navigate ? saveProcessAndGoToQuoteManagementEvent : saveProcessEvent);
    saveQuote(state, navigate);
  };

  if (toggleCustomerSearch) {
    return (
      <>
        <Modal dialogId={"select_customer"}>
          <ModalHeader
            label={t("select_customer")}
            onCancel={() => setToggleCustomerSearch(false)}
          />
          <ModalBodyWrapper overflowY={true} className="modify-offer-modal">
            <CustomerSearchTable
              state={state.customerSearch}
              setState={setState}
            />
          </ModalBodyWrapper>
          <ModalFooter
            cancelLabel={t("cancel")}
            saveLabel={t("customer_search_accept")}
            saveButtonId={"customer_search_accept"}
            onCancel={() => setToggleCustomerSearch(false)}
            onSave={onCustomerSearchSave}
            disabled={!state.customerSearch.selectedAddress}
          ></ModalFooter>
        </Modal>
        {state.toggleCancelDialog && (
          <CancelModal
            onClose={() => setState(ToggleCancelDialog)}
            onCancel={closeDialogMethod}
            onSave={() => setState(ToggleCancelDialog)}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Modal dialogId={"modify_dialog_title"}>
        <ModalHeader label={t("modify_dialog_title")} onCancel={onCancel} />
        <ModalBodyWrapper overflowY={true} className="modify-offer-modal">
          <>
            <ModifyOffer
              quote={quote}
              onAddCustomer={onAddCustomer}
              onCustomerSearchToggle={onCustomerSearchToggle}
              state={state}
              setState={setState}
            />
            <div className="bottom-anchor" ref={bottomRef} />
          </>
        </ModalBodyWrapper>
        <ModalFooter
          cancelLabel={t("cancel")}
          saveLabel={t("save")}
          saveButtonId={"save"}
          onCancel={onCancel}
          onSave={() => {
            saveQuoteAndTrack(state, false);
            setSaved(true);
          }}
          disabled={!state.isComplete || saved}
        >
          {isSaveAndNavigateButton && (
            <SaveButton
              saveLabel={t("modify_dialog_save_to_quote_management")}
              saveButtonId="modify_dialog_save_to_quote_management"
              onSave={() => saveQuoteAndTrack(state, true)}
              disabled={!state.isComplete}
            />
          )}
        </ModalFooter>
      </Modal>
      {state.toggleCancelDialog && (
        <CancelModal
          onClose={() => setState(ToggleCancelDialog)}
          onCancel={closeDialogMethod}
          onSave={() => setState(ToggleCancelDialog)}
        />
      )}
    </>
  );
};
