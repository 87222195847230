import { Box, Stack, styled } from "@mui/material";
import classNames from "classnames";
import { isEmpty, isNil, isNotNil, propEq } from "ramda";
import { ChangeEvent, useReducer, useState } from "react";

import { L10n } from "@encoway/l10n";

import { NumberInput } from "../../../components/input/NumberInput";
import { TextInput } from "../../../components/input/TextInput";
import { LvConfiguration } from "../../../hooks/useLvConfiguration";
import { LvTable } from "../../../hooks/useLvTable";
import { ResolvedPosition } from "../../../types/lvTable";
import { concatReferenceString } from "../../../utilities/lvUtils";
import { Input } from "../../shoppingCart/components/modals/orderModal/input";
import { DeleteCartItemWarningPopup } from "../../shoppingCart/components/table/cells/dropdownCell/deleteCartItemWarningPopup";
import { t } from "../../shoppingCart/localizationUtils";
import { LvProductField } from "../lvTable/components/LvProductField";
import { MoreIconMenu } from "./MoreIconMenu";

const PANEL_SIZE_SIDE_BY_SIDE = 80;

export const SmallBlackLabel = styled("label")({
  display: "block",
  color: "#000000",
  marginBottom: "0.5em",
  fontWeight: "bold",
});

const EditableTextArea = styled("textarea")({
  height: "40dvh",
  resize: "none",
  fontSize: "small",
  lineHeight: "2.5em",
});

const EmbeddedHtml = styled("div")({
  height: "40dvh",
  overflow: "auto",
  border: "1px solid rgb(219, 219, 219)",
  fontSize: "small",
  padding: "calc(0.75em - 1px)",
  figure: {
    marginBottom: "0 !important",
    "mark.entity": {
      background: "#adc6c9 !important",
    },
  },
});

const TextAreaToggleLabel = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const LabelButton = styled("button")({
  background: "none",
  border: "none",
  margin: "0",
  fontSize: "small",
  color: "#000000",
  padding: 0,
  marginBottom: "0.5em",
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
  },
});

export const LvDeleteWarning = styled(DeleteCartItemWarningPopup)({
  position: "relative",
  right: "12rem",
  ".sec-warn.is-visible": {
    width: "15rem",
    textWrap: "wrap",
    fontSize: "0.9rem !important",
    "::before": {
      left: "auto !important",
      right: "10px !important",
      borderColor: "transparent #000 #000 transparent !important",
    },
  },
});

type LvPositionHeaderProps = Readonly<{
  selectedPosition: ResolvedPosition;
  lvTable: LvTable;
  lvConfiguration: LvConfiguration;
}>;

function LvPositionHeader({
  selectedPosition,
  lvTable,
  lvConfiguration,
}: LvPositionHeaderProps) {
  const { updateLvPosition, togglePositionType, deleteLvPosition } = lvTable;
  const { isLoading, initializeLvParameters } = lvConfiguration;
  const [showAnalyzeWarning, setShowAnalyzeWarning] = useState(false);
  const [showPositionDeleteWarning, setShowPositionDeleteWarning] =
    useState(false);

  async function analyzePosition() {
    setShowAnalyzeWarning(false);
    const initializedPosition = await initializeLvParameters(selectedPosition);
    updateLvPosition(selectedPosition.id, initializedPosition);
  }

  async function onClickAnalyze() {
    if (isLoading || isEmpty(selectedPosition.lvText)) {
      return;
    }
    // ckeck one of the properties that are added with analyzePosition
    if (isNotNil(selectedPosition.lvQuantity)) {
      setShowAnalyzeWarning(true);
      return;
    }
    analyzePosition();
  }

  const menuItems = [
    {
      key: "change-position",
      children: propEq("folder", "lvType", selectedPosition)
        ? t("lv_position_action_change_to_position")
        : t("lv_position_action_change_to_folder"),
      onClick: () => togglePositionType(selectedPosition.id),
    },
    {
      key: "merge-positions",
      children: t("lv_position_action_merge_position"),
      disabled: true,
    },
    {
      key: "delete-position",
      children: t("lv_position_action_delete_position"),
      onClick: () => setShowPositionDeleteWarning(true),
    },
  ];

  return (
    <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <label style={{ fontWeight: "bold", textWrap: "nowrap" }}>
          {t("lv_position_label")}
        </label>
        <TextInput
          className="input"
          style={{ minWidth: "80px" }}
          value={selectedPosition.lvPosition}
          setValue={(lvPosition) =>
            updateLvPosition(selectedPosition.id, { lvPosition })
          }
          data-input-field-id="lv-position"
        />
      </Box>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <button
          className={classNames("button is-info is-blue-dark", {
            "is-loading": isLoading,
          })}
          onClick={onClickAnalyze}
          disabled={isEmpty(selectedPosition.lvText)}
        >
          {t("lv_position_analyze")}
        </button>
        <Box sx={{ position: "relative", right: "7rem", top: "1rem" }}>
          {showAnalyzeWarning && (
            <LvDeleteWarning
              label={t("lv_warning_analyze_reset_configuration")}
              onDelete={() => analyzePosition()}
              onCancel={() => setShowAnalyzeWarning((prev) => !prev)}
            />
          )}
        </Box>
        <Box
          sx={{
            border: "1px solid rgb(219, 219, 219)",
            alignContent: "center",
            height: "100%",
          }}
        >
          <MoreIconMenu
            sx={{ pointerEvents: isLoading ? "none" : "auto" }}
            menuItems={menuItems}
          />
          {showPositionDeleteWarning && (
            <LvDeleteWarning
              label={t("lv_warning_position_delete_configuration")}
              onDelete={() => deleteLvPosition(selectedPosition.id)}
              onCancel={() => setShowPositionDeleteWarning(false)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

type LvPositionTextAreaProps = Readonly<{
  sideBySide: boolean;
  lvText: string;
  lvTextAnalyzed?: string;
  onInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}>;

function LvPositionTextArea({
  sideBySide,
  lvText,
  lvTextAnalyzed,
  onInputChange,
}: LvPositionTextAreaProps) {
  const [showAnalyzedText, toggleShowAnalyzedText] = useReducer(
    (prev) => !prev,
    true,
  );

  if (isNil(lvTextAnalyzed)) {
    return (
      <Box>
        <SmallBlackLabel>
          {t("lv_import_textarea_original_label")}
        </SmallBlackLabel>
        <EditableTextArea
          name="comment"
          className="textarea"
          value={lvText}
          onChange={onInputChange}
        />
      </Box>
    );
  }

  if (sideBySide) {
    return (
      <Stack direction="row" spacing={2}>
        <Box sx={{ flex: 1 }}>
          <SmallBlackLabel>
            {t("lv_import_textarea_original_label")}
          </SmallBlackLabel>
          <EditableTextArea
            name="comment"
            className="textarea"
            value={lvText}
            onChange={onInputChange}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <SmallBlackLabel>
            {t("lv_import_textarea_analyzed_label")}
          </SmallBlackLabel>
          <EmbeddedHtml
            dangerouslySetInnerHTML={{
              __html: lvTextAnalyzed,
            }}
          />
        </Box>
      </Stack>
    );
  }

  if (showAnalyzedText) {
    return (
      <Box>
        <TextAreaToggleLabel>
          <SmallBlackLabel>
            {t("lv_import_textarea_analyzed_label")}
          </SmallBlackLabel>
          <LabelButton onClick={toggleShowAnalyzedText}>
            {t("lv_import_show_original_text")}
          </LabelButton>
        </TextAreaToggleLabel>
        <EmbeddedHtml
          dangerouslySetInnerHTML={{
            __html: lvTextAnalyzed,
          }}
        />
      </Box>
    );
  }

  return (
    <Box>
      <TextAreaToggleLabel>
        <SmallBlackLabel>
          {t("lv_import_textarea_original_label")}
        </SmallBlackLabel>
        <LabelButton onClick={toggleShowAnalyzedText}>
          {t("lv_import_show_analyzed_text")}
        </LabelButton>
      </TextAreaToggleLabel>
      <EditableTextArea
        name="comment"
        className="textarea"
        value={lvText}
        onChange={onInputChange}
      />
    </Box>
  );
}

type LvPositionAnalyzedTextProps = Readonly<{
  selectedPosition: ResolvedPosition;
  lvTable: LvTable;
  sideBySide: boolean;
}>;

function LvPositionAnalyzedText({
  selectedPosition,
  lvTable,
  sideBySide,
}: LvPositionAnalyzedTextProps) {
  const {
    id,
    lvReference,
    lvResolvedReference,
    lvQuantity,
    lvText,
    lvAnalyzedText,
  } = selectedPosition;
  const { updateLvPosition } = lvTable;
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Box sx={{ flex: 1 }}>
          <SmallBlackLabel>
            {L10n.format("lv_import_position_reference")}
          </SmallBlackLabel>
          <Input
            value={concatReferenceString(lvReference, lvResolvedReference, "-")}
            disabled
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <SmallBlackLabel>
            {L10n.format("lv_import_position_amount")}
          </SmallBlackLabel>
          {isNil(lvQuantity) ? (
            <Input value={"-"} disabled />
          ) : (
            <NumberInput
              className="input"
              value={lvQuantity}
              setValue={(lvQuantity) => updateLvPosition(id, { lvQuantity })}
              data-input-field-id="lv-amount"
            />
          )}
        </Box>
      </Stack>
      <LvPositionTextArea
        sideBySide={sideBySide}
        lvText={lvText}
        lvTextAnalyzed={lvAnalyzedText}
        onInputChange={({ target }) =>
          updateLvPosition(id, { lvText: target.value })
        }
      />
    </>
  );
}

type LvPositionViewProps = Readonly<{
  panelSize: number;
  lvTable: LvTable;
  selectedPosition: ResolvedPosition;
  lvConfiguration: LvConfiguration;
}>;

export function LvPositionView({
  panelSize,
  lvTable,
  selectedPosition,
  lvConfiguration,
}: LvPositionViewProps) {
  return (
    <Stack spacing={5} sx={{ padding: 2, paddingBottom: 5 }}>
      <Stack spacing={3}>
        <LvPositionHeader
          selectedPosition={selectedPosition}
          lvTable={lvTable}
          lvConfiguration={lvConfiguration}
        />
        <LvPositionAnalyzedText
          selectedPosition={selectedPosition}
          lvTable={lvTable}
          sideBySide={panelSize >= PANEL_SIZE_SIDE_BY_SIDE}
        />
      </Stack>
      <LvProductField
        selectedPosition={selectedPosition}
        lvTable={lvTable}
        lvConfiguration={lvConfiguration}
      />
    </Stack>
  );
}
