import { styled, TableCell, TableHead, TableRow } from "@mui/material";

import { L10n } from "@encoway/l10n";

const ConfiguratorTableHeader = styled(TableCell)`
  font-size: 0.8rem;
  padding: 0 16px;
  font-weight: 600;
`;

export function LvTableHead() {
  return (
    <TableHead>
      <TableRow>
        <ConfiguratorTableHeader
          width="36px"
          sx={{ minWidth: "36px", maxWidth: "36px" }}
        />
        <ConfiguratorTableHeader
          width="240px"
          sx={{ minWidth: "240px", maxWidth: "240px" }}
        >
          {L10n.format("offer_management_lv_characteristic")}
        </ConfiguratorTableHeader>
        <ConfiguratorTableHeader
          width="355px"
          sx={{ minWidth: "355px", maxWidth: "355px" }}
        >
          {L10n.format("offer_management_lv_chosen_value")}
        </ConfiguratorTableHeader>
        <ConfiguratorTableHeader
          width="240px"
          sx={{ minWidth: "240px", maxWidth: "240px" }}
        >
          {L10n.format("offer_management_lv_recognized_value")}
        </ConfiguratorTableHeader>
        <ConfiguratorTableHeader />
      </TableRow>
    </TableHead>
  );
}
