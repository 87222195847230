import classNames from "classnames";
import { is, path, toPairs, values } from "ramda";
import { HTMLAttributes } from "react";

import { L10n } from "@encoway/l10n";

import { mapIndexed } from "../utilities/utilities";
import { ProgressButton } from "./progressButton";

type HeaderValue = string | number | { label: string; isColRight: boolean };

function isHeaderObject(
  value: HeaderValue,
): value is { label: string; isColRight: boolean } {
  return is(Object, value);
}

type TableHeadProps = Readonly<{
  headerValue: HeaderValue;
}>;

function TableHead({ headerValue }: TableHeadProps) {
  if (isHeaderObject(headerValue)) {
    const { label, isColRight } = headerValue;
    return (
      <th className={classNames({ "has-text-right": isColRight })}>{label}</th>
    );
  }
  return <th>{headerValue}</th>;
}

type CellValue =
  | string
  | number
  | { className: string; value: string | number };

function isCellObject(
  value: CellValue,
): value is { className: string; value: string | number } {
  return is(Object, value);
}

type TableCellProps = Readonly<{
  cellValue: CellValue;
  isColRight: boolean | undefined;
}>;

function TableCell({ cellValue, isColRight }: TableCellProps) {
  if (isCellObject(cellValue)) {
    const { className, value } = cellValue;
    return (
      <td
        className={classNames({ "has-text-right": isColRight }, className)}
        style={{ verticalAlign: "middle" }}
      >
        {value}
      </td>
    );
  }
  return (
    <td
      className={classNames({ "has-text-right": isColRight })}
      style={{ verticalAlign: "middle" }}
    >
      {cellValue}
    </td>
  );
}

type ProgressTableProps<T> = HTMLAttributes<HTMLTableElement> &
  Readonly<{
    tableHeader: T;
    tableCells: Array<
      {
        isLoading: boolean | undefined;
        isSuccess: boolean | undefined;
      } & {
        [K in keyof T]: CellValue;
      }
    >;
  }>;

export function ProgressTable<T extends Record<string, HeaderValue>>({
  tableHeader,
  tableCells,
  className,
  ...props
}: ProgressTableProps<T>) {
  return (
    <table className={classNames("table is-fullwidth", className)} {...props}>
      <thead>
        <tr>
          <th>{L10n.format("status")}</th>
          {mapIndexed(
            (headerValue, headerIndex) => (
              <TableHead key={headerIndex} headerValue={headerValue} />
            ),
            values(tableHeader),
          )}
        </tr>
      </thead>
      <tbody>
        {mapIndexed(
          ({ isLoading, isSuccess, ...rest }, rowIndex) => (
            <tr key={rowIndex}>
              <td style={{ verticalAlign: "middle" }}>
                <ProgressButton isLoading={isLoading} isSuccess={isSuccess} />
              </td>
              {mapIndexed(([cellKey, cellValue], cellIndex) => {
                const isColRight = path<boolean>(
                  ["isColRight"],
                  tableHeader[cellKey],
                );
                return (
                  <TableCell
                    key={cellIndex}
                    cellValue={cellValue}
                    isColRight={isColRight}
                  />
                );
              }, toPairs(rest))}
            </tr>
          ),
          tableCells,
        )}
      </tbody>
    </table>
  );
}
