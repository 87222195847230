import { AxiosPromise } from "axios";
import { produce } from "immer";
import {
  __,
  equals,
  includes,
  isEmpty,
  map,
  propEq,
  reject,
  whereAny,
} from "ramda";

import { LvTable } from "../hooks/useLvTable";
import { LvAddressData } from "../pages/offerManagement/lvModal/LvOfferTab";
import { FirmadesNutzers, Nutzer } from "../types/identity";
import {
  AnalyzedPosition,
  CompleteResolvedPosition,
  LvBase,
  LvConstructionAndAddressData,
  ResolvedPositionTO,
} from "../types/lvTable";
import { concatReferenceString } from "../utilities/lvUtils";
import { fetchPost } from "./fetch";

const IGNORED_CHARACTERISTICS = ["T_M_ZARGE", "T_M_MAULW"];

export const parsePdf = (formData: FormData) =>
  fetchPost<ResolvedPositionTO[]>(
    "teckentrup-server/api/cart/quote/custom/import/parsePdf",
  )()(formData);

export const analyzePosition = (formData: FormData) =>
  fetchPost<AnalyzedPosition>(
    "teckentrup-server/api/cart/quote/custom/import/analyzePosition",
  )()(formData);

export const analyzeAddresses = (formData: FormData) => {
  return fetchPost<LvConstructionAndAddressData>(
    "teckentrup-server/api/cart/quote/custom/import/analyzeAddresses",
  )()(formData);
};

const buildDealer = (company: FirmadesNutzers, user: Nutzer) => ({
  customerID: company.FirmenSAPKundennummer,
  userID: user.Email,
});

const buildAddress = ({
  email,
  street,
  houseNumber,
  city,
  ...rest
}: LvAddressData) => ({
  email,
  street,
  houseNumber,
  city,
  contactSalutation: rest.title,
  contactFirstName: rest.firstname,
  contactLastName: rest.surname,
  telephoneNumber: rest.phone,
  postCode: rest.zip,
});

const rejectInvalidParameters = reject<LvBase>(
  whereAny({
    name: includes(__, IGNORED_CHARACTERISTICS),
    value: (value: string) => isEmpty(value) || equals(value, "0"),
  }),
);

function addDinRIfMissing(parameters: LvBase[]) {
  const foundDinR = parameters.find(propEq("T_M_DINR", "name"));
  if (foundDinR) {
    return parameters;
  }
  return produce(parameters, (draft) => {
    draft.push({ name: "T_M_DINR", value: "U", recognized: "" });
  });
}

const buildCharacteristics = (parameters: LvBase[]) => {
  const filteredParameters = rejectInvalidParameters(parameters);
  const parametersWithDinR = addDinRIfMissing(filteredParameters);
  return map(
    ({ name, value }) => ({
      characteristicId: name,
      value,
      format: "UNFORMATTED",
    }),
    parametersWithDinR,
  );
};

const buildConfigurableProducts = (
  selectedPositions: CompleteResolvedPosition[],
) =>
  map(
    ({
      product,
      lvQuantity,
      lvPosition,
      lvReference,
      lvResolvedReference,
      lvConfigurationParameters,
    }) => ({
      productTO: {
        articleId: product.value,
        amount: lvQuantity,
        lvPosition: lvPosition,
        referenceNo: concatReferenceString(lvReference, lvResolvedReference),
      },
      characteristics: buildCharacteristics(lvConfigurationParameters),
    }),
    selectedPositions,
  );

type ProductsInProcess = {
  cartOrderNumber: string;
};

export const createProductsInProcess = (
  lvTable: LvTable,
  buildProject: string,
  referenceNumber: string,
  comment: string,
  addressData: LvAddressData,
  company: FirmadesNutzers,
  user: Nutzer,
  signal: AbortSignal,
): AxiosPromise<ProductsInProcess> => {
  const body = {
    headerDataTO: {
      buildProject,
      referenceNumber,
      comment,
      dealer: buildDealer(company, user),
      address: buildAddress(addressData),
      thirdPartySystemName: "extern_import_lv",
      sourceDocumentName: lvTable.lvFileName,
    },
    discreteProducts: [],
    configurableProducts: buildConfigurableProducts(
      lvTable.selectedLvPositions,
    ),
  };
  return fetchPost<ProductsInProcess>(
    "teckentrup-server/api/request/_importrequest?validationResponse=true",
  )({ signal })(body);
};
