import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { any, filter, forEach, isNil, propEq, propOr, values } from "ramda";
import React from "react";

import { L10n } from "@encoway/l10n";

import { Dropdown } from "../../components/dropdown";
import { SmartInput } from "../../components/input/smartInput";
import { Loading } from "../../components/loading";
import {
  checkFilterValidityAndReset,
  getCharacteristicValue,
  isValuePossible,
} from "./productSelectionUtils";

import "./productSelectionFilters.scss";

const KEYCODE_TAB = 9;
const KEYCODE_ENTER = 13;

export default class ProductSelectionFilters extends React.Component {
  renderFilters() {
    let characteristicFilters = [];
    if (!isNil(this.props.characteristicViews.property_type_group_selection)) {
      characteristicFilters =
        this.props.characteristicViews.property_type_group_selection.map(
          (entry) => {
            const characteristic = this.props.characteristics.find(
              (char) => char.id === entry,
            );
            checkFilterValidityAndReset(
              this.props.currentFilters,
              this.props.characteristics,
              this.props.onReset,
            );

            const possibleValues = [
              {
                id: "__undo__",
                value: L10n.format("all"),
                css:
                  this.props.currentFilters.findIndex(
                    (filter) => filter.characteristicId === entry,
                  ) === -1
                    ? "is-selected"
                    : "is-selectable",
              },
            ];

            const notPossibleValues = [];

            const filteredPossibleValues = filter((value) => {
              const currentValidValues = propOr(
                {},
                entry,
                this.props.validValues,
              );
              return any(propEq(value.id, "value"), currentValidValues);
            }, values(characteristic.possibleValues));
            forEach((value) => {
              const index = this.props.currentFilters.findIndex(
                (filter) => filter.characteristicId === entry,
              );

              if (
                index !== -1 &&
                this.props.currentFilters[index].value === value.id
              ) {
                possibleValues.push({
                  id: value.id,
                  value: getCharacteristicValue(characteristic, value),
                  css: "is-selected",
                });
              } else if (
                isValuePossible(
                  this.props.aggregatedCharacteristicValues,
                  value,
                  characteristic,
                )
              ) {
                possibleValues.push({
                  id: value.id,
                  value: getCharacteristicValue(characteristic, value),
                  css: "is-selectable",
                });
              } else {
                notPossibleValues.push({
                  id: value.id,
                  value: getCharacteristicValue(characteristic, value),
                  css: "is-not-selectable",
                });
              }
            }, filteredPossibleValues);

            return (
              <div key={entry} className="control filters">
                <label className="label">
                  {characteristic.characteristicValues.benennung
                    ? characteristic.characteristicValues.benennung.values[0]
                    : characteristic.name}
                </label>
                <Dropdown
                  possibleValues={possibleValues}
                  notPossibleValues={notPossibleValues}
                  onClickMethod={(value) =>
                    this.props.setFilter(value, characteristic.id)
                  }
                  entry={entry}
                  isSelected={
                    this.props.currentFilters.findIndex(
                      (filter) => filter.characteristicId === entry,
                    ) !== -1
                  }
                  characteristicId={characteristic.id}
                />
              </div>
            );
          },
        );
    }

    return characteristicFilters;
  }

  onKeyPress(value, keyCode) {
    if (keyCode === KEYCODE_ENTER || keyCode === KEYCODE_TAB) {
      this.props.setSearch(value);
    }
  }

  onBlur(value) {
    value !== this.props.currentSearchTerm && this.props.setSearch(value);
    return value;
  }

  render() {
    return (
      <div className="product-selection-filter-wrapper">
        <div className="search-reset-wrapper">
          <div className="control product-search">
            <label className="label">{L10n.format("search")}</label>
            <div className="control has-icons-right">
              <SmartInput
                autoComplete="off"
                className={classNames([
                  "input has-text-black",
                  { "is-selected": this.props.currentSearchTerm },
                ])}
                onKeyDown={({ target, keyCode }) =>
                  this.onKeyPress(target.value, keyCode)
                }
                onBlur={({ target }) => this.onBlur(target.value)}
                value={this.props.currentSearchTerm}
                placeholder={L10n.format("catalogue_search_placeholder")}
                data-input-field-id="catalogue-search"
              />
              <span className="icon is-small is-right">
                {this.props.searchFilterLoading ? (
                  <Loading />
                ) : (
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                )}
              </span>
            </div>
          </div>
          <div className="control">
            <div className="label">&nbsp;</div>
            <button
              className="button is-info is-pulled-right is-fullwidth is-blue-dark"
              data-button-id="catalogue_clear_filters"
              onClick={() => this.props.onReset()}
            >
              <div className="is-pulled-left">
                {L10n.format("catalogue_clear_filters")}
              </div>
            </button>
          </div>
        </div>
        <div className="filter-wrapper">{this.renderFilters()}</div>
      </div>
    );
  }
}
