import { default as classNames, default as classnames } from "classnames";
import {
  addIndex,
  all,
  equals,
  gt,
  inc,
  is,
  length,
  lt,
  map,
  not,
} from "ramda";
import { useState } from "react";

import { Viewport } from "@encoway/react-components";

import { ParameterTO } from "../../../types/@encoway/Parameter";
import {
  ConfiguratorComponentProps,
  ConfiguratorComponentStyleProps,
  ContainerTO,
} from "../../../types/configuration";

type SectionStyle = ConfiguratorComponentStyleProps & {
  columns: number;
  collapsable: boolean;
};

type SectionOptions = {
  currency: string;
  eventBus: unknown;
  hideInternalTree: boolean;
  hideLinkedTree: boolean;
  nameDynamicContainers: (
    child: ContainerTO,
    index: number,
    children: ContainerTO[],
  ) => string;
};

const DEFAULT_STYLE: Partial<SectionStyle> = {
  columns: 1,
  compact: false,
  compactLeftColumn: "is-5",
  compactRightColumn: "is-7",
  collapsable: false,
} as const;

function isContainerEmpty(container: ContainerTO): boolean {
  const { children, parameters } = container;
  if (is(Array, parameters) && !equals(length(parameters), 0)) {
    return false;
  }

  if (!is(Array, children)) {
    return true;
  }
  return all(isContainerEmpty, children);
}

function determineTranslatedName(
  child: ContainerTO,
  children: ContainerTO[],
  options: SectionOptions,
  index: number,
): string {
  return equals(typeof options?.nameDynamicContainers, "function")
    ? options.nameDynamicContainers(child, index, children)
    : `${inc(index)}`;
}

type ParametersProps = ConfiguratorComponentProps<ContainerTO> & {
  hasColumns: boolean;
  style: SectionStyle;

  parameterFilter?: (parameter: ParameterTO) => boolean;
};

function Parameters(props: Readonly<ParametersProps>) {
  const { data, hasColumns, style } = props;
  const columnStyle = classnames({
    content: true,
    column: hasColumns,
    "is-half": hasColumns && equals(style.columns, 2),
    "is-third": hasColumns && equals(style.columns, 3),
    "is-quarter": hasColumns && equals(style.columns, 4),
  });

  return (
    <>
      {map((parameter: ParameterTO) => {
        if (props.parameterFilter?.(parameter) === false) {
          return null;
        }
        const view = Viewport.parse(null, "parameter");
        return (
          <div key={parameter.id} className={style.compact ? "" : columnStyle}>
            {Viewport.instance(view.name, {
              key: parameter.id,
              data: parameter,
              style: style.compact
                ? {
                    compact: true,
                    compactLeftColumn: style.compactLeftColumn,
                    compactRightColumn: style.compactRightColumn,
                    ...view.style,
                  }
                : view.style,
              mediaLink: props.mediaLink,
              onValueChanged: props.onValueChanged,
              onFocus: props.onFocus,
              error: props.error,
              options: props.options,
              config: props.config,
            })}
          </div>
        );
      }, data.parameters)}
    </>
  );
}

type SubsectionsProps = ConfiguratorComponentProps<ContainerTO> & {
  level: number;
};

function Subsections(props: Readonly<SubsectionsProps>) {
  const {
    config,
    data,
    error,
    level,
    options,
    mediaLink,
    onValueChanged,
    onFocus,
  } = props;
  return (
    <>
      {addIndex<ContainerTO>(map)((child: ContainerTO, index: number) => {
        child.translatedName = determineTranslatedName(
          child,
          data.children,
          options,
          index,
        );
        const viewport = Viewport.parse(
          child.name,
          Viewport.orElse(child.viewPort!, "section"),
        );
        return (
          <div
            key={child.id}
            className={classnames({
              container: true,
              "is-subsection": gt(level, 1),
            })}
          >
            {Viewport.instance(viewport.name, {
              key: child.id,
              data: child,
              style: viewport.style,
              mediaLink: mediaLink,
              onValueChanged: onValueChanged,
              onFocus: onFocus,
              options: options,
              error: error,
              level: inc(level),
              config: config,
            })}
          </div>
        );
      }, data.children)}
    </>
  );
}

type ContentProps = ConfiguratorComponentProps<ContainerTO> & {
  level: number;
  style: SectionStyle;
  parameterFilter?: (parameter: ParameterTO) => boolean;
};

function Content(props: Readonly<ContentProps>) {
  const { data, style } = props;

  const hasColumns =
    Boolean(style.columns) && gt(style.columns, 1) && lt(style.columns, 5);

  const columnsStyle = style.compact
    ? ""
    : classnames({
        columns: hasColumns,
        "is-multiline": hasColumns,
        content: true,
      });

  return (
    <div>
      {data.shortText && (
        <p className="subtitle is-shorttext">{data.shortText}</p>
      )}
      <div className={columnsStyle}>
        <Parameters hasColumns={hasColumns} {...props} />
      </div>
      <Subsections {...props} />
    </div>
  );
}

type IconProps = {
  collapsed: boolean;
};

function Icon({ collapsed }: Readonly<IconProps>) {
  return (
    <span className="icon">
      <i
        className={classnames({
          fa: true,
          "fa-angle-down": !collapsed,
          "fa-angle-right": collapsed,
        })}
      />
    </span>
  );
}

type SectionProps = ConfiguratorComponentProps<ContainerTO, SectionOptions> & {
  level: number;
  style: SectionStyle;
  parameterFilter?: (parameter: ParameterTO) => boolean;
};

export function Section(props: Readonly<SectionProps>) {
  const { data } = props;
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const style = { ...DEFAULT_STYLE, ...props.style };

  if (isContainerEmpty(data)) {
    return null;
  }

  if (style.collapsable) {
    return (
      <div id={data.id} className="content is-section">
        <p
          className={classNames("title", "is-section", {
            "has-subsections": data.children.length > 0,
          })}
        >
          <button onClick={() => setCollapsed(not)}>
            <Icon collapsed={collapsed} /> {data.translatedName}
          </button>
        </p>
        {!collapsed && <Content {...props} style={style} />}
      </div>
    );
  }

  return (
    <div id={data.id} className="content is-section">
      {gt(props.level, 1) && (
        <p
          className={classNames("title", "is-section", {
            "has-subsections": data.children.length > 0,
          })}
        >
          {data.translatedName}
        </p>
      )}
      <Content {...props} style={style} />
    </div>
  );
}
