import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNil, map, values } from "ramda";
import { PropsWithChildren, useEffect, useState } from "react";

import { PossibleValues } from "@encoway/rest-api";

import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../../../../components/modal/modal";
import { useApp } from "../../../../../hooks/useApp";
import { useCart } from "../../../../../hooks/useCart";
import {
  hasRightToPrintEnDocXML,
  hasRightToPrintXML,
} from "../../../../../http/identityHelper";
import { CART_MODALS, CartModalsReturn } from "../../../hooks/useCartModals";
import { t } from "../../../localizationUtils";
import { determineXMLFileName } from "./printOptionsUtils";
import { usePrintOptionsModal } from "./usePrintOptionsModal";

import "./printOptionsModal.scss";

function Section({
  label,
  children,
}: Readonly<PropsWithChildren<{ label: string }>>) {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="field-body">
        <div className="field">
          <div className="control">{children}</div>
        </div>
      </div>
    </div>
  );
}

type RadioButtonProps = {
  checked: string | number | readonly string[] | undefined;
  label: string;
  name: string | undefined;
  value: string | number | readonly string[] | undefined;

  onValueChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

function RadioButton(props: Readonly<RadioButtonProps>) {
  const {
    label,
    name,
    value,
    checked,

    onValueChange,
  } = props;

  return (
    <label className="radio is-fullwidth">
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked === value}
        onChange={onValueChange}
        data-checkbox-id={label}
      />
      <span>{t(label)}</span>
    </label>
  );
}

type CheckBoxProps = {
  label: string;
  checked: boolean | undefined;

  onChecked: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

function CheckBox(props: Readonly<CheckBoxProps>) {
  const { label, checked, onChecked } = props;

  return (
    <div className="field">
      <div className="control">
        <label className="checkbox">
          <input
            type="checkbox"
            className="checkbox"
            checked={checked}
            onChange={onChecked}
            data-checkbox-id={label}
          />
          <span> {t(label)} </span>
        </label>
      </div>
    </div>
  );
}

type PrintOptionsModalProps = {
  modalActions: CartModalsReturn["modalActions"];
};

export const PrintOptionsModal = (props: Readonly<PrintOptionsModalProps>) => {
  const { modalActions } = props;
  const { identityStore } = useApp();
  const { cart } = useCart();
  const { toggleModal, downloadFromPrintOption } = modalActions;
  const {
    printOptionsValues,
    onInputChange,
    onCheckboxChange,
    onRadioOrDropdownChange,
  } = usePrintOptionsModal(cart, modalActions);
  const { catalogService } = useApp();
  const [printOfferOptions, setPrintOfferOptions] = useState<PossibleValues>();

  useEffect(() => {
    (async () => {
      const availableOfferLanguages = await catalogService.product(
        "print_offer_languages",
      );
      setPrintOfferOptions(
        availableOfferLanguages?.characteristics[0].possibleValues,
      );
    })();
  }, []);

  if (isNil(printOfferOptions)) {
    return null;
  }

  return (
    <Modal dialogId="print-options">
      <ModalHeader
        label={t("printoptions_dialog_title")}
        onCancel={toggleModal(CART_MODALS.PRINT_OPTION)}
      />
      <ModalBodyWrapper className="print-options">
        <div className="two-columns" data-dialog-id="print_options">
          <div className="column-entry-wide with-padding">
            <Section label={t("printoptions_dialog_variant")}>
              <CheckBox
                label="printoptions_dialog_variant_short"
                checked={printOptionsValues.short}
                onChecked={onCheckboxChange("short")}
              />
              <CheckBox
                label="printoptions_dialog_variant_long"
                checked={printOptionsValues.long}
                onChecked={onCheckboxChange("long")}
              />
              <CheckBox
                label="printoptions_dialog_variant_techSpec"
                checked={printOptionsValues.techSpec}
                onChecked={onCheckboxChange("techSpec")}
              />
            </Section>
          </div>
          <div className="column-entry-slim with-padding">
            <Section label={t("printoptions_dialog_language")}>
              <div className="select is-fullwidth">
                <select
                  value={printOptionsValues.language}
                  onChange={onRadioOrDropdownChange("language")}
                  data-dropdown-id={"printoptions_dialog_language"}
                >
                  {map(
                    (offerLanguage) => (
                      <option
                        key={offerLanguage.value}
                        data-dropdown-id={`printoptions_dialog_lang_${offerLanguage.id}`}
                        value={offerLanguage.value}
                      >
                        {t(`printoptions_dialog_lang_${offerLanguage.id}`)}
                      </option>
                    ),
                    values(printOfferOptions),
                  )}
                </select>
              </div>
            </Section>
            <Section label={t("printoptions_dialog_format")}>
              <RadioButton
                label={"printoptions_dialog_format_pdf"}
                name="documentType"
                value="PDF"
                checked={printOptionsValues.documentType}
                onValueChange={onRadioOrDropdownChange("documentType")}
              />
              <RadioButton
                label={"printoptions_dialog_format_word"}
                name="documentType"
                value="DOCX"
                checked={printOptionsValues.documentType}
                onValueChange={onRadioOrDropdownChange("documentType")}
              />
              {hasRightToPrintXML(cart.authorities.booleanAuthorities) && (
                <RadioButton
                  label={determineXMLFileName(identityStore.getXMLFileName())}
                  name="documentType"
                  value="XML_OPENTRANS"
                  checked={printOptionsValues.documentType}
                  onValueChange={onRadioOrDropdownChange("documentType")}
                />
              )}
              {hasRightToPrintEnDocXML(cart.authorities.booleanAuthorities) && (
                <RadioButton
                  label={"EN-Doc XML"}
                  name="documentType"
                  value="XML"
                  checked={printOptionsValues.documentType}
                  onValueChange={onRadioOrDropdownChange("documentType")}
                />
              )}
            </Section>
          </div>
        </div>
        <hr />
        <div className="two-columns">
          <div className="column-entry-wide with-padding">
            <Section label={t("printoptions_dialog_price")}>
              <RadioButton
                label={"printoptions_dialog_price_without"}
                name="price"
                value="without"
                checked={printOptionsValues.price}
                onValueChange={onRadioOrDropdownChange("price")}
              />
              <RadioButton
                label={"printoptions_dialog_price_offer"}
                name="price"
                value="offer"
                checked={printOptionsValues.price}
                onValueChange={onRadioOrDropdownChange("price")}
              />
              <RadioButton
                label={"printoptions_dialog_price_net"}
                name="price"
                value="net"
                checked={printOptionsValues.price}
                onValueChange={onRadioOrDropdownChange("price")}
              />
              <RadioButton
                label={"printoptions_dialog_price_purchase"}
                name="price"
                value="purchase"
                checked={printOptionsValues.price}
                onValueChange={onRadioOrDropdownChange("price")}
              />
            </Section>
          </div>
          <div className="column-entry-slim with-padding">
            <Section label={t("printoptions_dialog_further_options")}>
              <CheckBox
                label="printoptions_dialog_logo"
                checked={printOptionsValues.logo}
                onChecked={onCheckboxChange("logo")}
              />
              <CheckBox
                label="printoptions_dialog_VAT"
                checked={printOptionsValues.vat}
                onChecked={onCheckboxChange("vat")}
              />
              <CheckBox
                label="printoptions_dialog_manufacturer"
                checked={printOptionsValues.manufacturer}
                onChecked={onCheckboxChange("manufacturer")}
              />
              <CheckBox
                label="printoptions_dialog_priceDetails"
                checked={printOptionsValues.priceDetails}
                onChecked={onCheckboxChange("priceDetails")}
              />
            </Section>
          </div>
        </div>
        <hr />
        <div className="two-columns">
          <div className="column-entry-wide with-padding">
            <Section label={t("printoptions_dialog_salutation")}>
              <div className="control is-expanded">
                <input
                  name="salutation"
                  className="input"
                  value={printOptionsValues.salutation}
                  onChange={onInputChange}
                  placeholder={t("printoptions_dialog_sal_placeholder")}
                  type="text"
                  data-input-field-id={"printoptions_dialog_salutation"}
                />
              </div>
            </Section>
          </div>
          <div className="column-entry-wide with-padding">
            <Section label={t("printoptions_dialog_offerText")}>
              <div className="control is-expanded">
                <textarea
                  name="offerText"
                  className="textarea"
                  value={printOptionsValues.offerText}
                  onChange={onInputChange}
                  placeholder={t("printoptions_dialog_offerText_placeholder")}
                  data-input-field-id={"printoptions_dialog_offerText"}
                >
                  {printOptionsValues.offerText}
                </textarea>
              </div>
            </Section>
          </div>
          <div className="column-entry-wide with-padding">
            <Section label={t("printoptions_dialog_coverLetter")}>
              <div className="control is-expanded">
                <textarea
                  name="coverLetter"
                  className="textarea"
                  value={printOptionsValues.coverLetter}
                  onChange={onInputChange}
                  placeholder={t("printoptions_dialog_coverLetter_placeholder")}
                  data-input-field-id={"printoptions_dialog_coverLetter"}
                >
                  {printOptionsValues.coverLetter}
                </textarea>
              </div>
            </Section>
          </div>
          <div className="column-entry-wide with-padding">
            <Section label={t("printoptions_dialog_appendix")}>
              <textarea
                name="appendix"
                className="textarea"
                value={printOptionsValues.appendix}
                onChange={onInputChange}
                data-input-field-id={"printoptions_dialog_appendix"}
              >
                {printOptionsValues.coverLetter}
              </textarea>
            </Section>
          </div>
        </div>
      </ModalBodyWrapper>
      <ModalFooter
        onCancel={toggleModal(CART_MODALS.PRINT_OPTION)}
        cancelLabel={t("cancel")}
        saveLabel={t("printoptions_dialog_print")}
        onSave={downloadFromPrintOption(printOptionsValues)}
        saveIcon={<FontAwesomeIcon icon={faCheck} className="pl-2" />}
        saveButtonId={"printoptions_dialog_print"}
      />
    </Modal>
  );
};
