import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { isEmpty, isNil } from "ramda";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";

import { L10n } from "@encoway/l10n";

import {
  FlexTableCell as Cell,
  FlexTableHead as Head,
  FlexTableWrapper as Table,
} from "../../../../components/flexTable/flexTable";
import { SmartInput } from "../../../../components/input/smartInput";
import { Loading } from "../../../../components/loading";
import {
  ContactDto,
  CustomerDto,
} from "../../../customerManagement/customerManagementUtils";
import { getFirstOrPreSelectedAddressIndex } from "../../offerManagementUtils";
import {
  CustomerSearch,
  ModifyOfferModalReducedState,
  ResetSearchValue,
  SearchValueChange,
  SetCustomerAddress,
  ToggleSelectedCustomer,
} from "../modifyOfferModalActions";
import { CustomerSearchTableAddress } from "./customerSearchTableAddress";
import { CustomerSearchTableResults } from "./customerSearchTableResults";

import "./customerSearchTable.scss";

function getSearchValue(searchValue: string | undefined) {
  return isNil(searchValue) ? (
    <FontAwesomeIcon icon={faSearch} />
  ) : (
    <FontAwesomeIcon icon={faTimes} />
  );
}

type CustomerSearchTableHeaderProps = {
  label: string;
  placeHolder: string;
  searchResult: CustomerDto[] | null;
  searchValue: string | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onReset: () => void;
  attributeId: string;
  searchFilterLoading: boolean;
};

function CustomerSearchTableHeader(
  props: Readonly<CustomerSearchTableHeaderProps>,
) {
  const {
    label,
    placeHolder,
    searchResult,
    searchValue,
    onChange,
    onReset,
    attributeId,
    searchFilterLoading,
  } = props;

  return (
    <Head
      className={classNames({
        "fill-empty-row": isEmpty(searchResult) && isEmpty(searchValue),
      })}
    >
      <Cell>
        <div className="customer-search-header">
          <h2 className="subtitle has-text-weight-bold">{label}</h2>
          <p className="control has-icons-right ">
            <SmartInput
              className="input customer-search-input-field"
              type="text"
              name="search"
              value={searchValue}
              onChange={onChange}
              placeholder={placeHolder}
              persistOnChange={true}
              autoComplete={"off"}
              attributeId={attributeId}
            />
            <button
              onClick={onReset}
              className="icon is-small is-right has-pointer no-border transparent"
            >
              {searchFilterLoading ? (
                <Loading />
              ) : (
                <div>{getSearchValue(searchValue)}</div>
              )}
            </button>
          </p>
        </div>
      </Cell>
    </Head>
  );
}

type CustomerSearchProps = {
  state: CustomerSearch;
  setState: Dispatch<SetStateAction<ModifyOfferModalReducedState>>;
};

export function CustomerSearchTable(props: Readonly<CustomerSearchProps>) {
  const { state, setState } = props;

  const [searchFilterLoading, setSearchFilterLoading] = useState(false);

  const onToggleSelectedCustomer = (customer: CustomerDto) => () =>
    setState(ToggleSelectedCustomer(customer));

  const onSetCustomerAddress = (address: ContactDto) =>
    setState(SetCustomerAddress(address));

  const onCustomerSearchReset = () => setState(ResetSearchValue);

  const onSearchValueChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchFilterLoading(true);
    setState(SearchValueChange(value));
    setSearchFilterLoading(false);
  };

  return (
    <Table className="customer-search with-padding">
      <CustomerSearchTableHeader
        label={L10n.format("search")}
        onChange={onSearchValueChange}
        searchResult={state.searchResult}
        searchValue={state.searchValue}
        placeHolder={L10n.format("customer_search_placeholder")}
        onReset={onCustomerSearchReset}
        attributeId="search"
        searchFilterLoading={searchFilterLoading}
      />
      <CustomerSearchTableResults
        disableSearchResult={state.disableSearchResult}
        searchResult={state.searchResult}
        searchValue={state.searchValue}
        selectedCustomer={state.selectedCustomer}
        onToggleSelectedCustomer={onToggleSelectedCustomer}
      />
      <CustomerSearchTableAddress
        customer={state.selectedCustomer}
        onClick={onSetCustomerAddress}
        toggleCustomerAddress={state.toggleCustomerAddress}
        presetId={getFirstOrPreSelectedAddressIndex(
          state.selectedAddress,
          state.selectedCustomer,
        )}
      />
    </Table>
  );
}
