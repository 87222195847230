import { any, isNil } from "ramda";

import { L10n } from "@encoway/l10n";
import { Icon } from "@encoway/react-components";

import { ConfiguratorComponentStyleProps } from "../../../../types/configuration";

type DescriptionLinkProps = Readonly<{
  longText: string | undefined;
  id: string;
  imageUrl: string | undefined;
  parameterStyle: ConfiguratorComponentStyleProps;
  handleDescriptionClick: () => void;
}>;

export function DescriptionLink(props: DescriptionLinkProps) {
  const { longText, id, imageUrl, parameterStyle, handleDescriptionClick } =
    props;

  if (any(isNil, [longText, imageUrl])) {
    return null;
  }

  return (
    <button onClick={handleDescriptionClick} className="info-icon">
      <span className="icon is-small">
        <Icon
          src={parameterStyle.descriptionIcon}
          title={L10n.format("DESCRIPTION")}
          iconId={`info-icon-${id}`}
        />
      </span>
    </button>
  );
}
