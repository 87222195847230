import { Box, Stack, styled } from "@mui/material";
import { isNotNil } from "ramda";
import { DragEvent, PropsWithChildren, useState } from "react";

const DropzoneContainer = styled(Stack)`
  justify-content: center;
  position: absolute;
  inset: 0;
`;

const DropzoneIndicator = styled(Box)`
  background-color: rgba(239, 123, 34, 0.6);
  position: absolute;
  inset: 0;
  pointer-events: none;
  z-index: 2;
`;

type DropzoneProps = Readonly<
  PropsWithChildren<{
    handleDrop?: (file: File) => void;
  }>
>;

export function Dropzone(props: DropzoneProps) {
  const { children, handleDrop } = props;
  const [isDraggedOver, setIsDraggedOver] = useState<boolean>(false);

  function onDrop(e: DragEvent<HTMLDivElement>): void {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer?.files[0];
    if (isNotNil(file)) {
      handleDrop?.(file);
    }
    e.dataTransfer?.clearData();
    setIsDraggedOver(false);
  }

  function onActive(isOver: boolean) {
    return function (e: DragEvent<HTMLDivElement>): void {
      e.preventDefault();
      e.stopPropagation();
      setIsDraggedOver(isOver);
    };
  }

  return (
    <DropzoneContainer
      direction="row"
      onDragOver={onActive(true)}
      onDrop={onDrop}
      onDragEnd={onDrop}
      onDragLeave={onActive(false)}
    >
      {isDraggedOver && <DropzoneIndicator />}
      {children}
    </DropzoneContainer>
  );
}
