import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ComponentPropsWithoutRef, MouseEventHandler } from "react";

import { L10n } from "@encoway/l10n";

type IconButtonProps = {
  icon: IconProp;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

export const IconButton = ({ icon, onClick, disabled }: IconButtonProps) => (
  <button className="button is-white" onClick={onClick} disabled={disabled}>
    <span className="icon">
      <FontAwesomeIcon icon={icon} />
    </span>
  </button>
);

type ChooseFileInputProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const ChooseFileInput = ({ onClick }: ChooseFileInputProps) => (
  <div className="field">
    <div className="field has-addons">
      <div className="control">
        <button className="button is-info is-orange" onClick={onClick}>
          {L10n.format("offer_management_create_lv_import_select_lv")}
        </button>
      </div>
    </div>
  </div>
);

type NumberInputFieldProps = ComponentPropsWithoutRef<"input"> & {
  label: string;
  addon?: string;
};

export const NumberInputField = ({
  label,
  addon,
  ...props
}: NumberInputFieldProps) => (
  <div className="field">
    <label className="label">{L10n.format(label)}</label>
    <div className="field has-addons">
      <div className="control is-expanded">
        <input className="input" type="number" {...props} />
      </div>
      {addon && (
        <div className="control">
          <div className="button is-static">{addon}</div>
        </div>
      )}
    </div>
  </div>
);
