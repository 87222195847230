import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, styled } from "@mui/material";
import { useState } from "react";

import { L10n } from "@encoway/l10n";

import { ModalFooter, ModalFooterProps } from "../../../components/modal/modal";
import { DeleteCartItemWarningPopup } from "../../shoppingCart/components/table/cells/dropdownCell/deleteCartItemWarningPopup";

type LvModalFooterProps = ModalFooterProps & {
  hasWarning?: boolean;
};

const ModalFooterWrapper = styled(ModalFooter)({
  flexDirection: "row-reverse",
  gap: "0.5rem",
});

const LvButtons = styled(Box)({
  justifyContent: "space-between",
  display: "flex",
  flex: "1",
});

const MiddleButtons = styled(Box)({
  display: "flex",
  flex: "1",
  flexDirection: "row",
});

export const LvCancelWarning = styled(DeleteCartItemWarningPopup)({
  position: "relative",
  right: "5rem",
  bottom: "1rem",
  ".sec-warn": {
    width: "15rem",
    textWrap: "wrap",
    fontSize: "0.9rem !important",
    bottom: "2rem",
    "::before": {
      display: "none",
    },

    "::after": {
      borderColor: "#000 transparent transparent #000 !important",
      content: '" "',
      width: "0",
      height: "0",
      border: "7px solid",
      position: "absolute",
      bottom: "-12px",
    },
  },
});

export function LvModalFooter({
  cancelLabel,
  onCancel,
  children,
  hasWarning = true,
  ...props
}: Readonly<LvModalFooterProps>) {
  const [showCancelWarning, setShowCancelWarning] = useState(false);

  const onButtonCancel = () =>
    hasWarning ? setShowCancelWarning(true) : onCancel?.();

  return (
    <ModalFooterWrapper {...props} onCancel={onCancel}>
      <LvButtons>
        <button
          data-button-id="button-cancel-lv-position"
          className="button cancel-button is-info is-blue-dark"
          onClick={onButtonCancel}
        >
          <span>{cancelLabel}</span>
          <span className="icon">
            <FontAwesomeIcon icon={faBan} />
          </span>
        </button>
        {showCancelWarning && hasWarning && (
          <LvCancelWarning
            label={L10n.format("offer_management_lv_cancel_warning")}
            onDelete={() => onCancel?.()}
            onCancel={() => setShowCancelWarning(false)}
          />
        )}
        <MiddleButtons>{children}</MiddleButtons>
      </LvButtons>
    </ModalFooterWrapper>
  );
}
