import {
  any,
  eqProps,
  equals,
  find,
  includes,
  is,
  isEmpty,
  join,
  length,
  not,
  path,
  pickBy,
  propIs,
  test,
  values,
} from "ramda";

import { L10n } from "@encoway/l10n";

import {
  getFormattedDate,
  getFormattedTime,
} from "../../../utilities/dateUtils";
import { isNonEmptyString } from "../../../utilities/utilities";
import { CustomerDto } from "../../customerManagement/customerManagementUtils";
import { notNilEmpty } from "../offerManagementUtils";
import { InputConstants } from "./inputConstants";
import {
  CustomerInformation,
  CustomerSearch,
  ModifyOfferModalReducedState,
} from "./modifyOfferModalActions";

const DATES_KEY = [
  "creationDate",
  "modificationDate",
  "checkoutDate",
  "priceDate",
];

export const isComplete = (
  buildProject: string | undefined,
  referenceNumber: string | undefined,
  shippingAddressMandatoriesCompleted: boolean,
) =>
  isNonEmptyString(buildProject) &&
  isNonEmptyString(referenceNumber) &&
  shippingAddressMandatoriesCompleted;

export const checkDisplayedValue = (
  value: string | number | string[],
  key: string,
) => {
  if (is(Array, value)) {
    return join(", ", value);
  }
  if (
    value === "all" ||
    value === "OPEN" ||
    value === "ORDERED" ||
    value === "ORDERED_PART" ||
    value === "SHARED"
  ) {
    return L10n.format(`offer_management_table_status_${value}`);
  }
  if (includes(key, DATES_KEY)) {
    return getFormattedDate(value);
  }
  return value;
};

/**
 * finds the max length value in InputConstants
 * @param key the key to match
 * @returns {*} the max length value
 */
export const findMaxLength = (key: string): number | undefined => {
  const numberConstants: Record<string, number> = pickBy(
    is(Number),
    InputConstants.length,
  );
  return path([key], numberConstants);
};

/**
 * Checks for an empty string or with whitepsaces only
 * @param {String} customerNumber the string to check
 * @returns {boolean} returns true if its valid
 */
export const isNotEmptyAndWithoutWhitespaces = (customerNumber: string) =>
  !test(/^\s*$/g, customerNumber);

/**
 * Checks if the salutation is matching with the translations
 * @param {String} salutation the salutations string (mr, mrs, herr, frau)
 * @returns {boolean} true if the salutation is found
 */
export const isNewSalutation = (salutation: string) =>
  !notNilEmpty(salutation) ||
  salutation === L10n.format("modify_dialog_contact_mr") ||
  salutation === L10n.format("modify_dialog_contact_mrs");

/**
 * Checks if the selectedCustomer matches the given customerNumber and name
 * @param {CustomerInformation} customerInformation selectedCustomer object
 * @param {CustomerSearch} customerSearch
 * @returns {boolean} returns true if the selectedCustomer isn't matching with the given number and name
 */
export const isSelectedCustomerChangedOrSearchValueEmpty = (
  customerInformation: CustomerInformation,
  { selectedCustomer, searchValue }: CustomerSearch,
) =>
  !selectedCustomer ||
  customerInformation.customerNumber !== selectedCustomer.customerNumber ||
  customerInformation.customerName !== selectedCustomer.customerName ||
  isEmpty(searchValue);

export const findCustomer = (
  customerNumber: string,
  customers: CustomerDto[],
) =>
  notNilEmpty<string | CustomerDto[]>(customerNumber, customers) &&
  find((customer) => customer.customerNumber === customerNumber, customers);

/**
 *
 * @param customerInformation
 * @param customers
 * @param prop
 * @returns {string|f1}
 */
const findCustomerFromCustomerProp = (
  {
    customerInformation,
    customerSearch: { customers },
  }: ModifyOfferModalReducedState,
  prop: string,
) =>
  find(
    (customer) =>
      not(propIs(isEmpty, prop, customerInformation)) &&
      eqProps(prop, customerInformation, customer),
    customers,
  ) || null;

/**
 * trys to find the given customer
 * @param state the modifyOfferDialog state
 * @returns {object} the found customer or nil
 */
export const findCustomerFromState = (state: ModifyOfferModalReducedState) =>
  findCustomerFromCustomerProp(state, "customerNumber") ||
  findCustomerFromCustomerProp(state, "customerName");

/**
 * Matches the exact customerNumber or the customerName if the number isn't matching
 * @param {Customer} foundCustomer the customer to search in
 * @param {CustomerInformation} customerInformation the  customer to search with
 * @returns {string | undefined} customerName or customerNumber or undefined
 */
const getNameOrNumberPerfectMatch = (
  foundCustomer: CustomerDto,
  customerNumber: string,
  customerName: string,
): string => {
  if (equals(customerNumber, foundCustomer.customerNumber)) {
    return customerNumber;
  }
  if (equals(customerName, foundCustomer.customerName)) {
    return customerName;
  }
  return "";
};

/**
 * Matches the customerName or customerNumber if the given value includes the match
 * @param {Customer} foundCustomer the customer to search in
 * @param {CustomerInformation} customerInformation the  customer to search with
 * @returns {string | undefined} the customerNumber or the customerName or undefined if nothing matches
 */
const getNameOrNumberIncludesMatch = (
  foundCustomer: CustomerDto,
  customerNumber: string,
  customerName: string,
): string => {
  if (includes(customerNumber, foundCustomer.customerNumber)) {
    return customerNumber;
  }
  if (includes(customerName, foundCustomer.customerName)) {
    return customerName;
  }
  return "";
};

/**
 * Returns the found customerNumber or the customerName
 * @param foundCustomer the customer to search
 * @param customerInformation the customer to match
 * @returns {string | undefined} customerNumber or Name or undefined if nothing is found
 */
export const getFoundNumberOrName = (
  foundCustomer: CustomerDto | null,
  { customerInformation }: ModifyOfferModalReducedState,
): string => {
  if (foundCustomer) {
    return (
      getNameOrNumberPerfectMatch(
        foundCustomer,
        customerInformation.customerNumber,
        customerInformation.customerName,
      ) ||
      getNameOrNumberIncludesMatch(
        foundCustomer,
        customerInformation.customerNumber,
        customerInformation.customerName,
      )
    );
  }
  return customerInformation.customerNumber || customerInformation.customerName;
};

/**
 * Checks if a customer exists based on a list of customers
 * @param {String} customerNumber the given customerNumber
 * @param customers the given customers
 * @returns {boolean} true if a customer exist, false if not
 */
export const customerExists = (
  customerNumber: string,
  customers: CustomerDto[],
) =>
  notNilEmpty<string | CustomerDto[]>(customerNumber, customers) &&
  any((customer) => equals(customer.customerNumber, customerNumber), customers);

export const formatDateWithTime = (orderedDate: string) => {
  const date = getFormattedDate(orderedDate);
  const time = getFormattedTime(orderedDate);
  return `${date} - ${time}`;
};

export const containsOddNumberOfObject = (checkedObject: Record<string, any>) =>
  length(values(checkedObject)) % 2 !== 0;
