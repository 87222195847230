import {
  Modal,
  ModalBodyWrapper,
  ModalFooter,
  ModalHeader,
} from "../../components/modal/modal";
import { t } from "../shoppingCart/localizationUtils";

import "./configurationUndoModal.scss";

type ConfigurationUndoModalProps = Readonly<{
  conflictedValue: string;
  conflictedCharacteristic: string;
  isVisible: boolean;
  onCancel: () => void;
}>;

export function ConfigurationUndoModal(props: ConfigurationUndoModalProps) {
  const { conflictedValue, conflictedCharacteristic, isVisible, onCancel } =
    props;

  if (isVisible) {
    return (
      <Modal
        dialogId="configuration-undo-modal"
        className="configuration-undo-modal"
      >
        <ModalHeader
          label={t("configuration_undo_not_possible_title")}
          onCancel={onCancel}
        />
        <ModalBodyWrapper
          label={t("configuration_undo_not_possible", {
            conflictedValue,
            conflictedCharacteristic,
          })}
        />
        <ModalFooter cancelLabel={t("cancel")} onCancel={onCancel} />
      </Modal>
    );
  }

  return null;
}
