import {
  any,
  equals,
  filter,
  find,
  findIndex,
  isNil,
  isNotNil,
  not,
  pathSatisfies,
  propEq,
} from "ramda";
import { useRef, useState } from "react";
import { useImmer } from "use-immer";
import { v4 as uuidv4 } from "uuid";

import { analyzeAddresses } from "../service/lvService";
import {
  CompleteResolvedPosition,
  LvConstructionAndAddressData,
  ResolvedPosition,
  ResolvedPositionTO,
} from "../types/lvTable";
import { addUniqueIdToArray } from "../utilities/lvUtils";
import { isNonEmptyString } from "../utilities/utilities";

const INITIAL_LV_CONSTRUCTION_AND_ADDRESS_DATA: LvConstructionAndAddressData = {
  addresses: [],
  construction: [],
  reference: [],
  contactPersons: [],
};

export const useLvTable = () => {
  const lvFileRef = useRef<File | null>(null);
  const lvOldFileRef = useRef<File | null>(null);
  const [lvPositions, setLvPositions] = useImmer<ResolvedPosition[]>([]);
  const [selectedPositionId, setSelectedPositionId] = useState<string | null>(
    null,
  );
  const [constructionAndAddressData, setConstructionAndAddressData] = useState(
    INITIAL_LV_CONSTRUCTION_AND_ADDRESS_DATA,
  );

  const isAnyProductSelected = any(
    ({ product }) => isNotNil(product),
    lvPositions,
  );

  const selectedLvPositions = filter(
    pathSatisfies(isNonEmptyString, ["product", "value"]),
    lvPositions,
  ) as CompleteResolvedPosition[];

  const areLvFilesEqual = equals(lvFileRef.current, lvOldFileRef.current);

  async function initializeLvStructure(
    selectedFile: File,
    resolvedPositions: ResolvedPositionTO[],
  ) {
    lvFileRef.current = selectedFile;
    const preparedResolvedPositions = addUniqueIdToArray(resolvedPositions);
    setLvPositions(preparedResolvedPositions);
  }

  function setFileToOldFile() {
    lvOldFileRef.current = lvFileRef.current;
  }

  async function getAddressAndConstruction() {
    if (isNil(lvFileRef.current)) {
      return;
    }
    const formData = new FormData();
    formData.append("pdf_input_file", lvFileRef.current);
    const { data } = await analyzeAddresses(formData);
    setConstructionAndAddressData(data);
    setFileToOldFile();
  }

  function clearLvTableState() {
    lvFileRef.current = null;
    lvOldFileRef.current = null;
    setLvPositions([]);
    setSelectedPositionId(null);
    setConstructionAndAddressData(INITIAL_LV_CONSTRUCTION_AND_ADDRESS_DATA);
  }

  function togglePositionType(id: string | null) {
    if (isNil(id)) {
      return;
    }
    setLvPositions((draft) => {
      const index = findIndex(propEq(id, "id"), draft);
      if (not(equals(index, -1))) {
        draft[index].lvType =
          draft[index].lvType === "folder" ? "position" : "folder";
      }
    });
  }

  function addLvPosition(id: string | null) {
    if (isNil(id)) {
      return;
    }
    setLvPositions((draft) => {
      const index = findIndex(propEq(id, "id"), draft);
      if (not(equals(index, -1))) {
        draft.splice(index + 1, 0, {
          id: uuidv4(),
          lvPosition: "",
          lvReference: "",
          lvResolvedReference: "",
          lvResolvedText: "",
          lvText: "",
          lvType: "position",
        });
      }
    });
  }

  function deleteLvPosition(id: string | null) {
    if (isNil(id)) {
      return;
    }
    setLvPositions((draft) => {
      const index = findIndex(propEq(id, "id"), draft);
      if (not(equals(index, -1))) {
        draft.splice(index, 1);
      }
    });
  }

  function updateLvPosition(id: string, newPos: Partial<ResolvedPosition>) {
    setLvPositions((draft) => {
      const index = findIndex(propEq(id, "id"), draft);
      if (not(equals(index, -1))) {
        draft[index] = { ...draft[index], ...newPos };
      }
    });
  }

  function updateLvConfigurationParameter(
    positionId: string,
    parameterName: string,
    parameterValue: string,
  ) {
    setLvPositions((draft) => {
      const position = find(propEq(positionId, "id"), draft);
      if (isNil(position)) {
        return;
      }
      const { lvConfigurationParameters } = position;
      if (isNil(lvConfigurationParameters)) {
        return;
      }
      const parameterIndex = findIndex(
        propEq(parameterName, "name"),
        lvConfigurationParameters,
      );
      if (not(equals(parameterIndex, -1))) {
        lvConfigurationParameters[parameterIndex].value = parameterValue;
      }
    });
  }

  return {
    lvPositions,
    selectedPositionId,
    setSelectedPositionId,
    togglePositionType,
    addLvPosition,
    deleteLvPosition,
    updateLvPosition,
    updateLvConfigurationParameter,
    isAnyProductSelected,
    selectedLvPositions,
    initializeLvStructure,
    clearLvTableState,
    constructionAndAddressData,
    areLvFilesEqual,
    lvFileName: lvFileRef.current?.name,
    lvOldFile: lvOldFileRef.current,
    getAddressAndConstruction,
    setFileToOldFile,
  };
};

export type LvTable = ReturnType<typeof useLvTable>;
