import { faAngleDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  gte,
  isEmpty,
  isNil,
  isNotNil,
  lte,
  map,
  prop,
  tail,
  test,
  toUpper,
} from "ramda";
import { ChangeEvent, FocusEvent, useEffect, useRef } from "react";

import { L10n } from "@encoway/l10n";

import { Dropzone } from "../../../components/Dropzone";
import { LvImportOptions, VALID_LV_FILE_TYPES } from "./LvImportTab";
import { ChooseFileInput, NumberInputField } from "./LvImportTabComponents";
import { SmallBlackLabel } from "./LvPositionView";

import "./PdfDocument.scss";

type PdfSelectionDialogProps = Readonly<{
  handleInputClick: () => void;
  handleFileChange: (file: File) => void;
  selectedFile: File | null;
}>;

function PdfSelectionDialog(props: PdfSelectionDialogProps) {
  const { handleInputClick, handleFileChange, selectedFile } = props;
  const previewRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (previewRef.current && e.target?.result) {
          previewRef.current.src = e.target.result.toString();
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);

  if (isNil(selectedFile)) {
    return (
      <Dropzone handleDrop={handleFileChange}>
        <Stack alignItems="center" justifyContent="center" gap={4.8}>
          <Typography sx={{ fontSize: "1.4rem", fontWeight: "bold" }}>
            {L10n.format("offer_management_create_lv_upload_drag_information")}
          </Typography>
          <Typography>
            {L10n.format("offer_management_create_lv_upload_or")}
          </Typography>
          <ChooseFileInput onClick={handleInputClick} />
          <Stack alignItems="center" position="absolute" bottom="4rem">
            <Typography fontWeight="bold">
              {L10n.format(
                "offer_management_create_lv_upload_supported_file_types_label",
              )}
            </Typography>
            <Typography>
              {L10n.format(
                "offer_management_create_lv_supported_file_size_label",
              )}
            </Typography>
            <Typography>
              {map(
                (filetype) => toUpper(tail(prop("key", filetype))),
                VALID_LV_FILE_TYPES,
              )}
            </Typography>
          </Stack>
        </Stack>
      </Dropzone>
    );
  }

  return (
    <iframe
      ref={previewRef}
      style={{ flex: 1 }}
      title={L10n.format("offer_management_lv_pdf_preview")}
    />
  );
}

type PdfOverviewProps = Readonly<{
  isLoaded: boolean;
  lvImportOptions: LvImportOptions;
  selectedFile: File | null;
  totalPages: number;
  onRemoveFile: () => void;
  setLvImportOptionsValue: (
    name: keyof LvImportOptions,
    value: string | number,
  ) => void;
  unsetAlreadyImported: () => void;
}>;

function PdfOverview(props: PdfOverviewProps) {
  const {
    isLoaded,
    lvImportOptions,
    selectedFile,
    totalPages,
    onRemoveFile,
    setLvImportOptionsValue,
    unsetAlreadyImported,
  } = props;

  const handleOnChange =
    (name: keyof LvImportOptions) => (event: ChangeEvent<HTMLInputElement>) => {
      setLvImportOptionsValue(name, event.target.value);
      unsetAlreadyImported();
    };

  const handleOnBlur =
    (name: keyof LvImportOptions, emptyValue: number, noDecimals = false) =>
    (event: FocusEvent<HTMLInputElement>) => {
      const value = lvImportOptions[name];
      const { min, max } = event.target;

      if (isEmpty(value)) {
        setLvImportOptionsValue(name, emptyValue);
        return;
      }

      if (gte(parseInt(value), parseInt(max))) {
        setLvImportOptionsValue(name, parseInt(max));
        return;
      }

      if (lte(parseInt(value), parseInt(min))) {
        setLvImportOptionsValue(name, parseInt(min));
        return;
      }

      if (noDecimals && test(/[.,]/, value)) {
        setLvImportOptionsValue(name, parseInt(value));
      }
    };

  if (isLoaded) {
    return (
      <Stack
        sx={{
          backgroundColor: "#fff",
          flex: 1,
          padding: "6rem 3rem",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          {L10n.format("offer_management_create_lv")}
        </Typography>
        <Stack>
          <SmallBlackLabel sx={{ marginTop: "0.8rem" }}>
            {L10n.format("offer_management_create_lv_upload_filename_label")}
          </SmallBlackLabel>
        </Stack>
        <Stack
          sx={{
            borderTop: "1px solid #adc3c7",
            borderBottom: "1px solid #adc3c7",
            fontSize: "0.9rem",
            justifyContent: "space-between",
            padding: ".4rem .8rem",
            marginBottom: "4rem",
          }}
          direction="row"
        >
          <Stack>{selectedFile?.name}</Stack>
          <button
            className="p-0 border-0 has-background-white is-clickable"
            onClick={onRemoveFile}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </Stack>
        <Stack>
          <details className="expert-settings-details">
            <summary className="expert-settings-summary subtitle">
              <Typography sx={{ fontWeight: "bold" }}>
                {L10n.format(
                  "offer_management_create_lv_import_expert_settings",
                )}
              </Typography>
              <FontAwesomeIcon
                className="expert-settings-icon"
                icon={faAngleDown}
              />
            </summary>
            <NumberInputField
              label="offer_management_create_lv_import_first_page"
              min={1}
              max={lvImportOptions.lastPage}
              value={lvImportOptions.firstPage}
              onChange={handleOnChange("firstPage")}
              onBlur={handleOnBlur("firstPage", 1, true)}
            />
            <NumberInputField
              label="offer_management_create_lv_import_last_page"
              min={lvImportOptions.firstPage}
              max={totalPages}
              value={lvImportOptions.lastPage}
              onChange={handleOnChange("lastPage")}
              onBlur={handleOnBlur("lastPage", totalPages, true)}
            />
          </details>
        </Stack>
      </Stack>
    );
  }

  return null;
}

type PdfDocumentProps = {
  lvImportOptions: LvImportOptions;
  selectedFile: File | null;
  totalPages: number;
  handleInputClick: () => void;
  handleFileChange: (file: File) => void;
  onRemoveFile: () => void;
  setLvImportOptionsValue: (
    name: keyof LvImportOptions,
    value: string | number,
  ) => void;
  unsetAlreadyImported: () => void;
};

export const PdfDocument = ({
  lvImportOptions,
  selectedFile,
  totalPages,
  handleInputClick,
  handleFileChange,
  onRemoveFile,
  setLvImportOptionsValue,
  unsetAlreadyImported,
}: PdfDocumentProps) => (
  <Grid container flex={1}>
    <Grid
      size={4}
      justifyContent="center"
      sx={{
        borderRight: "1px solid #adc3c7",
        display: "flex",
        flexFlow: "column",
      }}
      position="relative"
    >
      <PdfSelectionDialog
        handleInputClick={handleInputClick}
        selectedFile={selectedFile}
        handleFileChange={handleFileChange}
      />
    </Grid>
    <Grid size={8} sx={{ backgroundColor: "#eef4f4", display: "flex" }}>
      <PdfOverview
        isLoaded={isNotNil(selectedFile)}
        selectedFile={selectedFile}
        onRemoveFile={onRemoveFile}
        lvImportOptions={lvImportOptions}
        totalPages={totalPages}
        setLvImportOptionsValue={setLvImportOptionsValue}
        unsetAlreadyImported={unsetAlreadyImported}
      />
    </Grid>
  </Grid>
);
