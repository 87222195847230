import { Stack, styled } from "@mui/material";
import {
  always,
  assoc,
  head,
  isEmpty,
  map,
  mapObjIndexed,
  values,
} from "ramda";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { L10n } from "@encoway/l10n";

import { GenericDropdown } from "../../../components/GenericDropdown";
import { InputTitle } from "../../../components/input/inputTitle";
import { ModalInput } from "../../../components/modal/ModalInput";
import {
  LvAddress,
  LvConstructionAndAddressData,
} from "../../../types/lvTable";
import {
  getAddressDetails,
  getPersonDetails,
  mergeLeftPersonAndAddress,
} from "../../../utilities/lvUtils";
import { t } from "../../shoppingCart/localizationUtils";
import { Title } from "../lvTable/components/LvProductField";
import { findMaxLength } from "../modifyOfferModal/modifyOfferDialogUtils";
import { LvAddressData } from "./LvOfferTab";

type LvOfferAddressProps = {
  addresses: LvConstructionAndAddressData["addresses"];
  contactPersons: LvConstructionAndAddressData["contactPersons"];
  addressData: LvAddressData;
  setAddressData: Dispatch<SetStateAction<LvAddressData>>;
};

const LvBlueButton = styled("button")({
  backgroundColor: "#adc6c9",
});

export const LvOfferAddress = ({
  addresses,
  contactPersons,
  addressData,
  setAddressData,
}: LvOfferAddressProps) => {
  const [selectedAddress, setSelectedAddress] = useState(head(addresses));
  const [selectedContactPerson, setSelectedContactPerson] = useState(
    head(contactPersons),
  );

  const { title, customerName, ...restAddressData } = addressData;

  useEffect(() => {
    setSelectedAddress(head(addresses));
    setSelectedContactPerson(head(contactPersons));
  }, [addresses, contactPersons]);

  function handleSelectContactPerson(contactPersonValue: LvAddress) {
    setSelectedContactPerson(contactPersonValue);
    const mergedPersonAndAddress = mergeLeftPersonAndAddress(
      contactPersonValue,
      selectedAddress,
    );
    setAddressData(mergedPersonAndAddress);
  }

  function handleSelectAddress(addressValue: LvAddress) {
    setSelectedAddress(addressValue);
    const mergedPersonAndAddress = mergeLeftPersonAndAddress(
      selectedContactPerson,
      addressValue,
    );
    setAddressData(mergedPersonAndAddress);
  }

  return (
    <>
      <Title sx={{ padding: "10px" }}>
        {L10n.format("lv_modify_customer")}
      </Title>
      <div className="custom-margin-2">
        <div className="field with-padding">
          <label className="label">
            {L10n.format("modify_dialog_contact_persons")}
          </label>
          {isEmpty(contactPersons) ? (
            <span className="is-italic">
              {L10n.format("modify_dialog_contact_no_contact_persons")}
            </span>
          ) : (
            <div className="control">
              <GenericDropdown
                dropdownId="addressDropdown"
                value={selectedContactPerson}
                options={contactPersons}
                onChange={handleSelectContactPerson}
                getDisplayValue={getPersonDetails}
                isFullwidth
              />
            </div>
          )}
        </div>
        <div className="field with-padding">
          <label className="label">
            {L10n.format("modify_dialog_contact_addresses")}
          </label>
          {isEmpty(addresses) ? (
            <span className="is-italic">
              {L10n.format("modify_dialog_contact_no_addresses")}
            </span>
          ) : (
            <div className="control">
              <GenericDropdown
                dropdownId="addressDropdown"
                value={selectedAddress}
                options={addresses}
                onChange={handleSelectAddress}
                getDisplayValue={getAddressDetails}
                isFullwidth
              />
            </div>
          )}
        </div>
      </div>
      <Stack direction="row" spacing={1} sx={{ padding: "10px" }}>
        <Title sx={{ flex: 1 }}>
          {L10n.format("modify_dialog_customer_title")}
        </Title>
        <LvBlueButton
          className="button"
          onClick={() => setAddressData((prev) => map(always(""), prev))}
        >
          {t("lv_import_reset_customer_address")}
        </LvBlueButton>
      </Stack>
      <div className="two-columns" style={{ paddingBottom: "15px" }}>
        <div className="column-entry with-padding">
          <InputTitle
            title={title}
            onClick={(title) => setAddressData(assoc("title", title))}
          />
        </div>
        <ModalInput
          name={"customerName"}
          label={L10n.format("modify_dialog_contact_customerName")}
          placeholder={L10n.format("modify_dialog_contact_customerName")}
          value={customerName}
          onChange={({ target }) =>
            setAddressData(assoc("customerName", target.value))
          }
          maxLength={findMaxLength("modify_dialog_contact_customerName")}
          inputId={"lv_modify_dialog_contact_customerName"}
        />
      </div>
      <div className="two-columns custom-margin-2">
        {values(
          mapObjIndexed(
            (value, key) => (
              <ModalInput
                key={key}
                name={key}
                label={L10n.format(`modify_dialog_contact_${key}`)}
                placeholder={L10n.format(`modify_dialog_contact_${key}`)}
                value={value}
                onChange={({ target }) =>
                  setAddressData(assoc(key, target.value))
                }
                maxLength={findMaxLength(`modify_dialog_contact_${key}`)}
                inputId={`lv_modify_dialog_contact_${key}`}
              />
            ),
            restAddressData,
          ),
        )}
      </div>
    </>
  );
};
