import { any, isEmpty, isNil, not, pathOr, pick } from "ramda";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { L10n } from "@encoway/l10n";

import { useApp } from "../../../../../hooks/useApp";
import {
  AddressTO,
  HeaderData,
  TeckentrupCartTO,
} from "../../../../../types/cart";
import { INITIAL_MODIFY_OFFER_MODAL } from "../../../../offerManagement/modifyOfferModal/modifyOfferModalActions";
import {
  getCountryCode,
  isPostCodeValid,
  updateShippingAddress,
} from "../differingShippingAddressUtils";
import { DifferingShippingAddressContent } from "./differingShippingAddressContent";
import { DifferingShippingAddressNote } from "./differingShippingAddressNote";

import "./differingShippingAddress.scss";

type DifferingShippingAddressProps = {
  headerData: TeckentrupCartTO["headerData"];
  checkAllMandatoriesCompleted: (value: boolean) => void;
  updateValue?: (name: string, value: string) => void;
  setShippingAddress?: (value: AddressTO) => void;
};

export type DifferingShippingAddressStateResult = {
  hasDifferingShippingAddress: boolean;
  companyName: string;
  contactSalutation: string;
  contactFirstName: string;
  contactLastName: string;
  telephoneNumber: string;
  street: string;
  houseNumber: string;
  countryCode: string;
  postCode: string;
  city: string;
  forklifter: "ZSV01";
};

export type DifferingShippingAddressState = {
  hasDifferingShippingAddress: boolean;
  companyName: string;
  contactSalutation: string;
  contactFirstName: string;
  contactLastName: string;
  telephoneNumber: string;
  street: string;
  houseNumber: string;
  countryCode: string;
  postCode: string;
  city: string;
  forklifter: "ZSV01";
};

export const FORKLIFT_AVAILABLE = "ZSV01";

const RESULT_INFORMATION_KEYS = [
  "companyName",
  "contactSalutation",
  "contactFirstName",
  "contactLastName",
  "telephoneNumber",
  "street",
  "houseNumber",
  "countryCode",
  "postCode",
  "city",
  "forklifter",
] as const;

const INITIAL_STATE: DifferingShippingAddressState = {
  hasDifferingShippingAddress: false,
  companyName: "",
  contactSalutation: "",
  contactFirstName: "",
  contactLastName: "",
  telephoneNumber: "",
  street: "",
  houseNumber: "",
  countryCode: "DE",
  postCode: "",
  city: "",
  forklifter: FORKLIFT_AVAILABLE,
} as const;

export const DifferingShippingAddress = forwardRef(
  (props: DifferingShippingAddressProps, ref) => {
    const {
      headerData,
      checkAllMandatoriesCompleted,
      updateValue,
      setShippingAddress,
    } = props;
    const { identityStore } = useApp();
    const [state, setState] = useState<DifferingShippingAddressState>({
      ...INITIAL_STATE,
      hasDifferingShippingAddress: !isEmpty(
        pathOr("", ["shippingAddress", "telephoneNumber"], headerData),
      ),
      countryCode: getCountryCode(
        headerData?.shippingAddress?.countryCode,
        identityStore,
      ),
    });
    const [isEdited, setIsEdited] = useState<boolean>(false);

    function checkShippingAddress(headerData: HeaderData) {
      return headerData.shippingAddress
        ? headerData.shippingAddress
        : headerData.address;
    }

    function updateState(address?: DifferingShippingAddressState | AddressTO) {
      if (
        isNil(headerData) ||
        (isNil(headerData?.shippingAddress) && isNil(headerData?.address))
      ) {
        return;
      }
      const updatedShippingAddress = updateShippingAddress(
        address ?? checkShippingAddress(headerData),
        state.hasDifferingShippingAddress,
        state.countryCode,
      );
      setState((prev) => ({ ...prev, ...updatedShippingAddress }));
      if (
        !isEmpty(headerData.shippingAddress?.telephoneNumber) &&
        !state.hasDifferingShippingAddress
      ) {
        setShippingAddress?.(updatedShippingAddress);
      }
    }

    function toggleDifferingShippingAddress() {
      setState((prev) => ({
        ...prev,
        hasDifferingShippingAddress: !prev.hasDifferingShippingAddress,
      }));
    }

    function onChange(
      name: keyof DifferingShippingAddressState,
      value: string,
    ) {
      setStateValue(name, value);
      not(isEdited) && setIsEdited(true);
      updateValue?.(name, value);
    }

    function setStateValue(
      key: keyof DifferingShippingAddressState,
      value: string,
    ): void {
      setState((prev) => ({
        ...prev,
        [key]: value,
      }));
    }

    function resetDifferingShippingAddressState() {
      setState((prev) => ({
        ...INITIAL_STATE,
        hasDifferingShippingAddress: prev.hasDifferingShippingAddress,
        countryCode: getCountryCode(
          headerData?.address?.countryCode,
          identityStore,
        ),
      }));
      setIsEdited(true);
      setShippingAddress?.(state);
    }

    const isValid = () =>
      isPostCodeValid(state.countryCode, state.postCode) &&
      not(
        any(isEmpty)([
          state.contactSalutation,
          state.contactFirstName,
          state.contactLastName,
          state.telephoneNumber,
          state.street,
          state.houseNumber,
          state.city,
          state.countryCode,
        ]),
      );

    useEffect(() => {
      updateState();
    }, []);

    useEffect(() => {
      if (!state.hasDifferingShippingAddress) {
        updateState(isEdited ? state : headerData?.address);
      }
    }, [state.hasDifferingShippingAddress]);

    useEffect(() => {
      checkAllMandatoriesCompleted(
        not(state.hasDifferingShippingAddress) || isValid(),
      );
      setShippingAddress?.(
        state.hasDifferingShippingAddress
          ? state
          : INITIAL_MODIFY_OFFER_MODAL.shippingAddress,
      );
    }, [state]);

    useImperativeHandle(ref, () => ({
      resultInformation() {
        return state.hasDifferingShippingAddress
          ? pick(RESULT_INFORMATION_KEYS, state)
          : null;
      },
    }));

    return (
      <div className="differing-shipping-address">
        <label className="checkbox label">
          <input
            type="checkbox"
            onChange={toggleDifferingShippingAddress}
            checked={state.hasDifferingShippingAddress}
            data-checkbox-id={"order_dialog_differing_shipping_address_title"}
          />
          {L10n.format("order_dialog_differing_shipping_address_title")}
        </label>
        <div className="differing-address-note">
          {L10n.format("order_dialog_differing_shipping_address")}
          <DifferingShippingAddressNote
            hasDifferingShippingAddress={state.hasDifferingShippingAddress}
          />
        </div>
        <DifferingShippingAddressContent
          identityStore={identityStore}
          state={state}
          setStateValue={setStateValue}
          reset={resetDifferingShippingAddressState}
          onChange={onChange}
        />
      </div>
    );
  },
);
