import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { MouseEventHandler, ReactNode, forwardRef, useEffect } from "react";

import "./modal.scss";

interface ModalProps {
  children?: ReactNode;
  className?: string;
  dialogId?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

interface ModalHeaderProps {
  className?: string;
  label?: string;
  onCancel?: () => void;
  children?: ReactNode;
}

interface SaveButtonProps {
  saveLabel: string;
  onSave: MouseEventHandler<HTMLButtonElement> | undefined;
  saveButtonId: string;
  saveIcon?: JSX.Element;
  disabled: boolean;
  isLoading?: boolean;
}

export interface ModalFooterProps {
  mandatoryText?: string | null;
  cancelLabel?: string;
  saveLabel?: string;
  onCancel?: () => void;
  onSave?: MouseEventHandler<HTMLButtonElement>;
  cancelButtonId?: string;
  saveButtonId?: string;
  saveIcon?: JSX.Element;
  disabled?: boolean;
  isLoading?: boolean;
  children?: ReactNode;
  className?: string;
}

interface ModalBodyProps {
  label?: string | JSX.Element;
  children?: ReactNode;
  overflowY?: string | boolean;
  className?: string;
}

export const Modal = ({
  children,
  className,
  dialogId,
  onClick,
}: ModalProps) => {
  useEffect(() => {
    document.documentElement.classList.add("is-clipped");
    return () => {
      document.documentElement.classList.remove("is-clipped");
    };
  }, []);
  return (
    <div className="modal is-active" data-dialog-id={dialogId}>
      <div className="modal-background" onClick={onClick} />
      <div className={classNames(["modal-card", className])}>{children}</div>
    </div>
  );
};

export const ModalHeader = ({
  className,
  label = "",
  onCancel,
  children,
}: ModalHeaderProps) => (
  <header className={classNames(["modal-card-head", className])}>
    <p className="modal-card-title">{label}</p>
    {children}
    {onCancel && (
      <button
        className="delete"
        aria-label="close"
        onClick={onCancel}
        data-button-id="delete"
      />
    )}
  </header>
);

export const SaveButton = ({
  saveLabel,
  onSave,
  saveButtonId,
  saveIcon,
  disabled = false,
  isLoading = false,
}: SaveButtonProps) => (
  <button
    className={classNames([
      "button is-info is-orange",
      { "is-loading": isLoading },
    ])}
    disabled={disabled}
    data-button-id={saveButtonId}
    {...(!disabled && { onClick: onSave })}
  >
    <span>{saveLabel}</span>
    {saveIcon && <span className="icon">{saveIcon}</span>}
  </button>
);

export const ModalFooter = ({
  mandatoryText = null,
  cancelLabel,
  saveLabel,
  onCancel,
  onSave,
  cancelButtonId = "cancel",
  saveButtonId = "save",
  saveIcon,
  disabled = false,
  isLoading = false,
  children,
  className,
}: ModalFooterProps) => (
  <footer className={classNames("modal-card-foot", className)}>
    {mandatoryText && <p className="mandatory-text">{mandatoryText}</p>}
    {cancelLabel && (
      <button
        data-button-id={cancelButtonId}
        className="button is-info is-blue-dark"
        onClick={onCancel}
      >
        <span>{cancelLabel}</span>
        <span className="icon">
          <FontAwesomeIcon icon={faBan} />
        </span>
      </button>
    )}
    {saveLabel && (
      <SaveButton
        saveLabel={saveLabel}
        saveButtonId={saveButtonId}
        onSave={onSave}
        saveIcon={saveIcon}
        disabled={disabled}
        isLoading={isLoading}
      />
    )}
    {children}
  </footer>
);

export const ModalBodyWrapper = forwardRef<HTMLElement, ModalBodyProps>(
  (props, ref) => {
    const { label, children, overflowY = false, className } = props;
    return (
      <section
        className={classNames([
          "modal-card-body",
          className,
          { "overflow-y-always": overflowY },
        ])}
        ref={ref}
      >
        {label && <div className="modal-card-subtitle">{label}</div>}
        {children}
      </section>
    );
  },
);
