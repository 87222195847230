import {
  InputAdornment,
  styled,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  equals,
  find,
  isEmpty,
  isNotNil,
  map,
  not,
  pathOr,
  propEq,
  values,
} from "ramda";
import { useEffect, useRef, useState } from "react";

import { L10n } from "@encoway/l10n";
import {
  CharacteristicContainer,
  CharacteristicValues,
} from "@encoway/rest-api";

import { useApp } from "../../../../hooks/useApp";
import { LvTable } from "../../../../hooks/useLvTable";
import { LvBaseExtended, ResolvedPosition } from "../../../../types/lvTable";
import { LvDropdown, MinimalValue } from "./LvDropdown";
import { ConfiguratorTableCell } from "./LvProductField";

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 0,
    height: "30px",
    paddingRight: "0.5rem",
  },
  "& .MuiInputBase-input": {
    borderRadius: 0,
    fontSize: "0.75rem",
    padding: "0.5rem",
    marginBottom: "-4px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderTop: 0,
    borderBottom: 0,
    borderColor: "#dbdbdb",
  },
  "& .MuiInputAdornment-root .MuiTypography-root": {
    fontSize: "0.75rem",
    marginBottom: "-2px",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#dbdbdb",
    },
    "&:hover fieldset": {
      borderColor: "#dbdbdb",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#dbdbdb",
      borderLeft: "1px solid #dbdbdb",
      borderRight: "1px solid #dbdbdb",
    },
  },
});

type LvCharacteristic = {
  viewport: "dropdown" | "textfield";
  name: string;
  translatedName: string;
  value: string;
  translatedValue: string;
  possibleValues?: Record<string, string>[];
};

function determineNaming(
  characteristicValues: CharacteristicValues | undefined,
  fallback: string = "",
) {
  const naming = pathOr("", ["benennung", "values", 0], characteristicValues);
  if (isEmpty(naming)) {
    return fallback;
  }
  return naming;
}

function mapPossibleValues(
  possibleValues: CharacteristicContainer["possibleValues"],
) {
  return map(
    (possibleValue) => ({
      key: possibleValue.id,
      value: determineNaming(
        possibleValue.characteristicValues,
        possibleValue.value,
      ),
    }),
    values(possibleValues ?? {}),
  );
}

function mapToLvCharacteristic(
  characteristic: CharacteristicContainer,
  parameter: LvBaseExtended,
): LvCharacteristic {
  const { possibleValues, characteristicValues, name } = characteristic;
  const mappedPossibleValues = mapPossibleValues(possibleValues);
  const foundValue = find(propEq(parameter.value, "key"), mappedPossibleValues);
  return {
    viewport: possibleValues ? "dropdown" : "textfield",
    name: parameter.name,
    possibleValues: mappedPossibleValues,
    translatedName: determineNaming(characteristicValues, name),
    value: foundValue?.key ?? parameter.value,
    translatedValue: foundValue?.value ?? parameter.value,
  };
}

type LvCharacteristicInputProps = Readonly<{
  lvCharacteristic: LvCharacteristic;
  selectedPosition: ResolvedPosition;
  lvTable: LvTable;
}>;

function LvCharacteristicInput(props: LvCharacteristicInputProps) {
  const { lvCharacteristic, selectedPosition, lvTable } = props;
  const [internalValue, setInternalValue] = useState(lvCharacteristic.value);
  const initialValueRef = useRef(lvCharacteristic.value);

  useEffect(() => {
    setInternalValue(lvCharacteristic.value);
    initialValueRef.current = lvCharacteristic.value;
  }, [lvCharacteristic.value]);

  function onSetValue() {
    if (not(equals(internalValue, initialValueRef.current))) {
      lvTable.updateLvConfigurationParameter(
        selectedPosition.id,
        lvCharacteristic.name,
        internalValue,
      );
    }
  }

  return (
    <StyledTextField
      value={internalValue}
      onChange={(event) => setInternalValue(event.target.value)}
      onBlur={onSetValue}
      onKeyDown={(event) => equals(event.key, "Enter") && onSetValue()}
      slotProps={{
        input: {
          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
        },
      }}
      fullWidth
    />
  );
}

type LvCharacteristicDropdownProps = Readonly<{
  lvCharacteristic: LvCharacteristic;
  selectedPosition: ResolvedPosition;
  lvTable: LvTable;
}>;

function LvCharacteristicDropdown(props: LvCharacteristicDropdownProps) {
  const { lvCharacteristic, selectedPosition, lvTable } = props;

  const values: MinimalValue[] = map(
    (possibleValue) => ({
      value: possibleValue.key,
      translatedValue: possibleValue.value,
      selectable: true,
      selected: propEq(lvCharacteristic.value, "value", possibleValue),
    }),
    lvCharacteristic.possibleValues ?? [],
  );

  return (
    <div className="control is-expanded">
      <LvDropdown
        selectedValue={lvCharacteristic}
        onSelectValue={(value) =>
          lvTable.updateLvConfigurationParameter(
            selectedPosition.id,
            lvCharacteristic.name,
            value,
          )
        }
        values={values}
      />
    </div>
  );
}

type LvCharacteristicsRowProps = Readonly<{
  parameter: LvBaseExtended;
  selectedPosition: ResolvedPosition;
  lvTable: LvTable;
}>;

export function LvCharacteristicsRow(props: LvCharacteristicsRowProps) {
  const { parameter, selectedPosition, lvTable } = props;
  const { catalogService } = useApp();
  const [lvCharacteristic, setLvCharacteristic] =
    useState<LvCharacteristic | null>(null);

  useEffect(() => {
    catalogService.characteristic(parameter.name).then((result) => {
      setLvCharacteristic(mapToLvCharacteristic(result, parameter));
    });
  }, [parameter, catalogService]);

  if (isNotNil(lvCharacteristic)) {
    return (
      <TableRow sx={{ height: "31px" }}>
        <ConfiguratorTableCell />
        <ConfiguratorTableCell>
          {lvCharacteristic.translatedName}
        </ConfiguratorTableCell>
        <ConfiguratorTableCell>
          {propEq("textfield", "viewport", lvCharacteristic) ? (
            <LvCharacteristicInput
              lvCharacteristic={lvCharacteristic}
              selectedPosition={selectedPosition}
              lvTable={lvTable}
            />
          ) : (
            <LvCharacteristicDropdown
              lvCharacteristic={lvCharacteristic}
              selectedPosition={selectedPosition}
              lvTable={lvTable}
            />
          )}
        </ConfiguratorTableCell>
        <ConfiguratorTableCell>{parameter.recognized}</ConfiguratorTableCell>
        <ConfiguratorTableCell>
          {parameter.isFromReference && (
            <Typography color="textSecondary" sx={{ fontSize: "small" }}>
              {L10n.format("offer_management_lv_taken_from_position", {
                lvResolvedReference: selectedPosition.lvResolvedReference,
              })}
            </Typography>
          )}
        </ConfiguratorTableCell>
      </TableRow>
    );
  }

  return null;
}
