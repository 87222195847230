import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { isNotEmpty, mapObjIndexed, pick, values } from "ramda";
import {
  ChangeEvent,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useRef,
} from "react";

import { L10n } from "@encoway/l10n";

import { InputError } from "../../../../components/input/InputError";
import { InputTitle } from "../../../../components/input/inputTitle";
import { ModalInput } from "../../../../components/modal/ModalInput";
import { ModalTag } from "../../../../components/modal/modalTag";
import { TextArea } from "../../../../components/textarea";
import { useCart } from "../../../../hooks/useCart";
import {
  hasRightToSeeCommentaryExtraOM,
  hasRightToSeeExtendedHeaderData,
  hasRightToSeeShareQuoteHeaderData,
  UNKNOWN,
} from "../../../../http/identityHelper";
import {
  ExtendedTeckentrupCartTO,
  TeckentrupCartTO,
} from "../../../../types/cart";
import { DifferingShippingAddress } from "../../../shoppingCart/components/modals/orderModal/differingShippingAddress";
import { Input } from "../../../shoppingCart/components/modals/orderModal/input";
import { InputConstants } from "../inputConstants";
import {
  checkDisplayedValue,
  containsOddNumberOfObject,
  findMaxLength,
  formatDateWithTime,
} from "../modifyOfferDialogUtils";
import {
  CustomerInformation as CustomerInformationType,
  CustomerInputChange,
  CustomerNumberChange,
  InputChange,
  ModifyOfferModalReducedState,
  ModifyOfferModalState,
  ModifyOfferModalStateObjectKeys,
  ResetCustomerNumberTag,
  SetShippingAddress,
  ShippingAddressChange,
  ShippingAddressMandatoriesCompleted,
  ToggleCustomerAddedToolTip,
} from "../modifyOfferModalActions";
import { ProjectShareInformation } from "./projectShareInformation";

type ColumnEntriesProps = {
  information: Record<string, string | number | string[]>;
  defaultValue: string;
};

const ColumnEntries = ({
  information,
  defaultValue,
}: Readonly<ColumnEntriesProps>) => (
  <div className="two-columns custom-margin-2">
    {values(
      mapObjIndexed(
        (value, key) => (
          <div className="column-entry with-padding" key={key}>
            <Input
              disabled
              name={key}
              label={L10n.format(`offer_management_table_${key}`)}
              value={
                value && value !== UNKNOWN
                  ? checkDisplayedValue(value, key)
                  : defaultValue
              }
              inputId={`offer_management_table_${key}`}
            />
          </div>
        ),
        information,
      ),
    )}
    {containsOddNumberOfObject(information) && (
      <div className="filler-item"></div>
    )}
  </div>
);

type CustomerHeaderProps = {
  label: string;
  labelCustomerSearch: string;
  labelAddCustomer: string;
  toolTipLabel: string;

  disabled: boolean;
  loading: boolean;
  showToolTip: boolean;

  onAddCustomer: () => Promise<void>;
  onClickToolTip: () => void;
  onCustomerSearch: () => void;
};

const CustomerHeader = (props: PropsWithChildren<CustomerHeaderProps>) => {
  const {
    label,
    onCustomerSearch,
    onAddCustomer,
    labelCustomerSearch,
    labelAddCustomer,
    children,
    disabled,
    loading,
    showToolTip,
    toolTipLabel,
    onClickToolTip,
  } = props;

  return (
    <div className="customer-header">
      <p>{label}</p>
      <hr />
      <div className="offer-management-table-button float-right">
        <button
          className="button is-info is-blue-dark is-pulled-right"
          onClick={onCustomerSearch}
        >
          <div className="is-pulled-left" data-button-id={labelCustomerSearch}>
            {labelCustomerSearch}
          </div>
          <div className="is-pulled-right">
            <FontAwesomeIcon icon={faAddressCard} />
          </div>
        </button>
      </div>
      {children}
      <div className="offer-management-table-button mb-4">
        <button
          className={classNames([
            "button is-pulled-right",
            { "is-loading": loading },
          ])}
          onClick={disabled ? () => null : onAddCustomer}
          disabled={disabled || loading}
          data-button-id={labelAddCustomer}
        >
          <div className="is-pulled-left">{labelAddCustomer}</div>
        </button>
        {showToolTip && (
          <button
            className="modify-offer-modal input-warning"
            style={{ border: 0 }}
            onClick={onClickToolTip}
          >
            {toolTipLabel}
          </button>
        )}
      </div>
    </div>
  );
};

type CustomerInformationProps = {
  mutatedQuote: Record<string, string>;
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const CustomerInformation = ({
  mutatedQuote,
  onInputChange,
}: Readonly<CustomerInformationProps>) => (
  <div className="two-columns custom-margin-2">
    {values(
      mapObjIndexed(
        (value, key) => (
          <ModalInput
            key={key}
            name={key}
            label={L10n.format(`modify_dialog_contact_${key}`)}
            placeholder={L10n.format(`modify_dialog_contact_${key}`)}
            value={value}
            onChange={onInputChange}
            maxLength={findMaxLength(`modify_dialog_contact_${key}`)}
            inputId={`modify_dialog_contact_${key}`}
          />
        ),
        mutatedQuote,
      ),
    )}
  </div>
);

type ProjectInformationWrapperProps = {
  newQuote: boolean;
  mutatedQuote: ModifyOfferModalReducedState;
  booleanAuthorities: string[];
};

const ProjectInformationWrapper = ({
  newQuote,
  mutatedQuote,
  booleanAuthorities,
}: ProjectInformationWrapperProps) => (
  <>
    {!newQuote && (
      <ColumnEntries
        information={mutatedQuote.projectInformationReadOnly}
        defaultValue={L10n.format("offer_management_will_be_set_by_system")}
      />
    )}
    {!newQuote && mutatedQuote.thirdPartySystemInformation && (
      <ColumnEntries
        information={mutatedQuote.thirdPartySystemInformation}
        defaultValue={L10n.format("offer_management_will_be_set_by_system")}
      />
    )}
    <div className="two-columns custom-margin-2">
      <ModalInput
        disabled
        name="orderedDate"
        label={L10n.format("modify_dialog_ordered_date")}
        placeholder={L10n.format("modify_dialog_ordered_date")}
        value={
          isNotEmpty(mutatedQuote.projectInformation.orderedDate)
            ? formatDateWithTime(mutatedQuote.projectInformation.orderedDate)
            : L10n.format("offer_management_will_be_set_by_system")
        }
        inputId={"modify_dialog_ordered_date"}
      />
      <ModalInput
        disabled
        name="sourceDocumentName"
        label={L10n.format("modify_dialog_sourceDocumentName")}
        placeholder={L10n.format("modify_dialog_sourceDocumentName")}
        value={mutatedQuote.projectInformation.sourceDocumentName}
        inputId={"modify_dialog_sourceDocumentName"}
      />
    </div>
    {!newQuote && hasRightToSeeExtendedHeaderData(booleanAuthorities) && (
      <ColumnEntries
        information={pick(
          [
            "accountId",
            "clientId",
            "creatorUserId",
            "contributionMarginNumber",
            "priceDate",
            "currencyConversionRate",
            "subOrders",
            "copiedFrom",
            "parentOrder",
          ],
          mutatedQuote.projectInformationExtended,
        )}
        defaultValue="-"
      />
    )}
    {hasRightToSeeShareQuoteHeaderData(booleanAuthorities) && (
      <ProjectShareInformation
        projectInformationShared={mutatedQuote.projectInformationShared}
      />
    )}
  </>
);

type ModifyOfferProps = {
  quote?: TeckentrupCartTO | ExtendedTeckentrupCartTO;
  onAddCustomer: () => Promise<void>;
  onCustomerSearchToggle: () => void;
  state: ModifyOfferModalReducedState;
  setState: Dispatch<SetStateAction<ModifyOfferModalReducedState>>;
};

export const ModifyOffer = (props: Readonly<ModifyOfferProps>) => {
  const { quote, onCustomerSearchToggle, onAddCustomer, state, setState } =
    props;
  const { cart } = useCart();
  const shippingAddress = useRef();

  const onInputChange =
    (prop: ModifyOfferModalStateObjectKeys) =>
    ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setState(InputChange(name, value, prop));

  const onSetShippingAddress = (
    value: ModifyOfferModalState["shippingAddress"],
  ) => setState(SetShippingAddress(value));

  const onCustomerInputChange = ({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) =>
    setState(CustomerInputChange(name, value));

  const onShippingAddressMandatoriesCompleted = (value: boolean) =>
    setState(ShippingAddressMandatoriesCompleted(value));

  const onShippingAddressChange = (name: string, value: string) =>
    setState(ShippingAddressChange(name, value));

  const onCustomerNumberChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => setState(CustomerNumberChange(value));

  const onTitleChange = (title: string) =>
    setState(InputChange("title", title, "customerInformation"));

  const onResetCustomerAddedToolTip = () =>
    setState(ToggleCustomerAddedToolTip(false));

  const onResetCustomerNumberTag = () => setState(ResetCustomerNumberTag);

  return (
    <>
      <div
        className="two-columns custom-margin-2"
        data-dialog-id="offer_management_menu_modify"
      >
        <ModalInput
          value={state.projectInformation.buildProject}
          name="buildProject"
          label={
            L10n.format("building_project") + L10n.format("mandatory_symbol")
          }
          placeholder={L10n.format("building_project")}
          onChange={onInputChange("projectInformation")}
          maxLength={InputConstants.length.building_project}
          inputId={"building_project"}
        />
        <ModalInput
          name="referenceNumber"
          label={
            L10n.format("reference_order_id") + L10n.format("mandatory_symbol")
          }
          placeholder={L10n.format("reference_order_id")}
          value={state.projectInformation.referenceNumber}
          onChange={onInputChange("projectInformation")}
          maxLength={InputConstants.length.modify_dialog_reference}
          inputId={"reference_order_id"}
        />
        <span className="smallText with-padding">
          {L10n.format("mandatory_symbol_information")}
        </span>
      </div>
      <CustomerHeader
        label={L10n.format("customer")}
        labelCustomerSearch={L10n.format("select_customer")}
        labelAddCustomer={L10n.format(
          "modify_dialog_save_address_in_customer_management",
        )}
        onCustomerSearch={onCustomerSearchToggle}
        onAddCustomer={onAddCustomer}
        loading={state.customerAddLoading}
        toolTipLabel={L10n.format("modify_offer_dialog_add_customer_label")}
        onClickToolTip={onResetCustomerAddedToolTip}
        showToolTip={state.customerAddLoadingToolTip}
        disabled={!state.edited}
      >
        <div className="two-columns custom-margin-2">
          {!state.customerExists ? (
            <ModalInput
              name={"customerNumber"}
              label={L10n.format("modify_dialog_contact_customerNumber")}
              placeholder={L10n.format("modify_dialog_contact_customerNumber")}
              value={state.customerInformation.customerNumber}
              onChange={onInputChange("customerInformation")}
              onBlur={onCustomerNumberChange}
              inputId={"modify_dialog_contact_customerNumber"}
            >
              <InputError
                label={L10n.format("modify_offer_dialog_no_customer_no")}
                visible={state.customerNumberEmpty}
              />
            </ModalInput>
          ) : (
            <ModalTag
              label={L10n.format("modify_dialog_contact_customerNumber")}
              value={state.customerInformation.customerNumber}
              onResetCustomerNumberTag={onResetCustomerNumberTag}
            />
          )}
          <ModalInput
            name={"customerName"}
            label={L10n.format("modify_dialog_contact_customerName")}
            placeholder={L10n.format("modify_dialog_contact_customerName")}
            value={state.customerInformation.customerName}
            onChange={onCustomerInputChange}
            maxLength={findMaxLength("modify_dialog_company_name")}
            inputId={"modify_dialog_contact_customerName"}
          >
            <InputError
              label={L10n.format("modify_offer_dialog_no_customer_name")}
              visible={state.customerNameEmpty}
            />
          </ModalInput>
        </div>
        <div className="quote-addresses with-padding column-entry custom-margin-2">
          <h2 className="subtitle has-text-weight-bold">
            {L10n.format("modify_dialog_customer_title")}
          </h2>
        </div>
        <InputTitle
          title={state.customerInformation.title}
          onClick={onTitleChange}
        />
        <CustomerInformation
          mutatedQuote={pick<
            Omit<CustomerInformationType, "id">,
            keyof Omit<CustomerInformationType, "id">
          >(
            [
              "firstname",
              "surname",
              "street",
              "houseNumber",
              "zip",
              "city",
              "phone",
              "email",
            ],
            state.customerInformation,
          )}
          onInputChange={onCustomerInputChange}
        />
      </CustomerHeader>
      <DifferingShippingAddress
        ref={shippingAddress}
        updateValue={onShippingAddressChange}
        setShippingAddress={onSetShippingAddress}
        headerData={quote?.headerData}
        checkAllMandatoriesCompleted={onShippingAddressMandatoriesCompleted}
      />
      <TextArea
        label={L10n.format("comment")}
        subLabel={
          hasRightToSeeCommentaryExtraOM(cart.authorities.booleanAuthorities)
            ? L10n.format("will_not_be_transmitted_to_Teckentrup")
            : undefined
        }
        value={state.projectInformation.comment}
        onInputChange={onInputChange("projectInformation")}
        attributeId="comment"
      />
      <ProjectInformationWrapper
        newQuote={!quote}
        mutatedQuote={state}
        booleanAuthorities={cart.authorities.booleanAuthorities}
      />
    </>
  );
};
