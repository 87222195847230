import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  MenuItemProps,
  SxProps,
  Theme,
} from "@mui/material";
import { map } from "ramda";
import { useState } from "react";

type CustomMenuItemProps = MenuItemProps & {
  onClick?: () => void;
};

type MoreIconMenuProps = Readonly<{
  menuItems: CustomMenuItemProps[];
  sx?: SxProps<Theme>;
}>;

export function MoreIconMenu({ menuItems, sx }: MoreIconMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMenuClick = (onClick?: () => void) => {
    onClick?.();
    setAnchorEl(null);
  };

  return (
    <Box sx={sx}>
      <IconButton
        size="small"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={{ zIndex: 999999 }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        MenuListProps={{
          dense: true,
        }}
      >
        {map(
          ({ key, children, onClick, ...restProps }) => (
            <MenuItem
              key={key}
              onClick={() => handleMenuClick(onClick)}
              {...restProps}
            >
              {children}
            </MenuItem>
          ),
          menuItems,
        )}
      </Menu>
    </Box>
  );
}
